<template>
  <card-modal
    icon=""
    :title="$t('instant_card_cards.details.activate_card_section.title')"
    context="activateInstantCard"
    @submit="submit"
    @cancel="cancel"
    :submit_working="working"
    cancel_text="instant_card_cards.details.activate_card_section.button_cancel"
    submit_text="instant_card_cards.details.activate_card_section.button_save"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <label-text :label="$t('instant_card_cards.details.activate_card_section.public_token')" :value="publicToken" />
        <h-autocomplete
          v-model="account_id"
          :label="$t('instant_card_cards.details.activate_card_section.user') + '*'"
          v-validate="'required'"
          :error-messages="errors.collect('user')"
          name="user"
          item-value="account_id"
          alternate-text="email"
          alternate-id="account_id"
          data-vv-name="user"
          data-vv-as="user"
          search-action="account/autocompleteSearch"
          data-test="activate-instant-card--user"
        >
        </h-autocomplete>

        <div v-if="account_id">
          <v-text-field
            v-model="first_name"
            :label="$t('instant_card_cards.details.activate_card_section.first_name') + '*'"
            name="first_name"
            v-validate="'required'"
            :error-messages="errors.collect('name')"
            data-vv-name="name"
            maxlength="50"
            :data-vv-as="$t('instant_card_cards.details.activate_card_section.first_name')"
            data-test="activate-card--first-name"
            :disabled="firstNameIsPrefill"
          ></v-text-field>

          <v-text-field
            v-maska="{ mask: '####' }"
            v-model="birth_year"
            :label="$t('instant_card_cards.details.activate_card_section.birth_year') + '*'"
            pattern="[0-9][0-9][0-9][0-9]"
            v-validate="'required|max:4'"
            :disabled="yearOfBirthIsPrefill"
          />

          <v-select
            name="country"
            v-model="country"
            v-validate="'required'"
            :error-messages="errors.collect('country')"
            data-vv-name="country"
            :data-vv-as="$t(`instant_card_cards.details.activate_card_section.country`)"
            :items="countryList"
            item-text="text"
            :label="$t(`instant_card_cards.details.activate_card_section.country`) + '*'"
            :disabled="countryIsPrefill"
          ></v-select>

          <v-text-field
            v-if="postCodeRequired"
            v-mask-zip
            hint="X9X 9X9"
            name="postal_code"
            v-validate="'required'"
            v-model="postal_code"
            :label="$t('instant_card_cards.details.activate_card_section.postal_code') + '*'"
            :error-messages="errors.collect('postal_code')"
            data-vv-name="postal_code"
            :data-vv-as="$t('instant_card_cards.details.activate_card_section.postal_code')"
            maxlength="7"
            :disabled="postalCodeIsPrefill || !postCodeRequired"
          ></v-text-field>
        </div>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import Vue from 'vue';
import moment from 'moment';
import datetimeFactory from 'datetime-factory';

export default {
  name: 'activate-instant-card-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    publicToken: {
      type: String,
    },
    cardId: {
      type: String,
    },
  },
  data() {
    return {
      account_id: null,
      first_name: '',
      birth_year: null,
      country: 'CA',
      postal_code: undefined,
      countryIsPrefill: false,
      postalCodeIsPrefill: false,
      yearOfBirthIsPrefill: false,
      firstNameIsPrefill: false,
    };
  },
  created() {
    this.initCountryList();
  },
  watch: {
    async account_id(val) {
      if (val) {
        this.clearAllFieldsExceptAccountId();
        await this.executeAction({ action: 'account/getAccount' }, val);
      }
    },
    country(val, oldVal) {
      if (val !== oldVal) {
        this.postalCode = undefined;
      }
    },
    accountFirstName() {
      if (this.accountFirstName) {
        this.firstNameIsPrefill = true;
        this.first_name = this.accountFirstName;
      }
    },
    accountYearOfBirth() {
      if (this.accountYearOfBirth) {
        this.yearOfBirthIsPrefill = true;
        this.birth_year = this.accountYearOfBirth;
      }
    },
    accountCountry() {
      if (this.accountCountry) {
        this.countryIsPrefill = true;
        this.country = this.accountCountry;
      }
    },
    accountPostalCode() {
      if (this.accountPostalCode) {
        this.postalCodeIsPrefill = true;
        this.postal_code = this.accountPostalCode;
      }
    },
  },
  computed: {
    ...mapGetters('instantCardCard', ['countryList']),
    ...mapGetters('account', ['account', 'accountCountry', 'accountPostalCode', 'accountFirstName', 'accountYearOfBirth']),
    postCodeRequired() {
      if (!this.country) {
        return false;
      }
      const countryFromList = _.find(this.countryList, (c) => c.value === this.country);
      return countryFromList && countryFromList.postal_code_required;
    },
  },
  methods: {
    ...mapActions('ui', ['addError', 'clearAllError', 'clearError']),
    ...mapActions('instantCardCard', ['initCountryList']),
    async clear() {
      this.account_id = null;
      this.clearAllFieldsExceptAccountId();
      await this.executeAction({ action: 'account/clearAccount' });
      this.$validator.reset();
    },
    clearAllFieldsExceptAccountId() {
      this.first_name = '';
      this.birth_year = null;
      this.country = '';
      this.postal_code = undefined;
      this.countryIsPrefill = false;
      this.postalCodeIsPrefill = false;
      this.yearOfBirthIsPrefill = false;
      this.firstNameIsPrefill = false;
    },
    success(response) {
      this.clear();
      this.$emit('activated');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    buildPayload() {
      return {
        public_token: this.publicToken,
        account_id: this.account_id,
        first_name: this.first_name,
        country: this.country.abbr ? this.country.abbr : this.country,
        postal_code: this.postal_code,
        year_of_birth: this.birth_year,
      };
    },
    async submit() {
      let validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        await this.executeAction(
          {
            action: 'instantCardCard/activateInstantCard',
            name: 'activateInstantCard',
            success: this.success,
          },
          this.buildPayload(),
        );
      }
    },
  },
};
</script>
