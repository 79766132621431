export default {
  address: {
    search_address_action: {
      call_to_action_text: 'Search address',
      modal: {
        title: 'Search Address',
        address_information: 'Address Information',
        street: 'Street',
        city: 'City',
        state: 'State',
        country: 'Country',
        zip: 'Zip',
        plus_code: 'Plus Code',
        button_cancel: 'Cancel',
        button_submit: 'Submit',
      },
    },
  },
  account_email: {
    label: 'User',
  },
  account_card_application_id: {
    label: 'Card Application ID',
  },
  purchase_channel: {
    consumer_interface: 'Portal - Consumer',
    corporate_interface: 'Portal - Corporate',
    guest_services: 'Guest Services',
    other: 'Admin - Other',
    null: 'N/A',
  },
  activation_code: {
    status: {
      undefined: '',
      active: 'Pending Activation',
      canceled: 'Canceled',
      redeemed: 'Redeemed',
    },
    details: {
      languages: {
        undefined: '',
        fr: 'French',
        en: 'English',
      },
      activation_code_information_section: {
        title: 'Activation Code',
        id: 'ID',
        purchase_channel: 'Purchase Channel',
        batch_id: 'Batch Id',
        user: 'User',
        cardholder_id: 'Card Public Token',
        amount: 'Amount',
        bonus_amount: 'Reward',
        payment_amount: 'Contribution',
        activation_enabled: 'Pre-Activated',
        system_status: {
          status: 'Status',
        },
        redeemed_date: 'Activation Date',
        publisher: 'Closed Loop',
        publisher_na: 'Set at Activation Time',
        voucher: 'Voucher',
        recipient_email: 'Email',
        recipient_mobile_number: 'Mobile Number',
        recipient_first_name: 'First Name',
        recipient_last_name: 'Last Name',
        recipient_language: 'Language',
        cardholder: 'Cardholder',
        referenced_by_business_id: 'Referenced by Business',
        source_quantity: 'Source Quantity',
        received: 'Received',
        remaining: 'Remaining',
        created: 'Created',
        action: 'Action',
        personalized_message: 'Personalized Message',
        voucher_title: 'Voucher',
        cancel_activation_code: 'Cancel',
        redeem_activation_code: 'Activate',
        resend_activation_code: 'Resend',
        actions: {
          pre_activate: 'Pre-Activate',
          pre_activate_success: 'Activation Code Pre-Activated Successfully',
          mark_as_non_pre_activated: 'Mask as Non Pre-Activated',
          mark_as_non_pre_activated_success: 'Activation Code Mask as Non Pre-Activated Successfully',
          downloadPDF: {
            title: 'Download PDF',
            language_modal: {
              title: 'Download PDF',
              languageSelectedField: 'Language',
              options: {
                en: 'English',
                fr: 'French',
              },
            },
            button_cancel: 'Cancel',
            button_save: 'Download',
          },
          update: {
            action: 'Edit',
            title: 'Edit Activation Code',
            personalized_message: 'Personalized Message',
            button_save: 'Save',
            button_cancel: 'Cancel',
          },
        },
        cancel_action: {
          success: 'Activation Code Canceled',
        },
      },
    },
  },
  activation_code_batch: {
    duration_trigger: {
      undefined: '',
      activation: 'Activation',
      purchase: 'Purchase',
    },
    activation_codes: 'Activation Codes',
    details: {
      bi: {
        employer_report: 'Employer Report',
      },
      activation_code_batch_information_section: {
        title: 'Activation Code Batch',
        id: 'ID',
        reference_id: 'Reference Id',
        duration_trigger: 'Duration Trigger',
        purchase_channel: 'Purchase Channel',
        name: 'Name',
        offer_id: 'Offer ID',
        offer_promotion_id: 'Offer Promotion ID',
        corporate_order_id: 'Corporate Order ID',
        buyer_account_id: 'Owner User',
        business_id: 'Business ID',
        creation_date: 'Created',
        payment_amount: 'Contribution Amount',
        payment_amount_funded: 'Skip Contribution Funding',
        bonus_amount: 'Reward Amount',
        bonus_amount_funded: 'Skip Reward Funding',
        activation_codes: 'Activation Codes',
        personalized_message: 'Personalized Message',
        pre_activation_required: 'Requires Pre-Activation',
      },
      activation_codes_section: {
        count: 'Count',
        placeholder: 'ID, User ID',
        form: {
          submit: 'Search',
          total_results_message: 'Displaying the maximum number of results. Further refine your search to see more matches.',
        },
      },
      actions: {
        downloadExcel: {
          title: 'Download Excel',
          language_modal: {
            title: 'Download Excel',
            languageSelectedField: 'Language',
            options: {
              en: 'English',
              fr: 'French',
            },
          },
          button_cancel: 'Cancel',
          button_save: 'Download',
        },
        downloadPdf: {
          title: 'Download Pdf',
          language_modal: {
            title: 'Download Pdf',
            languageSelectedField: 'Language',
            options: {
              en: 'English',
              fr: 'French',
            },
          },
          button_cancel: 'Cancel',
          button_save: 'Download',
        },
        downloadZip: {
          title: 'Download Zip',
          language_modal: {
            title: 'Download Zip',
            languageSelectedField: 'Language',
            options: {
              en: 'English',
              fr: 'French',
            },
          },
          button_cancel: 'Cancel',
          button_save: 'Download',
        },
        downloadInvoice: {
          title: 'Download Invoice PDF',
          language_modal: {
            title: 'Download Invoice PDF',
            languageSelectedField: 'Language',
            options: {
              en: 'English',
              fr: 'French',
            },
          },
          button_cancel: 'Cancel',
          button_save: 'Download',
        },
        downloadInfosPdf: {
          title: 'Download Informative PDF',
          language_modal: {
            title: 'Download Informative PDF',
            languageSelectedField: 'Language',
            options: {
              en: 'English',
              fr: 'French',
            },
          },
          button_cancel: 'Cancel',
          button_save: 'Download',
        },
        update: {
          action: 'Edit',
          title: 'Edit Activation Code Batch',
          reference_id: 'Reference Id',
          business_id: 'Business Id',
          personalized_message: 'Personalized Message',
          button_save: 'Save',
          button_cancel: 'Cancel',
        },
      },
    },
    activation_codes_table: {
      fields: {
        id: 'ID',
        account_id: 'User',
        voucher_id: 'Voucher',
        remaining: 'Remaining',
        status: 'Status',
        created: 'Created',
      },
    },
  },
  aml: {
    aml_status: {
      not_generated: 'AML status information not yet generated. Please check back tomorrow.',
    },
    create_aml_note_action: {
      title: 'Add AML Note',
      aml_note: 'Note',
      type: 'Type',
      add_a_note: 'Add a Note',
      category: 'Category',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      success: 'AML Note Saved',
    },
    documents_section: {
      title: 'Documents',
      document_count: 'Documents',
      no_found: 'None',
      action: 'Action',
      upload_document: 'Upload document',
    },
    upload_document_action: {
      title: 'Upload Documents',
      button_cancel: 'Close',
      button_save: 'Submit',
      dropzone_msg: 'Click or drag and drop file here',
      max_size: 'File max size',
      document_type: 'Document Type',
    },
    document_types: {
      id_verification: 'ID Verification',
      card_limit: 'Card Limit',
      other: 'Other',
    },
    document_upload: {
      success: 'Document uploaded successfully',
    },
  },
  app_key: 'App Key',
  application: {
    no_application_found: 'No applications found',
    no_card_issued: 'No card issued',
    no_kyc: 'No Equifax information currently available.',
    no_kyc_detail:
      'No ID Verification Information because the approbation/rejection has been done before the manual card approbation feature activation',
    button_modify: 'Modify',
    button_update_applicant: 'Edit Applicant Info',
    button_retry_kyc: 'Retry ID Verification',
    button_create_card: 'Create Card',
    button_reject: 'Reject',
    button_approve: 'Approve',
    button_upload_document: 'Upload Documents',
    button_request_documents: 'Request Documents',
    button_add_aml_note: 'Add AML Note',
    button_create_change_application_request: 'Request Change',
    button_send_request_change: 'Send Request Change',
    button_actions: 'Actions',
    update_applicant_info_action: {
      title: 'Edit Applicant Info',
      button_save: 'Submit',
      button_cancel: 'Cancel',
      fields: {
        first_name: 'First Name',
        last_name: 'Last Name',
        gender: 'Gender',
        date_of_birth: 'Date Of Birth',
        address: {
          street: 'Street',
          city: 'City',
          state: 'State/Province',
          country: 'Country',
          zip: 'Zip/Postal Code',
        },
        email: 'Email',
        phone: 'Phone Number',
        mobile: 'Mobile Number',
        occupation: 'Occupation',
        status: 'Occupation Status',
        sector: 'Sector',
        profession: 'Profession',
        language: 'Language',
        settings: {
          alerts: 'Alerts',
        },
        compliance: {
          occupation: {
            status: { text: 'Compliance Occupation Status' },
            sector: { text: 'Compliance Occupation Sector' },
            profession: { text: 'Compliance Occupation Profession' },
          },
        },
      },
      success: 'Card Applicant Info Updated',
    },
    retry_kyc_check_action: {
      title: 'Warning',
      text: 'ID will be verified again at Equifax. This will overwrite the actual result. Are you sure you want to verify the ID again?',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      success: 'Kyc Check Complete',
    },
    create_card_action: {
      title: 'Warning',
      text: 'Are you sure you want to try to create a card for the application?',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      success: 'Retry Create Card Complete',
    },
    approve_application_action: {
      title: 'Approve Card Application',
      verification_options: {
        credit_file: 'Credit File',
        dual_source: 'Dual Source',
        face_to_face: 'Face to Face',
      },
      single_options: {
        name_address: 'Name + Address',
        name_birthdate: 'Name + DOB',
        financial_account: 'Financial Account',
      },
      dual_options: {
        credit_file: 'Credit File',
        trade: 'Trade',
        document: 'Document',
      },
      document_options: {
        passport: 'Passport',
        drivers_license: "Driver's License",
        permanent_resident_card: 'Permanent Resident Card',
        other: 'Other',
      },
      aml_note: 'AML Note',
      verification_method: 'Verification Method',
      reference_number: 'Reference No',
      source1: 'Source 1',
      source2: 'Source 2',
      information: 'Information',
      provision: 'Provision',
      institution: 'Institution',
      document_type: 'Document Type',
      doc_type_other: 'Document',
      issuing_jurisdiction: 'Issuing Jurisdiction',
      expiration_date: 'Expiration Date',
      expiration_date_cancel: 'Cancel',
      expiration_date_ok: 'Ok',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      success: 'Application Approved',
      duplicate_warning: 'Warning! This card application is a duplicate. Do not approve unless it is on purpose.',
    },
    reject_application_action: {
      title: 'Reject Card Application',
      aml_note: 'AML Note',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      success: 'Application Rejected',
    },
    create_change_application_request_action: {
      title: 'Create Change Application Request',
      loading: 'Loading',
      url: 'Link',
      review_already_exists: 'You need to close the pending task related to this card application before creating a new change request.',
      card_duplicated: 'Warning! This card application is a duplicate. Do not approve unless it is on purpose.',
      button_close: 'Close',
      duplicate_card_modal: {
        title: 'Warning',
        text: 'Warning! This card application is a duplicate. Are you sure you want to request an update on this card application?',
        cancel_text: 'cancel',
        submit_text: 'submit',
      },
    },
    send_change_application_request_action: {
      title: 'Send Request By Email To Update Application',
      operations: 'Operations Requested*',
      options: {
        first_name: 'Update first name',
        last_name: 'Update last name',
        date_of_birth: 'Update date of birth',
        address: 'Update address',
        document_name_address: 'Upload KYC document (name and address)',
        document_name_date_of_birth: 'Upload KYC document (name and date of birth)',
      },
      loading: 'Loading',
      url: 'Link',
      review_already_exists: 'You need to close the pending task related to this card application before creating a new change request.',
      button_close: 'Close',
      button_save: 'Submit',
    },
    applicant_info: {
      headers: {
        basic_info: 'Basic Info',
        occupation: 'Occupation',
        contact_info: 'Contact Info',
        card_info: 'Card Info',
        other: 'Other',
      },
      compliance: {
        intent_of_use: {
          title: 'Intent Of Use',
          values: {
            offers_rewards: 'Offers and Rewards',
            everyday_spending: 'Everyday Spending',
          },
        },
      },
      title: 'Application',
      gender: 'Gender',
      first_name: 'First Name',
      last_name: 'Last Name',
      date_of_birth: 'Date of Birth',
      phone: 'Phone Number',
      mobile: 'Mobile Number',
      email: 'Email Address',
      language: 'Language',
      date_of_signup: 'Date of Signup',
      foreign_tax_obligations: 'Foreign Tax Obligations',
      status: 'Status',
      sector: 'Sector',
      profession: 'Profession',
      address: 'Address',
      product_id: 'Card Product ID',
      package_id: 'Card Package ID',
      sponsor_id: 'Card Sponsor ID',
      settings: {
        alerts: 'Alerts',
      },
    },
    aml_profile: {
      title: 'AML Profile',
      fatca: {
        title: 'FATCA',
        ssn: 'SSN',
        jurisdiction: 'Jurisdiction',
        tin: 'TIN',
        no_tin_reason: 'No TIN Reason',
      },
      third_party: {
        title: '3rd Party Determination',
        is_third_party: 'Third Party',
        type: 'Type',
        incorporation_number: 'Incorporation Number',
        jurisdiction: 'Jurisdiction',
        first_name: 'First Name',
        last_name: 'Last Name',
        relationship: 'Relationship',
        occupation: 'Occupation',
        date_of_birth: 'Date of Birth',
        address: 'Address',
      },
    },
    aml_note_section: {
      title: 'AML Notes',
      note_created: 'Created',
      note_author: 'Author Email',
      note_note: 'Note',
      note_count: 'Notes',
      add_note: 'Add note',
      action: 'Action',
      no_found: 'None',
      note_type_card: 'Card',
      note_type_application: 'Application',
      delete: 'Delete',
      deleted_by: 'Deleted By',
    },
    manual_approval: {
      title: 'ID Verification Review',
      agent_title: 'Agent',
      verification_method: 'Verification Method',
      reviewed_date: 'Date Reviewed',
      aml_note: 'AML Note',
      credit_file: {
        reference: 'Reference Number',
      },
      dual_source: {
        source1: 'Source 1',
        source2: 'Source 2',
        reference: 'Reference Number',
        information: 'Information',
        provision: 'Provision',
        institution: 'Institution',
      },
      face: {
        document: 'Document Type',
        reference: 'Reference Number',
        jurisdiction: 'Issuing Jurisdiction',
        expiration: 'Expiration Date',
      },
    },
    kyc_section: {
      equifax_error: 'Equifax Error Detail',
      equifax_reason: 'Reason',
      title: 'ID Verification',
      date_verified: 'Date Verified',
      source_title: 'Source',
      source: 'Equifax',
      document: 'Document',
      document_button: 'View',
      result: 'Result',
      result_button: 'View',
      result_modal_title: 'Kyc Result',
      document_modal_title: 'Kyc document',
      input: 'Input',
      name: 'Name',
      date_of_birth: 'Date of Birth',
      address: 'Address',
      consumer_report: 'Consumer Report',
      unique_number: 'Unique Number',
      file_since_date: 'File Since',
      first_name: 'First Name',
      middle_name: 'Middle Name',
      last_name: 'Last Name',
      also_known_as: 'Also Known As',
      addresses_heading: 'Addresses',
      addresses: {
        current_address: 'Current Address',
        first_former_address: 'First Former Addr',
        second_former_address: 'Second Former Addr',
        third: 'Third Former Addr',
        fourth: 'Fourth Former Addr',
        fifth: 'Fifth Former Addr',
      },
      date_reported: 'Date Reported',
      civic_number: 'Civic Number',
      street_name: 'Street Name',
      city: 'City',
      province: 'Province',
      postal_code: 'Postal Code',
      single_source: 'Single Source',
      dual_source: 'Dual Source',
      hit: 'Hit',
      decision: 'Decision',
      missing_matches: 'Missing Matches',
      partial_matches: 'Partial Matches',
      full_matches: 'Full Matches',
      dual_sources: {
        title: 'Source',
        matches: 'Match',
        name_and_dob_match: 'Name and Date of Birth',
        name_and_address_match: 'Name and Address',
        source: 'Source',
        reference_number: 'Reference Number',
        name_as_reported: 'Name as Reported',
        date_of_birth_as_reported: 'Date of Birth as Reported',
        address_as_reported: 'Address as Reported',
      },
      kyc_result: {
        not_found: 'Not Found',
        not_confirmed: 'Not Confirmed',
        parser_error: 'Equifax Parser Error',
        error: 'Error',
        credit_file: 'Credit File',
        dual_source: 'Dual Source',
        test: 'Test',
        na: 'N/A',
        duplicate: 'Duplicate',
      },
      matches: {
        LastNameMatch: 'Last Name',
        FirstNameMatch: 'First Name',
        DateOfBirthMatch: 'Date of Birth',
        CurrentAddressMatch: 'Current Address',
        AddressCivicMatch: 'Civic No',
        AddressStreetNameMatch: 'Street Name',
        AddressCityMatch: 'City',
        AddressProvinceMatch: 'Province',
        AddressPostalCodeMatch: 'Postal Code',
        FirstFormerAddressMatch: 'Former Address',
        SecondFormerAddressMatch: 'Former Address',
        FullNameMatch: 'Name',
      },
      none: 'None',
    },
    application_info: {
      title: 'Application Info',
      account_id: 'User ID',
      id: 'ID',
      date: 'Date',
      status: 'Status',
      package: 'Package',
      external_id: 'External ID',
    },
    issued_card: {
      title: 'Issued Card',
      public_token: 'Public Token',
      status: 'Status',
      aml_flag: 'AML Flag',
      external_id: 'External ID',
    },
    create_application_comment_action: {
      title: 'Add Comment',
      comment: 'Comment',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      success: 'Comment Saved',
    },
    comments_section: {
      title: 'Comments',
      created: 'Created',
      author: 'Author',
      comment: 'Comment',
      count: 'Comments',
      add: 'Add comment',
      delete: 'Delete',
      deleted_by: 'Deleted By',
      action: 'Action',
      no_found: 'None',
    },
  },
  bank_account: {
    bank_accounts_table: {
      key: 'Key',
      business_name: 'Business Name',
      bank_account_number: 'Bank Account Number',
      status: 'Status',
      created: 'Created',
      maximum_page_reached: '{page_count} pages found but we limit to the first {max_pages}.',
    },
    update_action: {
      title: 'Edit Bank Account',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      business_name: 'Business Name',
      success: 'Bank Account Updated',
    },
    create_action: {
      success: 'Bank Account created',
      title: 'Add Bank Account',
      business_name: 'Business Name',
      bank_institution_code: 'Bank Institution Code',
      branch_transit_number: 'Bank Transit Number',
      account_number: 'Account Number',
      pre_approved: 'Pre-approved',
      button_cancel: 'Cancel',
      button_save: 'Submit',
    },
    details: {
      bank_account_information_section: {
        created: 'Date Created',
        key: 'Key',
        title: 'Bank Account',
        actions: 'Actions',
        block: 'Block',
        activate: 'Activate',
        update: 'Edit',
        business_name: 'Business Name',
        bank_institution_code: 'Bank Institution Code',
        branch_transit_number: 'Branch Transit Number',
        account_number: 'Account Number',
        bank_account_number: 'Bank Account Number',
        status: 'Status',
      },
      activate_action: {
        title: 'Activate Bank Account',
        key: 'Key',
        bank_account_number: 'Bank Account Number',
        note: 'The bank account will be activated.',
        success: 'Bank Account activated',
        button_cancel: 'Cancel',
        button_save: 'Submit',
      },
      block_action: {
        title: 'Block Bank Account',
        key: 'Key',
        bank_account_number: 'Bank Account Number',
        note: 'The bank account will be blocked.',
        success: 'Bank Account blocked',
        button_cancel: 'Cancel',
        button_save: 'Submit',
      },
      notes_section: {
        action: 'Action',
        add: 'Add note',
        author: 'Author',
        count: 'Count',
        create_note: 'Add note',
        created: 'Created',
        delete: 'Delete',
        deleted_by: 'Deleted By',
        no_found: 'None',
        title: 'Notes',
      },
    },
    form: {
      add: 'Add',
      placeholder: 'Business Name, Bank Account Number',
      status: 'Status',
      submit: 'Search',
      title: 'Bank Accounts',
      bank_accounts_count: 'Count',
      total_results_message: 'Displaying the maximum number of results. Further refine your search to see more matches.',
    },
    no_bank_account_found: 'No bank accounts found',
    status: {
      unverified: 'Unverified',
      active: 'Active',
      blocked: 'Blocked',
      na: 'No Bank Account',
      undefined: 'Undefined',
    },
    create_note_action: {
      title: 'Add Note',
      note: 'Note',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      success: 'Note saved',
    },
    delete_note_action: {
      title: 'Delete Note',
      reason: 'Reason',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      success: 'Note deleted',
    },
  },
  boolean: {
    undefined: 'N/A',
    true: 'True',
    false: 'False',
  },
  enabled_disabled_status: {
    enabled: 'Enabled',
    disabled: 'Disabled',
  },
  branding: {
    stores: 'Stores',
    offers: 'Offers',
    publishers: 'Closed Loops',
    spaces: 'Spaces',
    no_branding_found: 'No brandings found',
    form: {
      add: 'Add',
      category: 'Category',
      placeholder: 'Name, ID',
      submit: 'Search',
      data_section: {
        title: 'Brandings',
        brandings_count: 'Count',
      },
      title: 'Branding',
      total_results_message: 'Displaying the maximum number of results. Further refine your search to see more matches.',
      type: 'Type',
    },
    types: {
      undefined: '',
      merchant: 'Merchant',
      campaign: 'Campaign',
      space: 'Space',
    },
    brandings_table: {
      id: 'ID',
      name: 'Name',
      type: 'Type',
      category: 'Category',
      images: {
        showcase: 'Showcase',
        horizontal_logo: 'Horizontal Logo',
        banner: 'Banner',
        background: 'Background',
      },
      created: 'Created',
      view_link: 'View',
      maximum_page_reached: '{page_count} pages found but we limit to the first {max_pages}.',
    },
    spaces_table: {
      id: 'ID',
      name: 'Name',
      program: 'Program',
      slug: 'Slug',
      visibility: 'Visibility',
      visibility_type: {
        private: 'Private',
        public: 'Public',
      },
    },
    details: {
      branding_images_section: {
        title_fr: 'Showcase Images French',
        title_en: 'Showcase Images English',
        action: 'Action',
        upload: 'Add Image',
        none: 'None',
        images_count: 'Count',
      },
      branding_background_images_section: {
        title: 'Background Images',
        en: {
          title: 'English',
          upload: 'Add English Image',
        },
        fr: {
          title: 'French',
          upload: 'Add French Image',
        },
      },
      branding_big_hero_images_section: {
        title: 'Big Hero Images',
        en: {
          title: 'English',
          upload: 'Add English Image',
        },
        fr: {
          title: 'French',
          upload: 'Add French Image',
        },
      },
      branding_banner_images_section: {
        title: 'Banner Images',
        en: {
          title: 'English',
          upload: 'Add English Image',
        },
        fr: {
          title: 'French',
          upload: 'Add French Image',
        },
      },
      branding_logo_horizontal_images_section: {
        title: 'Logo Horizontal Images',
        en: {
          title: 'English',
          upload: 'Add English Image',
        },
        fr: {
          title: 'French',
          upload: 'Add French Image',
        },
      },
      branding_information_section: {
        type: 'Type',
        category: 'Category',
        id: 'ID',
        title: 'Branding',
        action: 'Action',
        update_branding: 'Edit',
        color: 'Color',
        preview_color: 'Preview Color',
        update_action: {
          success: 'Branding Edited',
        },
        created: 'Created',
        name: 'Name',
        label: {
          fr: 'Label FR',
          en: 'Label EN',
        },
        description: {
          fr: 'Description FR',
          en: 'Description EN',
        },
        tagline: {
          fr: 'Tagline FR',
          en: 'Tagline EN',
        },
        region_label: {
          fr: 'Region Label FR',
          en: 'Region Label EN',
        },
        url: {
          fr: 'Url FR',
          en: 'Url EN',
        },
        phone: 'Phone',
        na: 'N/A',
        features: 'Features',
        panier_bleu: 'Panier Bleu',
        panier_bleu_value: {
          true: 'Yes',
          false: 'No',
        },
      },
      stores_section: {
        title: 'Stores',
        count: 'Count',
      },
      publishers_section: {
        title: 'Closed Loops',
        count: 'Count',
      },
      offers_section: {
        title: 'Owner',
        count: 'Count',
      },
      spaces_section: {
        title: 'Space',
        count: 'Count',
      },
    },
    create_action: {
      success: 'Branding created',
      title: 'Add Branding',
      type: 'Type',
      category: 'Category',
      name: 'Name',
      color: 'Color',
      label: {
        fr: 'Label FR',
        en: 'Label EN',
      },
      description: {
        fr: 'Description FR',
        en: 'Description EN',
      },
      url: {
        fr: 'URL FR',
        en: 'URL EN',
      },
      tagline: {
        fr: 'Tag Line FR',
        en: 'Tag Line EN',
      },
      region_label: {
        fr: 'Region Label FR',
        en: 'Region Label EN',
      },
      features_title: 'Features',
      panier_bleu: 'Panier Bleu',
      phone: 'Phone Number',
      button_cancel: 'Cancel',
      button_save: 'Submit',
    },
    update_action: {
      success: 'Branding updated',
      title: 'Update Branding',
      type: 'Type',
      category: 'Category',
      name: 'Name',
      color: 'Color',
      label: {
        fr: 'Label FR',
        en: 'Label EN',
      },
      description: {
        fr: 'Description FR',
        en: 'Description EN',
      },
      url: {
        fr: 'URL FR',
        en: 'URL EN',
      },
      tagline: {
        fr: 'Tag Line FR',
        en: 'Tag Line EN',
      },
      region_label: {
        fr: 'Region Label FR',
        en: 'Region Label EN',
      },
      features_title: 'Features',
      panier_bleu: 'Panier Bleu',
      phone: 'Phone Number',
      button_cancel: 'Cancel',
      button_save: 'Submit',
    },
  },
  business: {
    access_table: {
      remove_link: 'Remove',
      fields: {
        email: 'Email Address',
      },
    },
    webhooks: {
      title: 'Webhooks',
      count: 'Count',
      action: 'Action',
      add: 'Add',
      fields: {
        id: 'ID',
        name: 'Name',
        uri: 'URI',
        authorization: 'Authorization',
        status: 'Status',
        offer_listeners_count: 'Offer Listeners Count',
        action: 'Actions',
      },
      status: {
        active: 'Active',
        disabled: 'Disabled',
      },
      actions: {
        disable: 'Disable',
        activate: 'Activate',
        edit: 'Edit',
        remove: 'Remove',
      },
      add_modal: {
        title: 'Add Business Webhook',
        submit: 'Submit',
        cancel: 'Cancel',
        fields: {
          name: 'Name',
          uri: 'Uri',
          authorization: 'Authorization',
        },
        success: 'Webhook Added',
      },
      edit_modal: {
        title: 'Edit Business Webhook',
        submit: 'Submit',
        cancel: 'Cancel',
        fields: {
          name: 'Name',
          uri: 'Uri',
          authorization: 'Authorization',
        },
        success: 'Webhook Saved',
      },
      remove_modal: {
        title: 'Remove Business Webhook',
        text: 'Are you sure you want to remove "{webhookName}" webhook?',
        submit: 'Submit',
        cancel: 'Cancel',
        success: 'Webhook Removed',
      },
      disable_modal: {
        title: 'Disable Business Webhook',
        text: 'Are you sure you want to disable "{webhookName}" webhook?',
        submit: 'Submit',
        cancel: 'Cancel',
        success: 'Webhook Disabled',
      },
      activate_modal: {
        title: 'Activate Business Webhook',
        text: 'Are you sure you want to activate "{webhookName}" webhook?',
        submit: 'Submit',
        cancel: 'Cancel',
        success: 'Webhook Activated',
      },
    },
    activation_code_batches: 'Activation Code Batches',
    bank_account: 'Bank Account',
    no_business_found: 'No businesses found',
    form: {
      add: 'Add',
      placeholder: 'Name, ID',
      submit: 'Search',
      data_section: {
        title: 'Businesses',
        businesses_count: 'Count',
      },
      title: 'Business',
      total_results_message: 'Displaying the maximum number of results. Further refine your search to see more matches.',
    },
    business_table: {
      id: 'ID',
      name: 'Name',
      address: 'Address',
      contact: 'Contact',
      bank_account: 'Bank Account',
      created: 'Created',
      view_link: 'View',
      status: 'Status',
      maximum_page_reached: '{page_count} pages found but we limit to the first {max_pages}.',
    },
    bank_account_table: {
      fields: {
        bank_account_number: 'Bank Account Number',
        business_name: 'Business Name',
        created: 'Created',
      },
    },
    link_bank_account_action: {
      merchant_id: 'Merchant ID',
      merchant_name: 'Merchant Name',
      title: 'Link Bank Account',
      form_label: 'Search by Business Name or Bank Account',
      form: {
        no_results: 'No results found.',
        total_results_message: 'Displaying the maximum number of results. Further refine your search to see more matches.',
      },
      button_close: 'Close',
      button_search: 'Search',
      button_link: 'Link',
      success: 'Bank Account Linked',
    },
    eft_items: {
      data_section: {
        title: 'PADs',
      },
      not_found: 'No PADs',
      types: {
        fees: 'Fees',
        payment: 'Payment',
        funding: 'Funding',
        open: 'Open',
        bank_account_verification: 'Bank Account Verification',
      },
      button_generate: 'Generate',
      manual: {
        types: {
          fees: 'Fees (Hello / Monthly / Taxes Added to Amount)',
          funding: 'Funding (PeoplesTrust / Daily / No Taxes)',
          payment: 'Payment (Hello / Daily / No Taxes)',
        },
        generate_action: {
          title: 'Generate PAD Item',
          type: 'Type',
          amount: 'Amount',
          reason: 'Reason',
          button_cancel: 'Cancel',
          button_generate: 'Generate',
          successful: 'PAD item generated successfully',
        },
      },
      status: {
        ready: 'Ready',
        sent: 'Sent',
        processed: 'Processed',
        processing_error: 'Processing Error',
      },
    },
    eft_items_table: {
      date: 'Date',
      type: 'Type',
      bank_account: 'Bank Account',
      amount: 'Amount',
      eft_report_file_number: 'EFT File Number',
      status: 'Status',
    },
    create_note_action: {
      title: 'Add Note',
      note: 'Note',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      success: 'Note saved',
    },
    delete_note_action: {
      title: 'Delete Note',
      reason: 'Reason',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      success: 'Note deleted',
    },
    details: {
      access_section: {
        title: 'Access',
        add_access: 'Add',
        access_count: 'Count',
        action: 'Action',
        create_action: {
          title: 'Add Access',
          business: 'Business',
          email: 'Email Address',
          success: 'Access Added',
          cancel_text: 'Cancel',
          submit_text: 'Submit',
        },
        remove_action: {
          title: 'Remove Email Access',
          confirm: {
            title: 'Warning',
            button_cancel: 'Cancel',
            button_submit: 'Submit',
            text: 'Are you sure you want to remove access for </br><strong>{reportAccessEmail}</strong> ?',
          },
          success: 'Email Access Removed',
          button_save: 'Submit',
          button_cancel: 'Cancel',
        },
      },
      activation_code_batches_section: {
        count: 'Count',
        batch_activation_code: 'Generate Batch',
      },
      business_information_section: {
        title: 'Business',
        id: 'ID',
        name: 'Name',
        pads: 'PADS',
        system_status: {
          pad: 'Pad',
        },
        created: 'Created',
        address: 'Address',
        contact_info: 'Contact Info',
        contact_name: 'Contact Name',
        email: 'Contact Email',
        phone: 'Contact Phone Number',
        phone_extension: 'Ext.',
        number_of_employees: 'Number of Employees',
        action: 'Action',
        update_business: 'Edit',
        update_action: {
          success: 'Business Edited',
        },
        na: 'N/A',
        status_title: 'Status',
        status: {
          verified: 'Verified',
          unverified: 'Unverified',
        },
        button_set_verify: 'Set to Verified',
        button_set_unverify: 'Set to Unverified',
        action_verify: {
          title: 'Warning',
          text: 'Are you sure you want to set status to verified?',
          success: 'Status set to verified.',
          cancel_text: 'Cancel',
          submit_text: 'Submit',
        },
        action_unverify: {
          title: 'Warning',
          text: 'Are you sure you want to set status to unverified?',
          success: 'Status set to unverified.',
          cancel_text: 'Cancel',
          submit_text: 'Submit',
        },
        creation_by_provider_title: 'Created By',
        creation_by_account_title: 'Created By',
        creation_by_provider: {
          undefined: '',
          account: 'User',
          agent: 'Agent',
        },
      },
      notes_section: {
        action: 'Action',
        add: 'Add note',
        author: 'Author',
        count: 'Count',
        create_note: 'Add note',
        created: 'Created',
        delete: 'Delete',
        deleted_by: 'Deleted By',
        no_found: 'None',
        title: 'Notes',
      },
      bank_account_section: {
        title: 'Bank Account',
        na: 'N/A',
        action: 'Action',
        status: 'Status',
        link: 'Link',
        link_success: 'Bank Account Linked',
        unlink: 'Unlink',
        unlink_success: 'Bank Account Unlinked',
        unlink_action: {
          title: 'Unlink Bank Account',
          button_cancel: 'Cancel',
          button_submit: 'Submit',
          text:
            'Are you sure you want to unlink the bank account </br><strong>{bankAccountName}</strong> from business <strong>{businessName}</strong> ?',
        },
      },
      contributor_offers_section: {
        title: 'Offers Contributing To',
        count: 'Count',
      },
      owner_offers_section: {
        title: 'Offers Owned',
        count: 'Count',
      },
      stores_section: {
        title: 'Stores',
        store_count: 'Count',
      },
      publishers_section: {
        title: 'Closed Loops',
        count: 'Count',
      },
    },
    create_action: {
      success: 'Business created',
      title: 'Add Business',
      name: 'Name',
      number_of_employees: {
        label: 'Number of Employees',
        options: {
          undefined: 'Not Defined',
          null: 'Not Defined',
          '1_50': '1-50',
          '51_200': '51-200',
          '201_500': '201-500',
          '500_MORE': '500+',
        },
      },
      address: {
        title: 'Address',
        street: 'Street',
        city: 'City',
        state: 'State',
        country: 'Country',
        zip: 'Zip',
      },
      contact_info: 'Contact Info',
      contact_name: 'Contact Name',
      email: 'Contact Email',
      phone: 'Contact Phone Number',
      phone_extension: 'Phone Ext.',
      button_cancel: 'Cancel',
      button_save: 'Submit',
    },
    search_bank_account: {
      form: {
        no_results: 'No results found.',
        total_results_message: 'Displaying the maximum number of results. Further refine your search to see more matches.',
        button_search: 'Search',
        label_search: 'Search by Business Name or Bank Account',
      },
      search_results: {
        business_name: 'Business Name',
        bank_account: 'Bank Account',
        status: 'Status',
        na: 'None',
        button_select: 'Select',
      },
    },
    update_action: {
      success: 'Business updated',
      title: 'Update Business',
      name: 'Name',
      number_of_employees: {
        label: 'Number of Employees',
        options: {
          undefined: 'Not Defined',
          null: 'Not Defined',
          '1_50': '1-50',
          '51_200': '51-200',
          '201_500': '201-500',
          '500_MORE': '500+',
        },
      },
      contact_info: 'Contact Info',
      contact_name: 'Contact Name',
      email: 'Contact Email',
      phone: 'Contact Phone Number',
      phone_extension: 'Phone Ext.',
      address: {
        title: 'Address',
        street_hint: 'On paste - Address will be extracted from this format: STREET, CITY, STATE, ZIP',
        street: 'Street',
        city: 'City',
        state: 'State',
        country: 'Country',
        zip: 'Zip',
      },
      button_cancel: 'Cancel',
      button_save: 'Submit',
    },
  },
  card: {
    type: {
      unknown: '?',
      undefined: '?',
      gpr: 'Classic',
      instant: 'Instant',
    },
    no_card_found: 'No cards found',
    no_card_issued: 'No card issued',
    button_actions: 'Actions',
    button_actions_internal: 'Internal',
    button_update_cardholder: 'Edit Cardholder Info',
    button_get_activation_code: 'Get Activation Code',
    button_report_lost_stolen: 'Lost/Stolen',
    button_activate_card: 'Activate',
    button_regenerate_card: 'Regenerate',
    button_renew_card: 'Renew',
    button_get_pin: 'Get Card PIN',
    button_set_aml_flag: 'Edit AML Flag',
    button_set_peps: 'Edit PEPs Status',
    button_set_sanctions: 'Edit Sanctions Status',
    button_upload_document: 'Upload Documents',
    button_block_card: 'Block',
    button_unblock_card: 'Unblock',
    button_lock_card: 'Lock',
    button_unlock_card: 'Unlock',
    button_deactivate_card: 'Deactivate',
    simulate_transaction: 'Simulate Transaction',
    button_unblock_card_pin: 'Unblock PIN',
    block_card_action: {
      title: 'Warning',
      text: 'Are you sure you want to block this card?',
      cancel_text: 'Cancel',
      submit_text: 'Submit',
      success: 'Card Blocked',
    },
    unblock_card_action: {
      title: 'Warning',
      text: 'Are you sure you want to unblock this card?',
      cancel_text: 'Cancel',
      submit_text: 'Submit',
      success: 'Card Unblocked',
    },
    lock_card_action: {
      title: 'Warning',
      text: 'Are you sure you want to lock this card?',
      cancel_text: 'Cancel',
      submit_text: 'Submit',
      success: 'Card Locked',
    },
    unlock_card_action: {
      title: 'Warning',
      text: 'Are you sure you want to unlock this card?',
      cancel_text: 'Cancel',
      submit_text: 'Submit',
      success: 'Card Unlocked',
    },
    deactivate_card_action: {
      title: 'Warning',
      text: '<strong>THIS ACTION CANNOT BE UNDONE!</strong><br/>Are you sure you want to deactivate this card?',
      cancel_text: 'Cancel',
      submit_text: 'Submit',
      success: 'Card Deactivated',
    },
    unblock_card_pin_action: {
      title: 'Warning',
      text: '<strong>This action does not work on every POS.</strong><br/>Are you sure you want to reset the PIN of this card?',
      cancel_text: 'Cancel',
      submit_text: 'Submit',
      success: 'Card Pin Reset',
    },
    report_lost_stolen_action: {
      title: 'Report Lost/Stolen Card',
      pin: 'New pin (optional)',
      card_package_id: 'Package',
      card_product_id: 'Card Product',
      regenerate: 'Regenerate card?',
      address: {
        street: 'Street',
        city: 'City',
        state: 'State/Province',
        country: 'Country',
        zip: 'Zip/Postal Code',
      },
      button_cancel: 'Cancel',
      button_save: 'Submit',
      pin_error_label: 'pin',
      success: 'Card Reported as Lost/Stolen',
    },
    report_lost_confirmation: {
      title: 'Warning',
      text: 'Are you sure you want to report the card as lost/stolen?',
      cancel_text: 'Cancel',
      submit_text: 'Submit',
    },
    renew_card_action: {
      title: 'Renew Card',
      card_package_id: 'Package',
      card_package_id_error_label: 'Package',
      card_product_id: 'Card Product',
      pin: 'New pin',
      pin_optional: 'New pin (optional)',
      warning:
        'It is recommended to let the user renew the card by himself in the My Account section to avoid transferring the PIN in the communications (for security purpose).',
      address: {
        street: 'Street',
        city: 'City',
        state: 'State/Province',
        country: 'Country',
        zip: 'Zip/Postal Code',
      },
      button_cancel: 'Cancel',
      button_save: 'Submit',
      pin_error_label: 'pin',
      success: 'Card Renewed',
    },
    get_pin_action: {
      title: 'Card PIN',
      label: 'PIN',
      cancel_text: 'Close',
    },
    renew_card_confirmation: {
      title: 'Warning',
      text: 'Are you sure you want to renew the card?',
      cancel_text: 'Cancel',
      submit_text: 'Submit',
    },
    activate_card_action: {
      title: 'Warning',
      text: '<strong>THIS ACTION CANNOT BE UNDONE!</strong><br/>Are you sure you want to activate this card?',
      cancel_text: 'Cancel',
      submit_text: 'Submit',
      success: 'Card Activated',
    },
    regenerate_card_action: {
      title: 'Regenerate Card',
      pin: 'New pin',
      random_pin: 'Generate',
      card_package_id: 'Package',
      card_product_id: 'Card Product',
      address: {
        street: 'Street',
        city: 'City',
        state: 'State/Province',
        country: 'Country',
        zip: 'Zip/Postal Code',
      },
      button_cancel: 'Cancel',
      button_save: 'Submit',
      pin_error_label: 'pin',
      success: 'Card Regenerated',
    },
    regenerate_card_confirmation: {
      title: 'Warning',
      text: 'Are you sure you want to regenerate the card',
      cancel_text: 'Cancel',
      submit_text: 'Submit',
    },
    set_aml_flag_action: {
      title: 'Edit AML Flag',
      regenerate: 'Regenerate card?',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      aml_flag_error_label: 'AML Flag',
      aml_flag_label: 'AML Flag',
      success: 'AML Flag Set',
      aml_note: 'AML Note',
    },
    set_aml_status_action: {
      title: 'Edit AML Profile',
      success: 'AML Status Set',
    },
    set_peps_action: {
      title: 'Edit PEPs Status',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      peps_error_label: 'PEPs',
      peps_label: 'PEPs',
      success: 'PEPs Set',
      aml_note: 'AML Note',
    },
    set_sanctions_action: {
      title: 'Edit Sanctions Status',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      sanctions_error_label: 'Sanctions',
      sanctions_label: 'Sanctions',
      success: 'Sanctions Set',
      aml_note: 'AML Note',
    },
    update_cardholder_info_action: {
      title: 'Edit Cardholder Info',
      button_save: 'Submit',
      button_cancel: 'Cancel',
      fields: {
        first_name: 'First Name',
        last_name: 'Last Name',
        date_of_birth: 'Date Of Birth',
        address: {
          street: 'Street',
          city: 'City',
          state: 'State/Province',
          country: 'Country',
          zip: 'Zip/Postal Code',
        },
        email: 'Email',
        phone: 'Phone Number',
        mobile: 'Mobile Number',
        occupation: 'Occupation',
        language: 'Language',
        settings: {
          alerts: 'Alerts',
          communications: 'Communications',
        },
      },
      success: 'Cardholder Info Updated',
    },
    get_card_transaction_context: {
      title: 'Simulate Transaction',
      button_save: 'Submit',
      button_cancel: 'Cancel',
      fields: {
        merchant_id: 'Merchant ID',
        merchant_name: 'Merchant Name',
        city: 'City',
        state: 'State',
        country: 'Country',
        terminal_id: 'Terminal ID',
      },
      results: {
        title: 'Matched Wallets',
        empty: 'No wallets matched!',
        wallet_definition_id: 'Wallet Definition ID',
        wallet_definition_name: 'Wallet Definition Name',
        store_id: 'Store ID',
        fuzzy_rating: 'Fuzzy Rating',
        fuzzy_merchant_name_match: 'Fuzzy Merchant Name Match',
      },
    },
    get_activation_code: {
      title: 'Activation Code',
      button_cancel: 'Close',
      label_application_code: 'Activation Code',
      label_birth_date: 'Date of Birth',
    },
    aml_flag_change: {
      button_change: 'Change',
      button_submit: 'Submit',
    },
    pep_change: {
      button_change: 'Change',
      button_submit: 'Submit',
    },
    sanctions_change: {
      button_change: 'Change',
      button_submit: 'Submit',
    },
    view_card_title: 'Card',
    limits_and_balances: {
      title: 'Wallets',
      cannot_use: 'Not Synced (No Classic Card)',
      total_limit: 'Total Limit',
      open_wallet_limit: 'Open Limit',
      card_balance: 'Total',
      open_wallet_balance: 'Open',
      custom_wallet_balance: 'Other',
      available: 'Available',
      hold: 'Hold',
      partner: 'Program',
      load_limit: 'Load Limit',
      merchant_id: 'Merchant ID',
      merchant_name: 'Merchant Name',
      terminal_id: 'Terminal ID',
      city: 'City',
      state: 'State',
      country: 'Country',
      fuzzy_enabled: 'Fuzzy',
      external_id: 'External ID',
      merchant_name_start_with: 'Merchant Name Start With',
      merchant_name_contains: 'Merchant Name Contains',
      merchant_id_start_with: 'Merchant ID Start With',
      pad_mapping_status: 'PAD Mapping',
      debits_credits_link: 'View Debits and Credits',
      adjust_wallet_balance: 'Adjust Wallet Balance',
      no_link_available: 'No Link Available',
      load_open_wallet: 'Load Open Wallet',
      ajust_wallet_button: 'Adjust wallet balance',
      action: 'Action',
      button_update_card_max_balance: 'Update Total Limit',
      show_empty_wallets: 'Show Empty Wallets ({number}) [+]',
      hide_empty_wallets: 'Hide Empty Wallets ({number}) [-]',
    },
    adjust_wallet_balance_action: {
      title: 'Adjust Wallet Balance',
      success: 'Wallet Balance Adjusted',
      form: {
        operation_group_id: {
          label: 'Operation Group Id',
        },
        operation_type: {
          label: 'Operation Type',
        },
        reason: {
          label: 'Reason',
        },
        skip_webhook: {
          label: 'Skip Webhook',
        },
        amount: {
          label: 'Amount',
        },
        currency: {
          label: 'Currency',
        },
        credit: {
          label: 'Credit',
        },
        debit: {
          label: 'Debit',
        },
      },
      button_cancel: 'Cancel',
      button_submit: 'Submit',
      confirm: {
        title: 'Warning',
        button_cancel: 'Cancel',
        button_submit: 'Submit',
        text:
          'Are you sure you want to adjust the balance of the wallet (<strong>{walletDefinitionId}</strong>) from <strong>${currentBalance}</strong> to <strong>${targetBalance}</strong>?',
      },
    },
    cardholder_info: {
      title: 'Cardholder Info',
      first_name: 'First Name',
      last_name: 'Last Name',
      date_of_birth: 'Date of Birth',
      address: 'Address',
      phone: 'Phone Number',
      mobile: 'Mobile Number',
      email: 'Email Address',
      language: 'Language',
      e_transfer_id: 'E-Transfer Id',
      settings: {
        alerts: 'Alerts',
      },
    },
    card_info: {
      has_been_renewed:
        'This card has been renewed and the new card is pending activation. The current card can be used until the new one is activated.',
      title: 'Classic Card',
      title_instant: 'Instant Card',
      cardholder_id: 'Cardholder ID',
      public_token: 'Public Token',
      account_id: 'User ID',
      sponsor: 'Sponsor ID',
      status: 'Status',
      date_issued: 'Date Issued',
      pan: 'PAN',
      expiration: 'Expiration Date',
      application_id: 'Card Application ID',
      package: 'Package ID',
      product: 'Product ID',
      external_id: 'External ID',
      aml_flag: 'AML Flag',
      pin_status: 'PIN',
      bi: {
        activity: 'Activity',
        debits_credits: 'Debits and credits',
        transactions: 'Transactions',
        fraud_analysis: 'Fraud Analysis',
      },
    },
    aml_note_section: {
      title: 'AML Notes',
      note_created: 'Created',
      note_author: 'Author Email',
      note_note: 'Note',
      note_count: 'Notes',
      add_note: 'Add note',
      action: 'Action',
      no_found: 'None',
      note_type_card: 'Card',
      note_type_application: 'Application',
      delete: 'Delete',
      deleted_by: 'Deleted By',
    },
    aml_profile: {
      title: 'AML Profile',
      aml_flag: 'Flag',
      peps_last_verified: 'Last Verification',
      sanctions_last_verified: 'Last Verification',
      peps_match: 'PEPs',
      sanctions_match: 'Sanctions',
      peps_title: 'PEPs',
      sanctions_title: 'Sanctions',
      edit: 'Action',
      edit_button: 'Edit',
      edit_action: {
        button_cancel: 'Cancel',
        button_save: 'Submit',
      },
      fatca: {
        title: 'FATCA',
        ssn: 'SSN',
        jurisdiction: 'Jurisdiction',
        tin: 'TIN',
        no_tin_reason: 'No TIN Reason',
      },
      third_party: {
        title: '3rd Party Determination',
        is_third_party: 'Third Party',
        type: 'Type',
        incorporation_number: 'Incorporation Number',
        jurisdiction: 'Jurisdiction',
        first_name: 'First Name',
        last_name: 'Last Name',
        relationship: 'Relationship',
        occupation: 'Occupation',
        date_of_birth: 'Date of Birth',
        address: 'Address',
      },
      history_table: {
        mobile_section_heading: 'History',
        history: 'History',
        history_mobile: 'Date',
        user: 'By',
        change: 'Change',
        view_summary: 'View Summary',
        list: 'List',
        as: 'As',
        view_details: 'Action',
      },
    },
    aml_status_logs: {
      title: 'AML Status Logs',
      date: 'Date',
      type: 'Type',
      sanctions_from_match: 'Sanctions From Match',
      sanctions_to_match: 'Sanctions To Match',
      peps_from_match: 'PEPs From Match',
      peps_to_match: 'PEPs To Match',
      action: 'Action',
      hits_modal_button_title: 'View Summary',
      details_modal_button_title: 'View Details',
      details_modal_title_general: 'PEPs and Sanctions Hit Summary',
      details_modal_title_peps: 'PEPs Hit Details',
      details_modal_title_sanctions: 'Sanctions Hit Details',
    },
    aml_assessment_checks: {
      title: 'Assessment Checks',
      created: 'Created',
      hits: 'Hits',
      sanctions_matched: 'Sanctions Matched',
      sanctions_details: 'Sanctions Details',
      pep_matched: 'PEPs Matched',
      pep_details: 'PEPs Details',
      hits_modal_button_title: 'Hits',
      hits_modal_title: 'Hits',
      sanctions_details_modal_button_title: 'Sanctions Details',
      sanctions_details_modal_title: 'Sanctions Details',
      pep_details_modal_button_title: 'PEPs Details',
      pep_details_modal_title: 'PEPs Details',
    },
    create_card_comment_action: {
      title: 'Add Comment',
      comment: 'Comment',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      success: 'Comment Saved',
    },
    comments_section: {
      title: 'Comments',
      created: 'Created',
      author: 'Author',
      comment: 'Comment',
      count: 'Comments',
      add: 'Add comment',
      action: 'Action',
      no_found: 'None',
      delete: 'Delete',
      deleted_by: 'Deleted By',
    },
    update_card_max_balance_action: {
      title: 'Update Total Limit',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      form: {
        balance: {
          label: 'Total Limit',
        },
      },
      success: 'Total Limit Updated',
    },
    load_open_wallet_action: {
      title: 'Load Open Wallet',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      success: 'Open Wallet Loaded',
      fields: {
        activity: 'Activity',
        activities: {
          credit: 'Credit',
          etransfer: 'Interac e-Transfer',
        },
        amount: 'Amount',
        currency: 'Currency',
        credit: {
          modal: {
            title: 'Search Funding Business',
            button_close: 'Close',
          },
          reference_id: 'Funding Business',
          note: 'Note',
        },
        etransfer: {
          reference_id: 'Interac e-Transfer Record ID',
          reference_date: 'Interac e-Transfer Date',
          note: 'Note (optional)',
        },
      },
      confirm: {
        title: 'Warning',
        button_cancel: 'Cancel',
        button_submit: 'Submit',
        text: 'Are you sure you want to load <strong>${amount}</strong> in the wallet (<strong>{walletDefinitionId}</strong>)',
      },
    },
    wallet_transactions: {
      title: 'Debits & Credits',
      form: {
        submit: 'Search',
        transaction_id: 'Transaction ID',
        operation_group_id: 'Operation Group ID',
        load_operation_id: 'Load Operation ID',
        load_operation_id_hint: 'Load Operation ID',
      },
      table: {
        fields: {
          transaction_id: 'Transaction ID',
          transaction_date: 'Transaction Date',
          operation_group_id: 'Operation Group ID',
          load_operation_id: 'Load Operation ID',
          activity_id: 'Activity ID',
          card_trx_type: 'Transaction Type',
          wallet_trx_type: 'Type',
          wallet_definition_id: 'Wallet Definition ID',
          merchant_name: 'Merchant Name',
          amount: 'Amount',
          reason: 'Reason',
          group_adjustment_action: 'Adjustment',
          cancel_transaction_action: 'Cancel',
        },
      },
      operation_group_adjustment_action: {
        title: 'Operation Group Adjustment',
        button_submit: 'Submit',
        button_cancel: 'Cancel',
        success: 'Transaction Adjusted',
        form: {
          wallet_definition_id: 'Wallet Definition Id',
          operation_group_id: 'Operation Group Id',
          operation_type: 'Operation Type',
          adjust_available_amount: 'Adjust Available Amount',
          available_amount: 'Available Amount',
          adjust_actual_amount: 'Adjust Actual Amount',
          actual_amount: 'Actual Amount',
          reason: 'Reason',
        },
      },
      cancel_transaction_action: {
        title: 'Cancel Transaction',
        button_submit: 'Submit',
        button_cancel: 'Cancel',
        success: 'Transaction Canceled',
        form: {
          wallet_definition_id: 'Wallet Definition Id',
          transaction_id: 'Transaction Id',
          transaction_type: 'Transaction Type',
          transaction_date: 'Transaction Date',
        },
      },
    },
  },
  change_history_section: {
    title: 'Change History',
    change_count: 'Changes',
    action: 'Action',
    none_found: 'None',
    no_value: 'No Value',
  },
  change_request_history_section: {
    title: 'Change Requests',
    change_count: 'Changes',
    changes_requested: 'Update',
    action: 'Action',
    none_found: 'None',
    no_value: 'No Value',
    id: 'ID',
    create_url: 'Create Change Request Url',
    send_change_request: 'Send Change Request',
    status_title: 'Status',
    status: {
      active: 'Pending Change',
      pending: 'Pending Task',
      resolved: 'Resolved',
      expired: 'Expired',
    },
    outcome_title: 'Outcome',
    outcome: {
      rejected: 'Rejected',
      approved: 'Approved',
      undefined: 'N/A',
    },
    fields: {
      first_name: 'Update first name',
      last_name: 'Update last name',
      date_of_birth: 'Update date of birth',
      address: 'Update address',
      document_name_address: 'Upload KYC document (name and address)',
      document_name_date_of_birth: 'Upload KYC document (name and date of birth)',
      first_name_verification_required: 'Update first name',
      last_name_verification_required: 'Update last name',
      dob_verification_required: 'Update date of birth',
      address_verification_required: 'Update address',
      document_for_address_required: 'Upload KYC document (name and address)',
      document_for_dob_required: 'Upload KYC document (name and date of birth)',
    },
  },
  clerk: {
    activate_action: {
      confirm: {
        button_cancel: 'Cancel',
        button_submit: 'Submit',
        text: 'Are you sure you want to set status to active?',
        title: 'Warning',
      },
      success: 'Guest Service User activated',
    },
    change_passcode_action: {
      button_cancel: 'Cancel',
      button_save: 'Submit',
      passcode: 'Passcode',
      success: 'Passcode changed',
      title: 'Change Passcode',
    },
    deactivate_action: {
      confirm: {
        button_cancel: 'Cancel',
        button_submit: 'Submit',
        text: 'Are you sure you want to set status to deactivated?',
        title: 'Warning',
      },
      success: 'Guest Services User Deactivated',
    },
    unlock_action: {
      confirm: {
        button_cancel: 'Cancel',
        button_submit: 'Submit',
        text: 'Are you sure you want to unlock this guest services user?',
        title: 'Warning',
      },
      success: 'Guest Services User Unlocked',
    },
    suspend_action: {
      confirm: {
        button_cancel: 'Cancel',
        button_submit: 'Submit',
        text: 'Are you sure you want to set status to suspended?',
        title: 'Warning',
      },
      success: 'Guest Service User suspended',
    },
    details: {
      clerk_information_section: {
        actions: 'Actions',
        activate: 'Activate',
        change_passcode: 'Change Passcode',
        date_created: 'Created',
        deactivate: 'Deactivate',
        unlock: 'Unlock',
        edit: 'Edit',
        id: 'Id',
        name: 'Name',
        email: 'Email',
        language: 'Language',
        roles: 'Roles',
        last_auth_date: 'Last Login Date',
        status: 'Status',
        suspend: 'Suspend',
        title: 'Guest Service User',
        username: 'Username',
        establishments: 'Establishments',
      },
    },
    roles: {
      admin: 'Manager',
      clerk: 'Agent',
      superadmin: 'Administrator',
    },
    status: {
      active: 'Active',
      pending: 'Pending',
      suspended: 'Suspended',
      deactivated: 'Deactivated',
      locked: 'Locked',
    },
    update_action: {
      button_cancel: 'Cancel',
      button_save: 'Submit',
      name: 'Name',
      roles: 'Roles',
      success: 'Guest Service User updated',
      title: 'Update Guest Service User',
      username: 'Username',
    },
  },
  code: {
    aml_assessment_checks: {
      sanctions_matched: {
        negative: 'Negative',
        possible: 'Possible',
      },
      pep_matched: {
        negative: 'Negative',
        possible: 'Possible',
      },
    },
    aml_profile: {
      fatca: {
        true: 'Positive',
        false: 'Negative',
      },
      third_party_determination: {
        true: 'Positive',
        false: 'Negative',
      },
      pep_matched: {
        negative: 'Negative',
        positive: 'Positive',
        possible: 'Possible',
      },
      sanctions_matched: {
        negative: 'Negative',
        positive: 'Positive',
        possible: 'Possible',
      },
      aml_status_logs_type: {
        pep: 'Pep',
        sanctions: 'Sanctions',
      },
      sanctions_from_match: {
        negative: 'Negative',
        positive: 'Positive',
        possible: 'Possible',
      },
      sanctions_to_match: {
        negative: 'Negative',
        positive: 'Positive',
        possible: 'Possible',
      },
      pep_from_match: {
        negative: 'Negative',
        positive: 'Positive',
        possible: 'Possible',
      },
      pep_to_match: {
        negative: 'Negative',
        positive: 'Positive',
        possible: 'Possible',
      },
    },
    application: {
      language: {
        en: 'English',
        fr: 'French',
      },
      status: {
        pending: 'Pending',
        rejected: 'Rejected',
        approved: 'Approved',
        voided: 'Voided',
      },
      settings: {
        alerts: {
          true: 'Enabled',
          false: 'Disabled',
        },
      },
      kyc: {
        decision: {
          yes: 'Pass',
          no: 'Fail',
          na: 'N/A',
        },
        hit: {
          yes: 'Yes',
          no: 'No',
          na: 'N/A',
        },
        source: {
          name_and_date_of_birth_match: {
            yes: 'Yes',
            no: 'No',
          },
          name_and_address_match: {
            yes: 'Yes',
            no: 'No',
          },
        },
        also_known_as: {
          na: 'N/A',
        },
      },
      manual_approval: {
        verification_method: {
          credit_file: 'Credit File',
          dual_source: 'Dual Source',
          face_to_face: 'Face to Face',
        },
        information: {
          name_address: 'Name + Address',
          name_birthdate: 'Name + DOB',
          financial_account: 'Financial Account',
        },
        provision: {
          credit_file: 'Credit File',
          trade: 'Trade',
          document: 'Document',
        },
      },
    },
    card: {
      status_title: 'Status',
      status: {
        pending_activation: 'Pending Activation',
        active: 'Active',
        expired: 'Expired',
        lost_stolen: 'Lost/Stolen',
        deactivated: 'Deactivated',
        blocked: 'Blocked',
        locked: 'Locked',
      },
      aml_flag: {
        ok: 'Ok',
        monitoring: 'Monitoring',
        suspicious: 'Suspicious',
        fraudulent: 'Fraudulent',
        test: 'Test',
      },
      pin_status: {
        active: 'Active',
        blocked: 'Blocked',
      },
      cardholder: {
        settings: {
          alerts: {
            true: 'Enabled',
            false: 'Disabled',
          },
        },
      },
    },
    bonus_orders: {
      status: {
        pending_payment: 'Pending Payment',
        completed: 'Completed',
        error: 'Error',
        refunded: 'Refunded',
        stripe_refunded: 'Refunded (Stripe)',
        canceled: 'Canceled',
        payment_failure: 'Payment Failure',
      },
    },
    consumer_gift_card_orders: {
      status: {
        pending_payment: 'Pending Payment',
        completed: 'Completed',
        error: 'Error',
        refunded: 'Refunded',
        stripe_refunded: 'Refunded (Stripe)',
        canceled: 'Canceled',
        payment_failure: 'Payment Failure',
      },
    },
    corporate_orders: {
      status: {
        pending_payment: 'Pending Payment',
        pending_fulfillment: 'Pending Fulfillment',
        completed: 'Completed',
        error: 'Error',
        refunded: 'Refunded',
        canceled: 'Canceled',
        payment_failure: 'Payment Failure',
      },
    },
    instant_card_project_gift_certificate: {
      status: {
        available: 'True',
        deactivated: 'False',
        closed: 'Closed',
      },
    },
    purse: {
      status: {
        active: 'Active',
        blocked: 'Blocked',
      },
    },
    pad_mapping: {
      status: {
        null: 'Getting status...',
        active: 'Active',
        unverified: ' Unverified',
        blocked: 'Blocked',
        missing: 'Missing',
      },
    },
  },
  country: {
    canada: 'Canada',
  },
  dashboards: {
    transactions: {
      title: 'Transactions',
    },
    debits_credits: {
      title: 'Debits and Credits',
    },
    card_activities: {
      title: 'Card Activities',
    },
    card_applications: {
      title: 'Card Applications',
    },
    fraud_analysis: {
      title: 'Fraud Analysis',
    },
    activation_codes: {
      title: 'Activation Codes',
    },
    vouchers: {
      title: 'Vouchers',
    },
    vouchers_operations: {
      title: 'Vouchers operations',
    },
    business_suite_promoter_report: {
      title: 'Promoter Report',
    },
    business_suite_merchant_report: {
      title: 'Merchant Report',
    },
    business_suite_members: {
      title: 'Members',
    },
    business_suite_transactions: {
      title: 'Transactions',
    },
    business_suite_employer_report: {
      title: 'Employer Report',
    },
    business_suite_balances: {
      title: 'Balances',
    },
    business_suite_pads: {
      title: 'PADs',
    },
    business_suite_corporate_program_businesses_report: {
      title: 'Corporate Program - Businesses',
    },
    business_suite_corporate_program_orders_report: {
      title: 'Corporate Program - Orders',
    },
  },
  errors: {
    http: {
      amount_out_of_inventory_available_amount: 'The batch quantity amount exceeds the available inventory amount',
      email_already_assigned: 'Email already assigned',
      email_already_taken_other_account: 'Email already in use',
      firebase_email_taken: 'Email already in use',
      firebase_email_invalid: 'Email not valid',
      instant_card_batch_note_already_deleted: 'Note has already been deleted',
      store_publisher_already_exists: 'Store already added',
      voucher_transfer_not_transferable_to_self: 'Voucher needs to be transferred to a different user',
      voucher_transfer_is_part_of_paid_program: 'Vouchers from subscription-based programs cannot be transfered',
      voucher_bad_status_not_active: 'Voucher is not active.',
      voucher_has_pos_transaction: "Voucher has already more than the create voucher operation on it.  It can't be transferred",
      offer_slug_already_exists: 'The slug you are using is already in use by another offer under the same partner.',
      publisher_offer_already_exists: 'This offer is already attached',
      refund_voucher_cannot_load_open_wallet:
        'Error occurs during the open wallet load for the refund amount.  The voucher has been debited and the PAD flow is triggered.  We will need to do the load open wallet manually. DO NOT RETRY THE REFUND OPERATION PLEASE!',
      exceed_offer_purchase_limit: 'This user has exceed the limit of purchase for this offer',
      no_matching_transaction: 'No GPS Transaction found with this card acceptor rule',
      operation_group_id_not_found: '{message}',
      merchant_search_requires_minimum_characters: '{message}',
      card_acceptor_already_exists: 'A store or merchant already has a card acceptor with same values.',
      card_acceptor_must_specify_one_rule: 'You must specify at least one rule',
      bank_account_search_requires_minimum_characters: '{message}',
      aml_status_system_resource_not_found: 'AML status with public token "{publicToken}" not found',
      application_system_illegal_state: 'Application Cannot be Approved.  Refresh the page',
      application_system_resource_not_found: 'Application with id "{id}" not found',
      task_already_resolved: 'Task is already resolved',
    },
    app: {
      store: 'Unexpected Error In the Store: {message}',
      render: 'Application Rendering Error: {message}',
    },
    validation: {
      slug_accepted_characters: 'The {field} needs to contain only lowercase characters, numbers and dashes.',
      card_acceptor_must_specify_one_rule: 'You must specify at least one rule',
      email_match: "The emails don't match",
    },
    sumLessThanOrEqual: 'The {totalFieldLabel} must be {total} or less.',
    maxLines: 'Must be {nbLines} or less lines.',
    dateValidation: {
      isSameUserEmail: 'Must match the user email',
      isLater: 'Must be later than {date}',
      isBefore: 'Must be before than {date}',
      fundingBonusSumsTo: 'The sum of the 3 Funding Bonus fields must equal {total}.',
    },
    offerFieldValidation: {
      bonusDurationBetween: 'Must be less than or equal to Closure, and {max}; and greater than or equal to {min}.',
      closureBetween: 'Must be less than or equal to {max}; greater than Bonus Duration, and greater than or equal to {min}.',
      minimumPaymentBetween: 'Must be less than or equal to User Contribution Limit, and {max}; and greater than or equal to {min}.',
      limitPaymentBetween: 'Must be less than or equal to {max}; and greater than or equal to Minimum Payment, Default Payment, and {min}.',
      stepBetween: 'Must be less than or equal to User Contribution Limit, and {max}; and greater than or equal to {min}.',
      defaultPaymentBetween:
        'Must be less than or equal to User Contribution Limit and {max}; and greater than or equal to Minimum Payment and {min}.',
      withinMinStep: 'The value must be at minimum {min} and with {step} value steps. Suggestion: {suggestion}',
    },
    changeRequestValidation: {
      minArrayLength: 'At least one box must be checked',
    },
    systemError: 'SYSTEM ERROR: ',
    system: {
      system_duplicate_key_found: 'Duplicate key found',
      system_resource_not_found: 'Not found',
      system_forbidden: 'Forbidden: {message}',
      system_unauthorized: 'Unauthorized: {message}',
      system_bad_gateway: 'Could not reach the api for some reason',
      validation_error: 'Validation Error: {message}',
    },
    validate_range: '{value} is not in range',
  },
  gender: {
    null: 'Not Defined',
    undefined: 'Not Defined',
    female: 'Female',
    male: 'Male',
    non_binary: 'Non-Binary / Third-Gender',
  },
  instant_card: {
    no_instant_card_project_found: 'No instant card projects found',
    no_instant_card_batch_found: 'No instant card batches found',
    no_instant_card_card_found: 'No instant cards found',
  },
  instant_card_batches: {
    status: {
      approved: 'Approved',
      pending: 'Pending',
      canceled: 'Canceled',
      processed: 'Processed',
      generated: 'Generated',
      delivered: 'Delivered',
      undefined: 'Undefined',
    },
    form: {
      title: 'Batches',
      batch_count: 'Count',
      action: 'Action',
      add_project: 'Add',
      program: 'Program',
      project: 'Project',
      business: 'Business',
      establishment: 'Establishment',
      status: 'Status',
      placeholder: 'ID',
      submit: 'Search',
      total_results_message: 'Displaying the maximum number of results. Further refine your search to see more matches.',
    },
    batches_table: {
      id: 'ID',
      program: 'Program',
      name: 'Name',
      project: 'Project',
      establishment: 'Establishment',
      owner: 'Business',
      shipping_address: 'Shipping Address',
      quantity: 'Quantity',
      card_package_id: 'Card Package ID',
      created: 'Created',
      status: 'Status',
    },
    generated_cards_table: {
      id: 'ID',
      public_token: 'Public Token',
      unique_number: 'Unique Number',
      barcode: 'Barcode',
      program: 'Program',
      project: 'Project',
      status: 'Status',
      user: 'User',
    },
    create_action: {
      success: 'Batch created',
      title: 'Add Batch',
      description: 'Batch will be saved but not yet processed until approved.',
      name: 'Name',
      business: 'Business',
      establishment: 'Establishment',
      card_package: 'Card Package',
      project: 'Project',
      quantity: 'Quantity',
      shipping_address: 'Shipping Address',
      pre_activation_required: 'Requires Pre-Activation',
      address_title: 'Address',
      business_address_note: 'Use Address/Contact of Business',
      establishment_address_note: 'Use Address/Contact of Establishment',
      attn_title: 'Attn',
      attn: {
        name: 'Name',
        phone: 'Phone',
        business_name: 'Business Name',
      },
      address: {
        title: 'Address',
        street: 'Street',
        city: 'City',
        state: 'State',
        country: 'Country',
        zip: 'Zip',
      },
      button_cancel: 'Cancel',
      button_save: 'Submit',
    },
    create_note_action: {
      title: 'Add Note',
      note: 'Note',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      success: 'Note saved',
    },
    delete_note_action: {
      title: 'Delete Note',
      reason: 'Reason',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      success: 'Note deleted',
    },
    update_tracking_action: {
      success: 'Tracking updated',
      title: 'Update Tracking',
      tracking_number: 'Tracking Number',
      business: 'Business',
      establishment: 'Establishment',
      attn: 'Attn Name',
      phone: 'Attn Phone',
      business_name: 'Attn Business',
      business_note: 'Use Business Contact of Business',
      establishment_note: 'Use Business Contact of Establishment',
      button_cancel: 'Cancel',
      button_save: 'Submit',
    },
    update_action: {
      success: 'Batch updated',
      title: 'Update Batch',
      name: 'Name',
      id: 'ID',
      business: 'Business',
      quantity: 'Quantity',
      pre_activation_required: 'Requires Pre-Activation',
      shipping_address: 'Shipping Address',
      address_title: 'Address',
      business_address_note: 'Use Address of Business',
      establishment_address_note: 'Use Address of Establishment',
      no_project_option: 'None',
      address: {
        title: 'Address',
        street: 'Street',
        city: 'City',
        state: 'State',
        country: 'Country',
        zip: 'Zip',
      },
      button_cancel: 'Cancel',
      button_save: 'Submit',
    },
    cancel_action: {
      success: 'Batch cancelled',
      title: 'Cancel Batch',
      id: 'Batch',
      button_cancel: 'Cancel',
      button_save: 'Submit',
    },
    approve_action: {
      success: 'Batch approved',
      title: 'Approve Batch',
      id: 'Batch',
      button_cancel: 'Cancel',
      button_save: 'Submit',
    },
    mark_as_delivered_action: {
      success: 'Batch marked as delivered',
      title: 'Mark Batch As Delivered',
      id: 'Batch',
      message: 'Are you sure to set this batch as delivered?',
      button_cancel: 'Cancel',
      button_submit: 'Submit',
    },
    details: {
      batch_information_section: {
        title: 'Batch',
        id: 'ID',
        name: 'Name',
        program: 'Program',
        project: 'Project',
        establishment: 'Establishment',
        business_name: 'Business Name',
        quantity: 'Quantity',
        quantity_pre_activated: 'Quantity Pre-Activated',
        quantity_destroyed: 'Quantity Destroyed',
        card_package_id: 'Card Package ID',
        created: 'Date Created',
        status: 'Status',
        action: 'Action',
        actions: 'Actions',
        update_batch: 'Edit',
        cancel_batch: 'Cancel',
        approve_batch: 'Approve',
        mark_as_delivered: 'Mark As Delivered',
        pre_activation_required: 'Requires Pre-Activation',
        update_action: {
          success: 'Batch Edited',
        },
        cancel_action: {
          success: 'Batch Cancelled',
        },
        approve_action: {
          success: 'Batch Approved',
        },
        mark_as_delivered_action: {
          success: 'Mark as Delivered',
        },
        na: 'N/A',
      },
      delivery_section: {
        action: 'Action',
        attn: 'Attn Name',
        phone: 'Attn Phone',
        business_name: 'Attn Business',
        business: 'Business',
        gps_job_number: 'GPS Job No',
        gps_filename: 'GPS Filename',
        generated_date: 'Generated Date',
        notes: 'Notes',
        shipping_address: 'Shipping Address',
        title: 'Delivery',
        tracking: 'Tracking',
        tracking_number: 'Tracking Number',
        work_order_id: 'ABCorp Work Order Id',
      },
      notes_section: {
        action: 'Action',
        add: 'Add note',
        author: 'Author',
        count: 'Count',
        create_note: 'Add note',
        created: 'Created',
        delete: 'Delete',
        deleted_by: 'Deleted By',
        no_found: 'None',
        title: 'Notes',
      },
      generated_cards: {
        title: 'Generated Cards',
        cards_count: 'Count',
        add_publisher: 'Add',
        placeholder: 'ID, Public Token, Barcode, Unique Number',
        program: 'Program',
        status: 'Status',
        action: 'Action',
        actions: 'Actions',
        count: 'Count',
        link_cards_action: {
          title: 'Link All',
          cancel_text: 'Cancel',
          submit_text: 'Submit',
          confirm: {
            title: 'Warning',
            button_cancel: 'Cancel',
            button_submit: 'Submit',
            text: 'Are you sure you want to link all?',
            // text: 'Are you sure you want to link all </br><strong>{offersPublisherName}</strong> ?',
          },
          success: 'Cards Linked',
        },
        unlink_cards_action: {
          title: 'Unlink All',
          cancel_text: 'Cancel',
          submit_text: 'Submit',
          confirm: {
            title: 'Warning',
            button_cancel: 'Cancel',
            button_submit: 'Submit',
            text: 'Are you sure you want to unlink all?',
          },
          success: 'Cards Unlinked',
        },
        pause_cards_action: {
          title: 'Pause All',
          cancel_text: 'Cancel',
          submit_text: 'Submit',
          confirm: {
            title: 'Warning',
            button_cancel: 'Cancel',
            button_submit: 'Submit',
            text: 'Are you sure you want to pause all cards?',
          },
          success: 'Cards Paused',
        },
        resume_cards_action: {
          title: 'Resume All',
          cancel_text: 'Cancel',
          submit_text: 'Submit',
          confirm: {
            title: 'Warning',
            button_cancel: 'Cancel',
            button_submit: 'Submit',
            text: 'Are you sure you want to resume all cards?',
          },
          success: 'Cards Resumed',
        },
        form: {
          submit: 'Search',
          total_results_message: 'Displaying the maximum number of results. Further refine your search to see more matches.',
        },
      },
      link_cards_section: {
        title: 'Link All',
        note: 'All non-activated cards generated from the batch will be linked to the selected project.',
        batch: 'Batch',
        total_cards: 'Total Cards',
        project: 'Project',
        button_cancel: 'Cancel',
        button_save: 'Submit',
      },
      unlink_cards_section: {
        title: 'Unlink All',
        note: 'All non-activated cards generated from the batch will be unlinked from their project.',
        batch: 'Batch',
        total_cards: 'Total Cards',
        button_cancel: 'Cancel',
        button_save: 'Submit',
      },
      pause_cards_section: {
        title: 'Pause All',
        note: 'All non-activated cards generated from the batch will be paused and not activatable until resumed.',
        batch: 'Batch',
        total_cards: 'Total Cards',
        button_cancel: 'Cancel',
        button_save: 'Submit',
      },
      resume_cards_section: {
        title: 'Resume All',
        note: 'All paused cards generated from the batch will be resumed and activatable.',
        batch: 'Batch',
        total_cards: 'Total Cards',
        button_cancel: 'Cancel',
        button_save: 'Submit',
      },
    },
  },
  instant_card_cards: {
    status: {
      pending_activation: 'Pending Activation',
      active: 'Activated',
      paused: 'Paused',
      destroyed: 'Destroyed',
      undefined: 'Undefined',
    },
    activation_enabled: {
      true: 'True',
      false: 'False',
    },
    form: {
      title: 'Cards',
      instant_cards_count: 'Count',
      action: 'Action',
      add_project: 'Add',
      program: 'Program',
      project: 'Project',
      status: 'Status',
      activation_enabled: 'Pre-Activated',
      placeholder: 'ID, Public Token, Batch ID, Barcode, Unique Number',
      submit: 'Search',
      total_results_message: 'Displaying the maximum number of results. Further refine your search to see more matches.',
    },
    cards_table: {
      id: 'ID',
      public_token: 'Public Token',
      barcode: 'Barcode',
      unique_number: 'Unique Number',
      program: 'Program',
      project: 'Project',
      batch: 'Batch',
      status: 'Status',
      user: 'User',
      activation_enabled: 'Pre-Activated',
      maximum_page_reached: '{page_count} pages found but we limit to the first {max_pages}.',
    },
    create_action: {
      success: 'Batch created',
      title: 'Add Batch',
      description: 'Batch will be saved but not yet processed until approved.',
      name: 'Name',
      business: 'Business',
      project: 'Project',
      quantity: 'Quantity',
      shipping_address: 'Shipping Address',
      address_title: 'Address',
      address_note: 'Use Address/Contact of Business',
      address: {
        title: 'Address',
        street: 'Street',
        city: 'City',
        state: 'State',
        country: 'Country',
        zip: 'Zip',
      },
      button_cancel: 'Cancel',
      button_save: 'Submit',
    },
    details: {
      vouchers_section: {
        title: 'Vouchers',
        voucher_count: 'Count',
      },
      vouchers_table: {
        fields: {
          id: 'ID',
          user: 'User',
          card_application_id: 'Card Application ID',
          cardholder: 'Card Public Token',
          payment: 'Contribution',
          bonus: 'Reward',
          received: 'Received',
          remaining: 'Remaining',
          publisher: 'Closed Loop',
          created: 'Created',
          status: 'Status',
        },
        view_link: 'View',
      },
      card_information_section: {
        purchase_channel: 'Purchase Channel',
        title: 'Instant Card',
        actions: 'Actions',
        id: 'ID',
        public_token: 'Public Token',
        barcode: 'Barcode',
        unique_number: 'Unique Number',
        masked_pan: 'Masked PAN',
        status: 'Status',
        user: 'User',
        date_created: 'Date Created',
        action: 'Action',
        activation_enabled: 'Pre-Activated',
        activate_card: 'Activate',
        pre_activate: 'Pre-Activate',
        pre_activate_success: 'Card Pre-Activated Successfully',
        mark_as_non_pre_activated: 'Mark as Non Pre-Activated',
        mark_as_non_pre_activated_success: 'Card Mask as Non Pre-Activated Successfully',
        activate_action: {
          success: 'Card Activated',
        },
        pause_card: 'Pause',
        pause_action: {
          success: 'Card Paused',
        },
        resume_card: 'Resume',
        resume_action: {
          success: 'Card Resumed',
        },
        destroy_card: 'Destroy',
        destroy_action: {
          success: 'Card Destroyed',
        },
        na: 'N/A',
      },
      mark_as_non_pre_activated_action: {
        confirm: {
          title: 'Mask as Non Pre-Activated',
          button_cancel: 'Cancel',
          button_submit: 'Submit',
          text: 'Are you sure you want to mark as Non Pre-Activated the card "{cardName}" ?',
        },
      },
      pre_activate_action: {
        confirm: {
          title: 'Pre-Activate',
          button_cancel: 'Cancel',
          button_submit: 'Submit',
          text: 'Are you sure you want to Pre-Activate the card "{cardName}" ?',
        },
      },
      activate_card_section: {
        title: 'Activate Card',
        first_name: 'First Name',
        country: 'Country',
        postal_code: 'Postal Code',
        birth_year: 'Birth Year',
        public_token: 'Public Token',
        user: 'User',
        button_cancel: 'Cancel',
        button_save: 'Submit',
      },
      pause_card_section: {
        title: 'Pause Card',
        note: 'The card will not be activatable until resumed.',
        public_token: 'Public Token',
        button_cancel: 'Cancel',
        button_save: 'Submit',
      },
      resume_card_section: {
        title: 'Resume Card',
        note: 'The card will be resumed and activatable.',
        public_token: 'Public Token',
        button_cancel: 'Cancel',
        button_save: 'Submit',
      },
      destroy_card_section: {
        title: 'Destroy Card',
        note: 'The card will be permanently deactivated.',
        public_token: 'Public Token',
        button_cancel: 'Cancel',
        button_save: 'Submit',
      },
      batch_section: {
        title: 'Batch',
        id: 'ID',
        name: 'Name',
        owner: 'Business',
        shipping_address: 'Shipping Address',
        na: 'N/A',
      },
      project_section: {
        title: 'Project',
        id: 'ID',
        name: 'Name',
        program: 'Program',
        actions: 'Actions',
        type: 'Type',
        na: 'N/A',
        link_card_action: {
          title: 'Link',
          cancel_text: 'Cancel',
          submit_text: 'Submit',
          confirm: {
            title: 'Warning',
            button_cancel: 'Cancel',
            button_submit: 'Submit',
            text: 'Are you sure you want to link card to project?',
            // text: 'Are you sure you want to link all </br><strong>{offersPublisherName}</strong> ?',
          },
          success: 'Card Linked',
        },
        unlink_card_action: {
          title: 'Unlink',
          cancel_text: 'Cancel',
          submit_text: 'Submit',
          confirm: {
            title: 'Warning',
            button_cancel: 'Cancel',
            button_submit: 'Submit',
            text: 'Are you sure you want to unlink card from project?',
          },
          success: 'Card Unlinked',
        },
      },
      link_card_section: {
        title: 'Link',
        public_token: 'Public Token',
        project: 'Project',
        button_cancel: 'Cancel',
        button_save: 'Submit',
      },
      unlink_card_section: {
        title: 'Unlink',
        public_token: 'Public Token',
        project: 'Project',
        button_cancel: 'Cancel',
        button_save: 'Submit',
      },
    },
    vouchers_table: {
      fields: {
        id: 'ID',
        user: 'User',
        card_application_id: 'Card Application ID',
        offer_id: 'Offer',
        partner_id: 'Program',
        cardholder: 'Card Public Token',
        payment: 'Contribution',
        bonus: 'Reward',
        received: 'Received',
        remaining: 'Remaining',
        publisher: 'Closed Loop',
        created: 'Created',
        status: 'Status',
      },
      view_link: 'View',
    },
  },
  instant_card_projects: {
    form: {
      title: 'Projects',
      action: 'Action',
      projects_count: 'Count',
      add_project: 'Add',
      placeholder: 'Name, ID',
      submit: 'Search',
      total_results_message: 'Displaying the maximum number of results. Further refine your search to see more matches.',
      program: 'Program',
    },
    projects_table: {
      id: 'ID',
      name: 'Name',
      program: 'Program',
      type: 'Type',
      offers_available: 'Offers',
      batches_pending: 'Batches Pending',
      batches_generated: 'Batches Generated',
      batches_delivered: 'Batches Delivered',
      cards_paused: 'Cards Paused',
      cards_pending_activation: 'Cards Pending Activation',
      cards_active: 'Cards Active',
      created: 'Created',
    },
    linked_cards_table: {
      id: 'ID',
      public_token: 'Public Token',
      barcode: 'Barcode',
      batch: 'Batch',
      status: 'Status',
      user: 'User',
    },
    create_action: {
      success: 'Project created',
      title: 'Add Project',
      name: 'Name',
      program: 'Program',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      structure: {
        title: 'Structure',
        options: {
          single: 'Single',
          multiple: 'Multiple',
        },
      },
      type: {
        title: 'Type',
        options: {
          blank: 'Blank',
          reward: 'Reward',
        },
      },
    },
    create_note_action: {
      title: 'Add Note',
      note: 'Note',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      success: 'Note saved',
    },
    delete_note_action: {
      title: 'Delete Note',
      reason: 'Reason',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      success: 'Note deleted',
    },
    update_action: {
      success: 'Project updated',
      title: 'Update Project',
      name: 'Name',
      button_cancel: 'Cancel',
      button_save: 'Submit',
    },
    update_gift_certificate_action: {
      success: 'Gift Certificate updated',
      title: 'Update Gift Certificate',
      payment_amount: 'Contribution Amount',
      bonus_amount: 'Reward Amount',
      total_amount: 'Total Amount',
      button_cancel: 'Cancel',
      button_save: 'Submit',
    },
    details: {
      notes_section: {
        action: 'Action',
        add: 'Add note',
        author: 'Author',
        count: 'Count',
        create_note: 'Add note',
        created: 'Created',
        delete: 'Delete',
        deleted_by: 'Deleted By',
        no_found: 'None',
        title: 'Notes',
      },
      project_information_section: {
        title: 'Project',
        id: 'ID',
        name: 'Name',
        program: 'Program',
        structure: {
          title: 'Structure',
          options: {
            multiple: 'Multiple',
            single: 'Single',
            blank: 'Blank',
          },
        },
        type: {
          title: 'Type',
          options: {
            reward: 'Reward',
            blank: 'Blank',
          },
        },
        created: 'Date Created',
        action: 'Action',
        update_project: 'Edit',
        update_action: {
          success: 'Project Edited',
        },
        na: 'N/A',
      },
      gift_certificates_section: {
        title: 'Offers',
        count: 'Count',
        not_available: 'Offers Not Available',
        action: 'Action',
        create: 'Add',
        total_amount: 'Total Amount',
        add_action: {
          success: 'Offer Added',
        },
        na: 'N/A',
      },
      batch_section: {
        title: 'Batches',
        count: 'Count',
        action: 'Action',
        create: 'Add',
        add_action: {
          success: 'Batch Added',
        },
        na: 'N/A',
      },
      add_batch_section: {
        title: 'Add Batch',
        note: 'Batch will be saved but not yet processed until approved.',
        name: 'Name',
        business: 'Business',
        project: 'Project',
        quantity: 'Quantity',
        shipping_address: 'Shipping Address',
        card_package: 'Card Package',
        pre_activation_required: 'Requires Pre-Activation',
        establishment: 'Establishment',
        business_name: 'Business Name',
        business_note: 'Use Address/Contact of Business',
        establishment_note: 'Use Address/Contact of Establishment',
        attn_title: 'Attn',
        attn: {
          name: 'Name',
          phone: 'Phone',
          business_name: 'Business Name',
        },
        address_title: 'Address',
        address: {
          title: 'Address',
          street: 'Street',
          city: 'City',
          state: 'State',
          country: 'Country',
          zip: 'Zip',
        },
        button_cancel: 'Cancel',
        button_save: 'Submit',
      },
      add_gift_certificate_section: {
        title: 'Add Gift Certificate Offer',
        project: 'Project',
        offer: 'Offer',
        payment_amount: 'Contribution Amount',
        bonus_amount: 'Reward Amount',
        total_amount: 'Total Amount',
        button_cancel: 'Cancel',
        button_save: 'Submit',
      },
      linked_cards: {
        title: 'Linked Cards',
        cards_count: 'Count',
        placeholder: 'ID, Public Token, Batch ID, Barcode, Unique Number',
        status: 'Status',
        action: 'Action',
        actions: 'Actions',
        count: 'Count',
        link_cards_action: {
          title: 'Link All',
          cancel_text: 'Cancel',
          submit_text: 'Submit',
          confirm: {
            title: 'Warning',
            button_cancel: 'Cancel',
            button_submit: 'Submit',
            text: 'Are you sure you want to link all?',
            // text: 'Are you sure you want to link all </br><strong>{offersPublisherName}</strong> ?',
          },
          success: 'Cards Linked',
        },
        unlink_cards_action: {
          title: 'Unlink All',
          cancel_text: 'Cancel',
          submit_text: 'Submit',
          confirm: {
            title: 'Warning',
            button_cancel: 'Cancel',
            button_submit: 'Submit',
            text: 'Are you sure you want to unlink all?',
          },
          success: 'Cards Unlinked',
        },
        pause_cards_action: {
          title: 'Pause All',
          cancel_text: 'Cancel',
          submit_text: 'Submit',
          confirm: {
            title: 'Warning',
            button_cancel: 'Cancel',
            button_submit: 'Submit',
            text: 'Are you sure you want to pause all cards?',
          },
          success: 'Cards Paused',
        },
        resume_cards_action: {
          title: 'Resume All',
          cancel_text: 'Cancel',
          submit_text: 'Submit',
          confirm: {
            title: 'Warning',
            button_cancel: 'Cancel',
            button_submit: 'Submit',
            text: 'Are you sure you want to resume all cards?',
          },
          success: 'Cards Resumed',
        },
        form: {
          submit: 'Search',
          total_results_message: 'Displaying the maximum number of results. Further refine your search to see more matches.',
        },
      },
      unlink_cards_section: {
        title: 'Unlink All',
        note: 'All non-activated cards linked to the project will be unlinked from the project.',
        project: 'Project',
        total_cards: 'Total Cards',
        button_cancel: 'Cancel',
        button_save: 'Submit',
      },
      pause_cards_section: {
        title: 'Pause All',
        note: 'All non-activated cards linked to the project will be paused and not activatable until resumed.',
        project: 'Project',
        total_cards: 'Total Cards',
        button_cancel: 'Cancel',
        button_save: 'Submit',
      },
      resume_cards_section: {
        title: 'Resume All',
        note: 'All non-activated cards linked to the project will be resumed and activatable.',
        project: 'Project',
        total_cards: 'Total Cards',
        button_cancel: 'Cancel',
        button_save: 'Submit',
      },
    },
    gift_certificates_table: {
      id: 'ID',
      name: 'Name',
      business: 'Owner',
      program: 'Program',
      incentive: 'Incentive',
      visibility: 'Visibility',
      activation_period: 'Period',
      publishers: 'Closed Loops',
      remaining_inventory: 'Inventory',
      offer_status: 'Offer Status',
      project_status: 'Active',
      edit: 'Edit',
      edit_link: 'Edit',
      actions: 'Actions',
      activate: 'Activate',
      deactivate: 'Deactivate',
      remove: 'Remove',
      update_action: {
        success: 'Gift Certificate Updated',
      },
      activate_action: {
        title: 'Activate Gift Certificate',
        text: 'Are you sure you want to activate this gift certificate?',
        button_cancel: 'Cancel',
        button_submit: 'Submit',
        success: 'Gift Certificate Activated',
      },
      deactivate_action: {
        title: 'Deactivate Gift Certificate',
        text: 'Are you sure you want to deactivate this gift certificate?',
        button_cancel: 'Cancel',
        button_submit: 'Submit',
        success: 'Gift Certificate Deactivated',
      },
      remove_action: {
        title: 'Remove Gift Certificate',
        text: 'Are you sure you want to remove this gift certificate?',
        button_cancel: 'Cancel',
        button_submit: 'Submit',
        success: 'Gift Certificate Removed',
      },
    },
    batches_table: {
      id: 'ID',
      business: 'Business',
      name: 'Name',
      shipping_address: 'Shipping Address',
      quantity: 'Quantity',
      card_package_id: 'Card Package ID',
      date_created: 'Date Created',
      status: 'Status',
    },
  },
  inventory: {
    no_inventory_found: 'No inventories found',
    form: {
      add: 'Add',
      placeholder: 'Name, ID',
      partner: 'Program',
      business: 'Owner',
      submit: 'Search',
      data_section: {
        title: 'Inventories',
        inventories_count: 'Count',
      },
      title: 'Inventory',
      total_results_message: 'Displaying the maximum number of results. Further refine your search to see more matches.',
    },
    inventories_table: {
      id: 'ID',
      name: 'Name',
      external_id: 'External Id',
      initial_amount: 'Initial Amount',
      available_amount: 'Available Amount',
      current_amount: 'Current Amount',
      owner: 'Owner',
      created: 'Created',
      view_link: 'View',
    },
    mouvements: {
      title: 'Inventory History',
      form: {
        submit: 'Submit',
        types: 'Types',
      },
      types: {
        create: 'Create',
        adjustment_debit: 'Adjustment (Debit)',
        adjustment_credit: 'Adjustment (Credit)',
        voucher_debit: 'Voucher (Debit)',
        voucher_credit: 'Voucher (Credit)',
        activation_code_debit: 'Activation Code (Debit)',
        activation_code_credit: 'Activation Code (Credit)',
        gift_certificate_purchase_fees: 'Gift Certificate Purchase (Fees)',
        purchase_debit: 'Purchase (Debit)',
        clear: 'Clear',
      },
    },
    offer: {
      title: 'Offers',
    },
    offer_promotions: {
      title: 'Offer Promotions',
    },
    offer_promotions_table: {
      id: 'ID',
      offer_id: 'Offer',
      owner: 'Owner',
      period: 'Period',
      program: 'Program',
      reward_percent: 'Incentive',
      status: 'Status',
      type: 'Type',
    },
    mouvements_table: {
      id: 'ID',
      type: 'Type',
      amount: 'Amount',
      created: 'Created',
      voucher: 'Voucher',
      activation_code: 'Activation Code',
      reason: 'Reason',
      agent: 'Agent / Service',
    },
    business_table: {
      fields: {
        id: 'ID',
        name: 'Name',
        contact: 'Contact',
        address: 'Address',
        bank_account: 'Bank Account',
        created: 'Created',
      },
      update_link: 'Change',
    },
    details: {
      business_section: {
        title: 'Owner',
        count: 'Count',
        action: 'Action',
        link: 'Link',
        unlink: 'Unlink',
        unlink_action: {
          title: 'Unlink Business',
          button_cancel: 'Cancel',
          button_submit: 'Submit',
          text: 'Are you sure you want to unlink the business </br><strong>{businessName}</strong> from inventory <strong>{inventoryName}</strong> ?',
          success: 'Business Unlinked',
        },
        link_action: {
          success: 'Business Linked',
          success_unlink: 'Business Unlinked',
          title: 'Link Business',
          inventory: 'Inventory',
          button_cancel: 'Cancel',
          button_save: 'Submit',
          button_unlink: 'Unlink',
          business: 'Business',
          none: 'None',
        },
      },
      notes_section: {
        action: 'Action',
        add: 'Add note',
        author: 'Author',
        count: 'Count',
        create_note: 'Add note',
        created: 'Created',
        delete: 'Delete',
        deleted_by: 'Deleted By',
        no_found: 'None',
        title: 'Notes',
      },
      offer_section: {
        title: 'Owner',
        count: 'Count',
        action: 'Action',
        link: 'Link',
        unlink: 'Unlink',
        unlink_action: {
          title: 'Unlink Business',
          button_cancel: 'Cancel',
          button_submit: 'Submit',
          text: 'Are you sure you want to unlink the business </br><strong>{businessName}</strong> from inventory <strong>{inventoryName}</strong> ?',
          success: 'Business Unlinked',
        },
        link_action: {
          success: 'Business Linked',
          success_unlink: 'Business Unlinked',
          title: 'Link Business',
          inventory: 'Inventory',
          button_cancel: 'Cancel',
          button_save: 'Submit',
          button_unlink: 'Unlink',
          business: 'Business',
          none: 'None',
        },
      },
      inventory_information_section: {
        id: 'ID',
        title: 'Inventory',
        action: 'Action',
        update_inventory: 'Edit',
        update_action: {
          success: 'Inventory Edited',
        },
        adjust_inventory: 'Adjust',
        adjust_action: {
          success: 'Inventory Adjusted',
        },
        clear_inventory: 'Clear',
        clear_action: {
          success: 'Inventory Cleared',
        },
        name: 'Name',
        external_id: 'External Id',
        initial_amount: 'Initial Amount',
        available_amount: 'Available Amount',
        current_amount: 'Current Amount',
        na: 'N/A',
      },
    },
    create_action: {
      success: 'Inventory Created',
      title: 'Add Inventory',
      name: 'Name',
      external_id: 'External Id',
      business: 'Business',
      initial_amount: 'Initial Amount',
      button_cancel: 'Cancel',
      button_save: 'Submit',
    },
    clear_inventory_action: {
      title: 'Clear Inventory',
      reason: 'Reason',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      success: 'Inventory cleared',
    },
    update_action: {
      success: 'Inventory updated',
      title: 'Update Inventory',
      name: 'Name',
      external_id: 'External Id',
      button_cancel: 'Cancel',
      button_save: 'Submit',
    },
    adjust_action: {
      success: 'Inventory Adjusted',
      title: 'Adjust Inventory',
      name: 'Name',
      reason: 'Reason',
      adjust_amount: 'Amount to Adjust',
      new_current_amount: 'New Current Amount',
      new_available_amount: 'New Available Amount',
      button_cancel: 'Cancel',
      button_save: 'Submit',
    },
    create_note_action: {
      title: 'Add Note',
      note: 'Note',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      success: 'Note saved',
    },
    delete_note_action: {
      title: 'Delete Note',
      reason: 'Reason',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      success: 'Note deleted',
    },
  },
  franchise: {
    no_franchise_found: 'No franchises found',
    form: {
      add: 'Add',
      placeholder: 'Name, ID',
      submit: 'Search',
      data_section: {
        title: 'Franchises',
        franchises_count: 'Count',
      },
      title: 'Franchise',
      total_results_message: 'Displaying the maximum number of results. Further refine your search to see more matches.',
    },
    franchise_table: {
      id: 'ID',
      name: 'Name',
      branding: 'Branding',
      creation_date: 'Creation Date',
    },
    details: {
      franchise_information_section: {
        id: 'ID',
        title: 'Franchise',
        action: 'Action',
        update_franchise: 'Edit',
        update_action: {
          success: 'Franchise Edited',
        },
        name: 'Name',
        branding: 'Branding',
        na: 'N/A',
      },
    },
    stores: {
      title: 'Stores',
      count: 'Count',
    },
    create_action: {
      success: 'Franchise Created',
      title: 'Add Franchise',
      name: 'Name',
      branding: 'Branding',
      button_cancel: 'Cancel',
      button_save: 'Submit',
    },
    update_action: {
      success: 'Franchise updated',
      title: 'Update Franchise',
      name: 'Name',
      branding: 'Branding',
      button_cancel: 'Cancel',
      button_save: 'Submit',
    },
  },
  destination: {
    no_destination_found: 'No destinations found',
    form: {
      add: 'Add',
      placeholder: 'Name, ID',
      submit: 'Search',
      data_section: {
        title: 'Shopping Destinations',
        destinations_count: 'Count',
      },
      title: 'Shopping Destination',
      total_results_message: 'Displaying the maximum number of results. Further refine your search to see more matches.',
    },
    destination_table: {
      id: 'ID',
      name: 'Name',
      branding: 'Branding',
      address: 'Address',
      creation_date: 'Creation Date',
    },
    details: {
      destination_information_section: {
        id: 'ID',
        title: 'Shopping Destination',
        action: 'Action',
        update_destination: 'Edit',
        update_action: {
          success: 'Shopping Destination Edited',
        },
        name: 'Name',
        branding: 'Branding',
        address: 'Address',
        na: 'N/A',
      },
      location_section: {
        title: 'Location',
        type: 'Type',
        address: 'Address',
        coordinates: 'Lat/Long Coordinates',
        plus_code: 'Plus Code',
        action: 'Action',
        generate_coordinates: 'Generate Lat/Long Coordinates',
        set_coordinates: 'Set Lat/Long Coordinates',
      },
    },
    stores: {
      title: 'Stores',
      count: 'Count',
    },
    create_action: {
      success: 'Shopping Destination Created',
      title: 'Add Destination',
      name: 'Name',
      branding: 'Branding',
      address: {
        title: 'Address',
        street: 'Street',
        city: 'City',
        state: 'State',
        country: 'Country',
        zip: 'Zip',
        plus_code: 'Plus Code',
      },
      button_cancel: 'Cancel',
      button_save: 'Submit',
    },
    update_action: {
      success: 'Shopping Destination updated',
      title: 'Update Destination',
      name: 'Name',
      branding: 'Branding',
      address: {
        title: 'Address',
        street: 'Street',
        city: 'City',
        state: 'State',
        country: 'Country',
        zip: 'Zip',
        plus_code: 'Plus Code',
        validation: {
          max_length: 'Max 50 characters.',
        },
      },
      button_cancel: 'Cancel',
      button_save: 'Submit',
    },
    set_coordinates_action: {
      title: 'Set Coordinates',
      success: 'Destination coordinates set',
      lat: 'Latitude',
      lng: 'Longitude',
      button_cancel: 'Cancel',
      button_save: 'Submit',
    },
    generate_coordinates_action: {
      title: 'Generate Coordinates',
      text: 'Are you sure you want to generate coordinates for </br><strong>{destinationName}</strong> ?',
      button_cancel: 'Cancel',
      button_submit: 'Submit',
      success: 'Destination coordinates generated',
    },
  },
  language: {
    french: 'French',
    english: 'English',
  },
  memberships_table: {
    fields: {
      program: 'Program',
      membership_id: 'Membership ID',
      external_id: 'External ID',
      features: 'Features',
      date_joined: 'Date Joined',
      allow_communication: 'Allow Communication',
      status: 'Status',
      edit: 'Edit',
      change_status: 'Change Status',
      unsubscribe: 'Unsubscribe',
      update: 'Edit',
    },
    status: {
      subscribed: 'Active',
      unsubscribed: 'Deactivated',
    },
    remove_membership_action: {
      title: 'Remove Membership',
      text: 'Are you sure you want to delete this membership?',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      success: 'Membership Removed',
    },
  },
  navigation: {
    bank_accounts: 'Bank Accounts',
    login: 'Login',
    users: 'Users',
    purses: 'Purses',
    my_cash: 'My Cash',
    logout: 'Logout',
    wallet_definition: 'Wallet Definitions',
    alert: 'Alerts',
    task: 'Tasks',
    publishers: 'Closed Loops',
    offers: 'Offers',
    businesses: ' Businesses',
    stores: 'Stores',
    setup: 'Setup',
    brandings: 'Brandings',
    inventory: 'Inventories',
    franchises: 'Franchises',
    destinations: 'Shopping Destinations',
    vouchers: 'Vouchers',
    vouchers_operations: 'Voucher Operations',
    dashboards: 'Dashboards',
    transactions: 'Transactions',
    fraud_analysis: 'Fraud Analysis',
    debits_credits: 'Debits and Credits',
    card_activities: 'Card Activities',
    card_applications: 'Card Applications',
    activation_codes: 'Activation Codes',
    tools: 'Tools',
    generate_qr_code: 'Generate QR Code',
    business_suite: 'Business Suite',
    business_suite_corporate_program_businesses: 'Corporate Program - Businesses',
    business_suite_corporate_program_orders: 'Corporate Program - Orders',
    business_suite_promoter_report: 'Promoter Report',
    business_suite_merchant_report: 'Merchant Report',
    business_suite_members: 'Members',
    business_suite_transactions: 'Transactions',
    business_suite_balances: 'Balances',
    business_suite_employer_report: 'Employer Report',
    business_suite_pads: 'PADs',
    instant_card: 'Instant Card',
    instant_card_projects: 'Projects',
    instant_card_batches: 'Batches',
    instant_cards: 'Cards',
    instant_card_cards: 'Cards',
    applications: 'Applications',
    cards: 'Cards',
    clerks: 'Guest Service Users',
    workstations: 'Workstations',
    workstations_registration_request: 'Registration Requests',
    spaces: 'Spaces',
    establishments: 'Establishments',
  },
  noAccess: {
    message: 'Please contact your technical support agent to give you the access to console features.',
  },
  note: {
    delete: 'Delete',
    deleted_by: 'Deleted By',
  },
  offer_promotions: {
    type: {
      bonus: 'Bonus',
    },
    active: {
      true: 'True',
      false: 'False',
    },
    status: {
      coming_soon: 'Coming Soon',
      available: 'Available',
      sold_out: 'Sold Out',
      closed: 'Closed',
    },
    fields: {
      incentive_title: 'Incentive',
      availability_title: 'Availability',
      branding: 'Branding',
      offer: 'Offer',
      type: 'Type',
      id: 'ID',
      status: 'Status',
      active: 'Active',
      created: 'Created',
      reward_percent: 'Reward',
      duration_model: 'Duration Model',
      duration_hint: 'In days',
      bonus_end_date: 'Reward End Date',
      minimum: 'Minimum',
      step: 'Step',
      duration: 'Reward Duration',
      duration_end_date: 'Reward Duration',
      default_payment: 'Default',
      limit: 'Reward Limit per User',
      user_inventory_limit: 'Reward Limit Per User',
      user_payment_limit: 'User Contribution Limit',
      is_refillable: 'Refillable',
      start_date: 'Start Date',
      start_time: 'Start Time',
      end_date: 'End Date',
      end_time: 'End Time',
      display_start_date: 'Display Start Date',
      display_start_time: 'Display Start Time',
      display_end_date: 'Display End Date',
      display_end_time: 'Display End Time',
      inventory: 'Inventory',
      accounting_title: 'Accounting',
      accounting_reference_id: 'Reference id',
      breakage_bonus_owner: 'Reward Breakage Owner',
      funding_title: 'Reward Funding',
      contributor: 'Contributor',
      funding_trigger: 'Payment Method',
    },
    title: 'Offer Promotion',
    inventory_refillable: {
      true: 'True',
      false: 'False',
    },

    update: 'Edit',
    activate: 'Activate',
    deactivate: 'Deactivate',
    delete: 'Delete',
    delete_action: {
      success: 'Offer Promotion Deleted',
      confirm: {
        title: 'Delete Offer Promotion',
        text: 'Are you sure you want to delete this offer promotion </br><strong>{offersPromotionName}</strong> ?',
        button_cancel: 'Cancel',
        button_submit: 'Delete',
      },
    },
    update_action: {
      title: 'Edit Offer Promotion',
      cancel: 'Cancel',
      save: 'Save',
      success: 'Offer Promotion Updated',
    },
    create_action: {
      title: 'Create Offer Promotion',
      submit: 'Submit',
      cancel: 'Cancel',
      success: 'Offer Promotion Created',
    },
    activate_action: {
      success: 'Offer Promotion Activated',
      confirm: {
        title: 'Warning',
        button_cancel: 'Cancel',
        button_submit: 'Submit',
        text: 'Are you sure you want to activate this offer promotion </br><strong>{offersPromotionName}</strong> ?',
      },
    },
    deactivate_action: {
      success: 'Offer Promotion Deactivated',
      confirm: {
        title: 'Warning',
        button_cancel: 'Cancel',
        button_submit: 'Submit',
        text: 'Are you sure you want to deactivate this offer promotion </br><strong>{offersPromotionName}</strong> ?',
      },
    },
    vouchers_section: {
      title: 'Vouchers',
      voucher_count: 'Count',
      no_found: 'None',
    },
    branding_section: {
      title: 'Branding',
    },
  },
  offers: {
    status: {
      undefined: '',
      draft: 'Draft',
      unready: 'Unready',
      coming_soon: 'Coming Soon',
      available: 'Available',
      sold_out: 'Sold-Out',
      closed: 'Closed',
    },
    type: {
      bonus: 'Bonus',
      gift_certificate: 'Gift Certificate',
      gift_certificate_corporate: 'Gift Certificate - Corporate',
      gift_certificate_consumer: 'Gift Certificate - Consumer',
    },
    visibility: {
      public: 'Public',
      private: 'Private',
    },
    customer_type: {
      undefined: '',
      demo: 'Demo',
      hello: 'Hello',
      local_purchase: 'Local Purchase',
      other: 'Other',
      sports_entertainment: 'Sports & Entertainment',
      tourism: 'Tourism',
      towers_malls: 'Towers & Malls',
      workplace: 'Workplace',
    },
    no_offers_found: 'No offers found',
    offer_count: 'Count',
    vouchers: 'Vouchers',
    publishers: 'Closed Loops',
    instant_card_projects: 'Instant Card Projects',
    activation_codes: 'Activation Codes',
    activation_code_batches: 'Activation Code Batches',
    form: {
      owner_business: 'Owner',
      partner: 'Program',
      status: 'Status',
      type: 'Type',
      add_bonus: 'Add Bonus',
      add_gift: 'Add Gift',
      placeholder: 'ID, Reference ID, Name',
      submit: 'Search',
      visibility: 'Visibility',
      data_section: {
        title: 'Offers',
      },
      title: 'Offers',
      total_results_message: 'Displaying the maximum number of results. Further refine your search to see more matches.',
    },
    incentive_refund_methods: {
      undefined: '',
      none: 'None',
      open_wallet: 'Open Wallet',
      no_refund: 'No Refund',
    },
    incentive_duration_models: {
      undefined: '',
      relative: 'Relative',
      fixed: 'Fixed',
    },
    single_publisher: {
      undefined: '-',
      null: '-',
      true: 'Without Choice',
      false: 'With Choices',
    },
    types: {
      bonus: 'Bonus',
      cashback: 'Cashback',
      gift_certificate: 'Gift Certificate',
      none: 'None',
    },
    days: '0 days | 1 day | {count} days',
    fee_purchase_types: {
      none: 'None',
      percent: 'Percent',
      fixed: 'Fixed',
    },
    fee_transaction_types: {
      none: 'None',
      percent: 'Percent',
      fixed: 'Fixed',
    },
    funding_triggers: {
      null: '',
      purchase: 'PAD on Activation',
      transaction: 'PAD on Transaction',
      upfront: 'Upfront Payment',
    },
    funding_payers: {
      none: 'None',
      other: 'Other',
      null: '',
      cardholder: 'Cardholder',
      publisher: 'Closed Loop Owner',
      contributor: 'Contributor',
      store: 'Store Owner',
    },
    funding_payer_triggers: {
      other: 'Upfront Payment',
      cardholder: 'Upfront Payment',
      contributor: 'PAD on Transaction (Contributor)',
      contributor_issuance: 'PAD on Issuance (Contributor)',
      store: 'PAD on Transaction (Store Owner)',
      publisher: 'PAD on Transaction (Closed-Loop Owner)',
    },
    fee_payment_methods: {
      undefined: '',
      none: 'None',
      upfront: 'Upfront Payment',
      monthly_manual_pad: 'Monthly / Manual (Invoicing)',
      monthly_automated_pad: 'Monthly / Automated (PAD)',
    },
    fee_purchase_scope: { payment: 'Contribution', bonus: 'Reward', voucher: 'Voucher' },
    fee_purchase_payer: { none: 'None', cardholder: 'Cardholder', publisher: 'Closed Loop Owner', contributor: 'Contributor' },
    fee_transaction_scope: { voucher: 'Voucher', transaction: 'Transaction' },
    fee_transaction_payer: { none: 'None', publisher: 'Closed Loop Owner', contributor: 'Contributor', store: 'Store Owner' },
    user_fee_strategy: { none: 'None', dormancy: 'Dormancy' },
    voucher_structures: { payment: 'Contribution', bonus: 'Reward' },
    offers_table: {
      id: 'ID',
      type: 'Type',
      name: 'Name',
      program: 'Program',
      business: 'Owner',
      publishers: 'Closed Loops',
      contributor: 'Contributor',
      branding: 'Branding',
      incentive: 'Incentive',
      period: 'Period',
      inventory: {
        title: 'Inventory',
        unlimited: 'N/A',
      },
      created: 'Created',
      reference_id: 'Reference ID',
      visibility: 'Visibility',
      status: 'Status',
      view_link: 'View',
      visibility_type: {
        private: 'Private',
        public: 'Public',
      },
      maximum_page_reached: '{page_count} pages found but we limit to the first {max_pages}.',
    },
    vouchers_table: {
      fields: {
        id: 'ID',
        user: 'User',
        card_application_id: 'Card Application ID',
        cardholder: 'Card Public Token',
        payment: 'Contribution',
        bonus: 'Reward',
        received: 'Received',
        remaining: 'Remaining',
        publisher: 'Closed Loop',
        created: 'Created',
        status: 'Status',
      },
      view_link: 'View',
    },
    publishers_table: {
      edit_link: 'Update Inv. Limit',
      remove_link: 'Remove',
      activate_link: 'Activate',
      deactivate_link: 'Deactivate',
      fields: {
        id: 'ID',
        name: 'Name',
        stores: 'Stores',
        added: 'Added',
        status: {
          pad: 'Pad',
          display: 'Display',
          transactional: 'Transactional',
        },
        wallet_definition: 'Wallet Definition',
        remaining: 'Remaining',
        voucher: 'Vouchers',
        offer_publisher_status: 'Status',
        offer_publisher_category: 'Category',
        inventory_limit: 'Reward Limit',
        edit: 'Edit',
        action: 'Action',
      },
    },
    activation_codes_table: {
      cancel_link: 'Cancel',
      resend_link: 'Resend',
      redeem_link: 'Activate',
      fields: {
        id: 'ID',
        email: 'Recipient Email',
        mobile_number: 'Recipient Mobile Number',
        first_name: 'Recipient First Name',
        last_name: 'Recipient Last Name',
        voucher_id: 'Voucher',
        account_id: 'User',
        publisher_id: 'Closed Loop',
        received: 'Received',
        remaining: 'Remaining',
        created: 'Created',
        redeemed: 'Activated',
        status: 'Status',
        action: 'Action',
        batch_id: 'Batch',
        edit: 'Edit',
      },
    },
    activation_code_batches_table: {
      fields: {
        id: 'ID',
        reference_id: 'Reference ID',
        name: 'Name',
        quantity: 'Quantity',
        business: 'Business',
        offer: 'Offer',
        payment_amount: 'Contribution',
        bonus_amount: 'Reward',
        creation_date: 'Creation Date',
        action: 'Action',
        edit: 'Edit',
      },
    },
    branding_table: {
      fields: {
        id: 'ID',
        name: 'Name',
        type: 'Type',
        images: {
          showcase: 'Showcase',
          horizontal_logo: 'Horizontal Logo',
          big_hero: 'Big Hero',
          small_hero: 'Small Hero',
          background: 'Background',
        },
        created: 'Created',
      },
    },
    access_table: {
      remove_link: 'Remove',
      fields: {
        email: 'Email Address',
      },
    },
    offers_tracking_link_types: { corporate: 'Corporate Program', consumer: 'Consumer Program' },
    offers_tracking_links_table: {
      action: 'Actions',
      id: 'ID',
      name: 'Name',
      business: 'Referrer Business',
      redirect_type: 'Type',
      qr_code: 'QR Code/URL',
      qr_code_view: 'View',
      status: 'Status',
      creation_date: 'Creation Date',
    },
    offer_promotions_table: {
      no_found: 'None',
      fields: {
        action: 'Actions',
        id: 'ID',
        reward_percent: 'Incentive',
        branding: 'Branding',
        from_date: 'Start',
        to_date: 'End',
        status: 'Status',
        type: 'Type',
        active: 'Active',
      },
      activate_link: 'Activate',
      deactivate_link: 'Deactivate',
    },
    offers_tracking_links: {
      status: {
        active: 'Active',
        disabled: 'Disabled',
        undefined: 'Undefined',
      },
      redirect_type: {
        corporate: 'Corporate Program',
        consumer: 'Consumer Program',
      },
      actions: {
        enable: 'Activate',
        disable: 'Deactivate',
      },
      add_modal: {
        title: 'Add Tracking Link',
        submit: 'Submit',
        cancel: 'Cancel',
        fields: {
          name: 'Name',
          business_id: 'Referrer Business',
          type: 'Redirect To',
          uri: 'URL',
        },
        success: 'Tracking Link Added',
      },
      activate_modal: {
        title: 'Activate Tracking Link',
        text: 'Are you sure you want to activate "{trackingLinkName}" tracking link?',
        submit: 'Submit',
        cancel: 'Cancel',
        success: 'Tracking Link Activated',
      },
      disable_modal: {
        title: 'Deactivate Tracking Link',
        text: 'Are you sure you want to deactivate "{trackingLinkName}" tracking link?',
        submit: 'Submit',
        cancel: 'Cancel',
        success: 'Tracking Link Deactivated',
      },
      qr_code_modal: {
        title: 'View QR Code / URL',
        name: 'Name',
        referrer_business: 'Referrer Business',
        id: 'ID',
        redirect_to: 'Redirect To',
        short_url: 'Short URL',
        qr_code: 'QR Code',
        download_svg: 'Download SVG',
        cancel: 'Close',
      },
    },
    details: {
      instant_card_projects_section: {
        projects_count: 'Count',
      },
      branding_section: {
        title: 'Branding',
      },
      access_section: {
        title: 'Report Access',
        add_access: 'Add',
        access_count: 'Count',
        action: 'Action',
        create_action: {
          title: 'Add Report Access',
          offer: 'Offer',
          email: 'Email Address',
          success: 'Access Created',
          cancel_text: 'Cancel',
          submit_text: 'Submit',
        },
        remove_action: {
          title: 'Remove Email Access',
          confirm: {
            title: 'Warning',
            button_cancel: 'Cancel',
            button_submit: 'Submit',
            text: 'Are you sure you want to remove report access for </br><strong>{offersAccessEmail}</strong> ?',
          },
          success: 'Email Access removed',
          button_save: 'Submit',
          button_cancel: 'Cancel',
        },
      },
      offers_urls_section: {
        title: 'URLs',
        activation_urls: {
          bonus: 'Purchase',
          gift_certificate: 'Redeem',
          raw: 'Raw',
          preview: 'Preview',
          friendly: 'Friendly',
          short: 'Short',
        },
        corpo_urls: {
          title: 'Corporate Gift Program',
          raw: 'Raw',
          friendly: 'Friendly',
          short: 'Short',
        },
      },
      offers_information_section: {
        id: 'ID',
        name: 'Name',
        owner_business: 'Owner',
        is_single_publisher: 'Structure',
        system_status: {
          pad_funding: 'Funding',
          pad_fee: 'Fee',
          transactional: 'Transactional',
          purchasable: 'Purchasable',
          status: 'Status',
        },

        accounting: {
          title: 'Accounting',
          reference_id: 'Reference Id',
          customer_type: {
            label: 'Customer Type',
          },
        },

        type: 'Type',
        external_id: 'External ID',
        wallet_definition: 'Wallet Definition',
        title: 'Offer',
        action: 'Action',
        update: 'Edit',
        program: 'Program',
        publisher: 'Closed Loop',
        contributor: 'Contributor',
        breakage_bonus_owner: 'Reward Breakage Owner',
        breakage_payment_owner: 'Contribution Breakage Owner',
        branding: 'Branding',
        timezone: 'Timezone',
        availability_title: 'Availability',
        features_title: 'Features',
        send_reminder_emails: 'Send Reminder Emails',
        slug_title: 'Slug',
        pdf_destination_notice: {
          en: 'PDF Destination Notice - English',
          fr: 'PDF Destination Notice - French',
        },
        cash_flow_optimization: 'Cash Flow Optimization',
        crowdfunding_title: 'Crowdfunding',
        cash_flow_optimization_title: 'Cash Flow Optimization',
        restricted_to_members: 'Privilege',
        private_title: 'Private',
        period: 'Period',
        start_date: 'Start Date',
        hide_store_before_start_date: 'Hide Stores Before Start Date',
        hide_activation_button: 'Hide Activation Button',
        hide_purchase_button: 'Hide Purchase Button',
        end_date: 'End Date',
        inventory_title: 'Inventory',
        is_refillable: 'Refillable',
        default_publisher_limit: 'Default Reward Limit per Closed Loop',
        default_publisher_payment_limit: 'Default Closed Loop Contribution Limit',
        inventory_refillable: {
          true: 'True',
          false: 'False',
        },
        statuses_title: 'Statuses',
        incentive_title: 'Incentive',
        incentive_bonus: 'Reward',
        incentive_payment_amount: 'Contribution',
        incentive_default_bonus: 'Default Reward',
        incentive_default_payment_amount: 'Default Contribution',
        incentive_refund_method: 'Refund Method',
        duration: 'Reward Duration',
        bonus_end_date: 'Reward End Date',
        closure: 'Closure',
        closure_end_date: 'Closure End Date',
        refund_method: 'Refund Method',
        duration_model: 'Duration Model',
        minimum: 'Minimum',
        limit: 'Reward Limit per User',
        user_payment_limit: 'User Contribution Limit',
        step: 'Step',
        default: 'Default',
        funding_title: 'Funding',
        payment_title: 'Contribution',
        funding_payer: 'Payer',
        funding_trigger: 'Payment Method',
        bonus_title: 'Reward',
        funding_bonus_publisher: 'Closed Loop Owner',
        funding_bonus_contributor: 'Contributor',
        funding_bonus_store: 'Store Owner',
        fees_title: 'Business Fees',
        fee_payment_method_label: 'Payment Method',
        purchase_fee_title: 'Purchase Fees',
        activation_fee_title: 'Activation Fees',
        purchase_fee_percent_fee_title: 'Percent Fee',
        purchase_percent_fee_payer: 'Payer',
        purchase_percent_fee_scope: 'Scope',
        purchase_percent_fee_ratio: 'Percent Fee',
        purchase_fee_fixed_fee_title: 'Fixed Fee',
        purchase_fixed_fee_payer: 'Payer',
        purchase_fixed_fee_amount: 'Fixed Fee',
        transaction_fee_title: 'Transaction Fees',
        transaction_fee_percent_fee_title: 'Percent Fee',
        transaction_percent_fee_payer: 'Payer',
        transaction_percent_fee_scope: 'Scope',
        transaction_percent_fee_ratio: 'Percent Fee',
        transaction_fee_fixed_fee_title: 'Fixed Fee',
        transaction_fixed_fee_payer: 'Payer',
        transaction_fixed_fee_amount: 'Fixed Fee',
        activation_url: 'Activation URL',
        consumer_gift_program: {
          label: 'Consumer',
          title: 'Consumer Gift Program',
          status: 'Status',
          purchase_limits: 'Purchase Limits',
          purchase_default_amount: 'Purchase Default Amount',
          purchase_inventory: 'Purchase Inventory',
          enabled: 'Enabled',
          disabled: 'Disabled',
          update: {
            title: 'Edit Consumer Gift Program',
            cancel: 'Cancel',
            save: 'Save',
            purchase_limit_min: 'Purchase Limit Min',
            purchase_limit_max: 'Purchase Limit Max',
            purchase_default_amount: 'Purchase Default Amount',
            purchase_inventory: 'Purchase Inventory',
          },
        },
        corporate_gift_program: {
          label: 'Corporate',
          title: 'Corporate Gift Program',
          status: 'Status',
          minimum_purchase_amount: 'Minimum Purchase Amount',
          purchase_fee: 'Purchase Fees',
          minimum_fee: 'Purchase Minimum Fee',
          fee_inventory: 'Purchase Fee Inventory',
          voucher_structure: 'Voucher Structure',
          enabled: 'Enabled',
          disabled: 'Disabled',
          update: {
            title: 'Edit Corporate Gift Program',
            cancel: 'Cancel',
            save: 'Save',
            percent_fee: 'Percent Fee',
            minimum_fee: 'Purchase Minimum Fee',
            fixed_fee: 'Fixed Fee',
            fee_inventory: 'Purchase Fee Inventory',
            minimum_purchase_amount: 'Minimum Purchase Amount',
            voucher_structure: 'Voucher Structure',
          },
        },
        created: 'Created',
        update_action: {
          success: 'Offer Edited',
        },
        user_fee: {
          title: 'User Fees',
          fees_policy: 'Fees Policy',
          strategy: {
            none: 'None',
            dormancy: 'Dormancy',
            undefined: 'Undefined',
          },
          dormancy_fee: 'Dormancy Fee',
          dormancy_trigger: 'Dormancy Trigger (in Months)',
          extension_in_months: 'Extension Period (in Months)',
        },
        na: 'N/A',
      },
      vouchers_section: {
        voucher_count: 'Count',
        add_voucher: 'Add',
        action: 'Action',
        create_action: {
          success: 'Voucher Created',
        },
        extend_expiration_action: {
          title: 'Extend Vouchers Reward Expiration Date',
          success_message: 'The Vouchers Extend Reward Expiration Date process to {newExpiration} is now completed.',
          offer: 'Offer',
          report: {
            params: {
              relativeParamsInfo: '{numberOfDays} days',
            },
            header: {
              action_column_name: 'Outcome',
              number_of_voucher_column_name: 'Number of Vouchers',
            },
            voucher_processed: {
              title: 'Total Evaluated',
            },
            voucher_extended: {
              title: 'Extended',
            },
            voucher_refunded: {
              title: 'Refunded',
            },
            voucher_skipped: {
              title: 'Skipped',
            },
          },
          cancel_text: 'Cancel',
          close_text: 'Close',
          submit_text: 'Submit',
        },
      },
      activation_codes_section: {
        languages: {
          fr: 'French',
          en: 'English',
        },
        placeholder: 'ID, Batch, Recipient Email, Recipient Mobile Number, Recipient First Name, Recipient Last Name, User ID',
        form: {
          submit: 'Search',
          total_results_message: 'Displaying the maximum number of results. Further refine your search to see more matches.',
        },
        activation_codes_count: 'Count',
        add_activation_code: 'Generate',
        batch_activation_code: 'Generate Batch',
        actions: 'Actions',
        create_action: {
          title: 'Generate Activation Code',
          success: 'Activation Code Generated',
          offer: 'Offer',
          recipient_language: 'Language',
          business: 'Referenced By',
          source_quantity: 'Source Quantity',
          cancel_text: 'Cancel',
          submit_text: 'Submit',
          email: 'Email',
          mobile_number: 'Mobile Number',
          first_name: 'First Name',
          last_name: 'Last Name',
          amount: 'Total Amount',
          bonus_amount: 'Reward Amount',
          payment_amount: 'Contribution Amount',
        },
        batch_action: {
          title: 'Batch Create Activation Codes',
          offer: 'Offer',
          batch_quantity: 'Batch Quantity',
          batch_name: 'Batch Name',
          batch_id: 'Batch Id',
          business_id: 'Business',
          cancel_text: 'Cancel',
          close_text: 'Close',
          submit_text: 'Submit',
          batch_amount: 'Total Batch Amount',
          personalized_message: 'Personalized Message',
          success_message: 'The activation code batch generation is now completed. Please keep the batch id for your reference.',
          amount: 'Total Amount',
          bonus_amount: 'Reward Amount',
          pre_activation_required: 'Requires Pre-Activation',
          bonus_amount_funded: 'Skip Reward Funding',
          payment_amount_funded: 'Skip Contribution Funding',
          payment_amount: 'Contribution Amount',
          recipients_csv_file: 'Recipients CSV File',
          recipients_csv_file_example_info:
            'To import the data successfully, it is necessary to provide a CSV file with column names that match the header, including "first_name", "last_name", and "email".',
          recipients_csv_file_example_info_download: 'Download example file',
          recipients_csv_file_error: 'Invalid Recipients CSV file',
          reference_id: 'Reference Id',
          import: 'Import',
          trigger_expiration_at_purchase: {
            label: 'Duration Trigger',
            options: {
              purchase: 'Purchase',
              activation: 'Activation',
            },
          },
        },
        cancel_action: {
          title: 'Cancel Activation Code',
          reason: 'Reason',
          button_cancel: 'Cancel',
          button_save: 'Submit',
          success: 'Activation Code Canceled',
        },
        resend_action: {
          title: 'Resend Activation Code',
          confirm: {
            title: 'Warning',
            button_cancel: 'Cancel',
            button_submit: 'Submit',
            text: 'Are you sure you want to resend email for activation code </br><strong>{activationCodeName}</strong> ?',
          },
          success: 'Activation Code Email Sent',
        },
        mark_as_non_pre_activated_action: {
          confirm: {
            title: 'Mask as Non Pre-Activated',
            button_cancel: 'Cancel',
            button_submit: 'Submit',
            text: 'Are you sure you want to mark as Non Pre-Activated the activation code "{activationCodeName}" ?',
          },
        },
        pre_activate_action: {
          confirm: {
            title: 'Pre-Activate',
            button_cancel: 'Cancel',
            button_submit: 'Submit',
            text: 'Are you sure you want to Pre-Activate the activation code "{activationCodeName}" ?',
          },
        },
        update_action: {
          success: 'Activation Code Batch Updated',
        },
      },
      activation_code_batches_section: {
        languages: {
          fr: 'French',
          en: 'English',
        },
        placeholder: 'ID, Batch, Recipient Email, Recipient Mobile Number, Recipient First Name, Recipient Last Name, User ID',
        form: {
          submit: 'Search',
          total_results_message: 'Displaying the maximum number of results. Further refine your search to see more matches.',
        },
        count: 'Count',
        add_activation_code: 'Generate',
        batch_activation_code: 'Generate Batch',
        batch_activation_code_with_csv_recipients_file: 'Generate Batch With Recipients CSV File',
        actions: 'Actions',
        create_action: {
          title: 'Generate Activation Code',
          success: 'Activation Code Generated',
          offer: 'Offer',
          recipient_language: 'Language',
          business: 'Referenced By',
          source_quantity: 'Source Quantity',
          cancel_text: 'Cancel',
          submit_text: 'Submit',
          email: 'Email',
          mobile_number: 'Mobile Number',
          first_name: 'First Name',
          last_name: 'Last Name',
          amount: 'Total Amount',
          bonus_amount: 'Reward Amount',
          payment_amount: 'Contribution Amount',
        },
        download_preview_pdf_action: {
          title: 'Preview PDF',
          language_modal: {
            title: 'Preview PDF',
            language_selected_field: 'Language',
            options: {
              en: 'English',
              fr: 'French',
            },
          },
          button_cancel: 'Cancel',
          button_save: 'Download',
        },
        batch_action: {
          title: 'Batch Create Activation Codes',
          offer: 'Offer',
          batch_quantity: 'Batch Quantity',
          batch_name: 'Batch Name',
          batch_id: 'Batch Id',
          cancel_text: 'Cancel',
          close_text: 'Close',
          submit_text: 'Submit',
          success_message: 'The activation code batch generation is now completed. Please keep the batch id for your reference.',
          amount: 'Total Amount',
          bonus_amount: 'Reward Amount',
          payment_amount: 'Contribution Amount',
        },
        cancel_action: {
          title: 'Cancel Activation Code',
          reason: 'Reason',
          button_cancel: 'Cancel',
          button_save: 'Submit',
          success: 'Activation Code Canceled',
        },
        resend_action: {
          title: 'Resend Activation Code',
          confirm: {
            title: 'Warning',
            button_cancel: 'Cancel',
            button_submit: 'Submit',
            text: 'Are you sure you want to resend email for activation code </br><strong>{activationCodeName}</strong> ?',
          },
          success: 'Activation Code Email Sent',
        },
        update_action: {
          success: 'Activation Code Batch Updated',
        },
      },
      publishers_section: {
        publisher_count: 'Count',
        add_publisher: 'Add',
        update_inventory_limit_for_all: 'Update Inv. Limit for All',
        placeholder: 'Name, ID',
        category: 'Category',
        status: 'Status',
        readiness: 'Readiness',
        action: 'Action',
        delete_action: {
          title: 'Remove Closed Loop',
          confirm: {
            title: 'Warning',
            button_cancel: 'Cancel',
            button_submit: 'Submit',
            text: 'Are you sure you want to remove closed loop </br><strong>{offersPublisherName}</strong> ?',
          },
          success: 'Closed Loop Removed',
        },
        deactivate_action: {
          title: 'Deactivate Closed Loop',
          confirm: {
            title: 'Warning',
            button_cancel: 'Cancel',
            button_submit: 'Submit',
            text: 'Are you sure you want to deactivate Closed Loop </br><strong>{offersPublisherName}</strong> ?',
          },
          success: 'Closed Loop Deactivated',
        },
        activate_action: {
          title: 'Activate Closed Loop',
          confirm: {
            title: 'Warning',
            button_cancel: 'Cancel',
            button_submit: 'Submit',
            text: 'Are you sure you want to activate Closed Loop </br><strong>{offersPublisherName}</strong> ?',
          },
          success: 'Closed Loop Activated',
        },
        create_action: {
          title: 'Add Closed Loop',
          success: 'Closed Loop Added',
          offer: 'Offer',
          publisher: 'Closed Loop',
          cancel_text: 'Cancel',
          submit_text: 'Submit',
          inventory_limit: 'Reward Limit',
        },
        update_action: {
          title: 'Update Closed Loop',
          success: 'Closed Loop Updated',
          offer: 'Offer',
          publisher: 'Closed Loop',
          button_cancel: 'Cancel',
          button_save: 'Save',
          inventory_limit: 'Rewards Limits',
        },
        update_inventory_limit_for_all_action: {
          title: 'Update Reward Limit For All Offer Closed Loops',
          success: 'Reward Limits Updated',
          offer: 'Offer',
          publisher: 'Closed Loop',
          button_cancel: 'Cancel',
          button_save: 'Submit',
          inventory_limit: 'Reward Limit',
        },
        form: {
          submit: 'Search',
          total_results_message: 'Displaying the maximum number of results. Further refine your search to see more matches.',
        },
      },
      notes_section: {
        action: 'Action',
        add: 'Add note',
        author: 'Author',
        count: 'Count',
        create_note: 'Add note',
        created: 'Created',
        delete: 'Delete',
        deleted_by: 'Deleted By',
        no_found: 'None',
        title: 'Notes',
      },
      offers_tracking_links_section: {
        action: 'Action',
        add: 'Add',
        count: 'Count',
        title: 'Tracking Links',
      },
      offer_promotions_section: {
        action: 'Action',
        add: 'Add',
        count: 'Count',
        title: 'Promotions',
      },
    },
    create_action: {
      success: 'Bonus Offer Created',
      title: 'Add Bonus Offer',
      name: 'Name',
      program: 'Program',
      publisher: 'Closed Loop',
      breakage_bonus_owner: 'Reward Breakage Owner',
      breakage_payment_owner: 'Contribution Breakage Owner',
      contributor: 'Contributor',
      branding: 'Branding',
      is_single_publisher: 'Structure',
      timezone: 'Timezone',
      incentive: {
        title: 'Incentive',
        bonus: 'Reward',
        duration: 'Reward Duration',
        duration_hint: 'In days',
        bonus_end_date: 'Reward End Date',
        bonus_end_time: 'Reward End Time',
        min: 'Min',
        user_inventory_limit: 'Reward Limit per User',
        user_payment_limit: 'User Contribution Limit',
        limit_hint: 'By user',
        step: 'Step',
        default_payment: 'Default',
        closure: 'Closure',
        closure_end_date: 'Closure End Date',
        closure_end_time: 'Closure End Time',
        refund_method: 'Refund Method',
        duration_model: 'Duration Model',
        payment_amount: 'Contribution',
      },
      availability: {
        title: 'Availability',
        publisher_default_inventory_limit: 'Default Reward Limit per Closed Loop',
        publisher_payment_limit: 'Default Closed-Loop Contribution Limit',
        inventory: 'Inventory',
        refillable: 'Refillable',
        start_date: 'Start Date',
        start_time: 'Start Time',
        start_reference_date: 'Start Reference Date',
        end_date: 'End Date',
        end_time: 'End Time',
      },
      features: {
        title: 'Features',
        slug: 'Slug',
        slug_hint: 'Only letters, numbers and dashes are accepted',
        cash_flow_optimization: 'Cash Flow Optimization',
        crowdfunding: 'Crowdfunding',
        private: 'Private',
        restricted_to_members: 'Privilege',
        send_reminder_emails: 'Send Reminder Emails',
        hide_store_before_start_date: 'Hide Stores Before Start Date',
        hide_activation_button: 'Hide Activation Button',
        hide_purchase_button: 'Hide Purchase Button',
        pdf_destination_notice: {
          title: 'PDF Destination Notice',
          en: 'PDF Destination Notice - English',
          fr: 'PDF Destination Notice - French',
        },
      },
      accounting_section: {
        title: 'Accounting',
        reference_id: 'Reference Id',
        customer_type: 'Customer Type',
      },
      funding: {
        bonus_title: 'Reward Funding',
        payment_title: 'Contribution Funding',
        title: 'Funding',
        bonus: 'Reward',
        payment: 'Contribution',
        trigger: 'Payment Method',
        publisher: 'Closed Loop Owner',
        contributor: 'Contributor',
        store: 'Store Owner',
      },
      owner_business_id: 'Owner',
      purchase_fee: {
        title: 'Purchase Fees',
        percent_fee: {
          ratio: 'Percent Fee',
          scope: 'Scope',
          payer: 'Payer',
        },
        fixed_fee: {
          amount: 'Fixed Fee',
          payer: 'Payer',
        },
      },
      transaction_fee: {
        title: 'Transaction Fees',
        percent_fee: {
          ratio: 'Percent Fee',
          scope: 'Scope',
          payer: 'Payer',
        },
        fixed_fee: {
          amount: 'Fixed Fee',
          payer: 'Payer',
        },
      },
      button_cancel: 'Cancel',
      button_save: 'Submit',
    },
    update_action: {
      owner_business_id: 'Owner',
      success: 'Bonus Offer Updated',
      title: 'Update Bonus Offer',
      name: 'Name',
      is_single_publisher: 'Structure',
      timezone: 'Timezone',
      program: 'Program',
      publisher: 'Closed Loop',
      contributor: 'Contributor',
      breakage_bonus_owner: 'Reward Breakage Owner',
      breakage_payment_owner: 'Contribution Breakage Owner',
      branding: 'Branding',
      incentive: {
        title: 'Incentive',
        bonus: 'Reward',
        duration: 'Reward Duration',
        bonus_end_date: 'Reward End Date',
        bonus_end_time: 'Reward End Time',
        duration_hint: 'In days',
        min: 'Min',
        user_inventory_limit: 'Reward Limit per User',
        user_payment_limit: 'User Contribution Limit',
        limit_hint: 'By user',
        step: 'Step',
        default_payment: 'Default',
        closure_end_date: 'Closure End Date',
        closure_end_time: 'Closure End Time',
        closure: 'Closure',
        refund_method: 'Refund Method',
        duration_model: 'Duration Model',
      },
      availability: {
        title: 'Availability',
        publisher_default_inventory_limit: 'Default Reward Limit per Closed Loop',
        publisher_payment_limit: 'Default Closed-Loop Contribution Limit',
        inventory: 'Inventory',
        refillable: 'Refillable',
        start_date: 'Start Date',
        start_time: 'Start Time',
        start_reference_date: 'Start Reference Date',
        end_date: 'End Date',
        end_time: 'End Time',
      },
      features: {
        title: 'Features',
        slug: 'Slug',
        slug_hint: 'Only letters, numbers and dashes are accepted',
        cash_flow_optimization: 'Cash Flow Optimization',
        crowdfunding: 'Crowdfunding',
        restricted_to_members: 'Privilege',
        send_reminder_emails: 'Send Reminder Emails',
        hide_store_before_start_date: 'Hide Stores Before Start Date',
        hide_activation_button: 'Hide Activation Button',
        hide_purchase_button: 'Hide Purchase Button',
        private: 'Private',
        pdf_destination_notice: {
          title: 'PDF Destination Notice',
          en: 'PDF Destination Notice - English',
          fr: 'PDF Destination Notice - French',
        },
      },
      funding: {
        title: 'Funding',
        payment: 'Contribution',
        payer: 'Payer',
        trigger: 'Payment Method',
        bonus: 'Reward',
        publisher: 'Closed Loop Owner',
        contributor: 'Contributor',
        store: 'Store Owner',
      },
      fees: {
        title: 'Business Fees',
        payment_method: {
          title: 'Fees Payment Method',
          none: 'None',
          upfront: 'Upfront Payment',
          monthly_manual_pad: 'Monthly / Manual (Invoicing)',
          monthly_automated_pad: 'Monthly / Automated (PAD)',
        },
      },
      purchase_fee: {
        title: 'Purchase Fees',
        percent_fee: {
          ratio: 'Percent Fee',
          scope: 'Scope',
          payer: 'Payer',
        },
        fixed_fee: {
          amount: 'Fixed Fee',
          payer: 'Payer',
        },
      },
      transaction_fee: {
        title: 'Transaction Fees',
        percent_fee: {
          ratio: 'Percent Fee',
          scope: 'Scope',
          payer: 'Payer',
        },
        fixed_fee: {
          amount: 'Fixed Fee',
          payer: 'Payer',
        },
      },
      user_fee: {
        title: 'User Fees',
        fees_policy: 'Fees Policy',
        dormancy_fee: 'Dormancy Fee',
        dormancy_trigger: 'Dormancy Trigger (in Months)',
        extension_in_months: 'Extension Period (in Months)',
      },
      button_cancel: 'Cancel',
      button_save: 'Submit',
    },
    create_gift_action: {
      success: 'Gift Certificate Offer Created',
      title: 'Add Gift Certificate Offer',
      name: 'Name',
      program: 'Program',
      contributor: 'Contributor',
      breakage_bonus_owner: 'Reward Breakage Owner',
      breakage_payment_owner: 'Contribution Breakage Owner',
      branding: 'Branding',
      is_single_publisher: 'Structure',
      timezone: 'Timezone',
      incentive: {
        title: 'Incentive',
        bonus: 'Default Reward',
        duration: 'Reward Duration',
        duration_model: 'Duration Model',
        bonus_end_date: 'Reward End Date',
        bonus_end_time: 'Reward End Time',
        closure_end_date: 'Closure End Date',
        closure_end_time: 'Closure End Time',
        duration_hint: 'In days',
        min: 'Min',
        user_inventory_limit: 'Reward Limit per User',
        user_payment_limit: 'User Contribution Limit',
        limit_hint: 'By user',
        payment_amount: 'Default Contribution',
      },
      availability: {
        title: 'Availability',
        publisher_default_inventory_limit: 'Default Reward Limit per Closed Loop',
        publisher_payment_limit: 'Default Closed-Loop Contribution Limit',
        start_date: 'Start Date',
        start_time: 'Start Time',
        start_reference_date: 'Start Reference Date',
        end_date: 'End Date',
        end_time: 'End Time',
      },
      funding: {
        title: 'Funding',
        publisher: 'Closed Loop Owner',
        contributor: 'Contributor',
        store: 'Store Owner',
        bonus: 'Reward',
        payment: 'Contribution',
      },
      owner_business_id: 'Owner',
      purchase_fee: {
        title: 'Activation Fees',
        percent_fee: {
          ratio: 'Percent Fee',
          scope: 'Scope',
          payer: 'Payer',
        },
        fixed_fee: {
          amount: 'Fixed Fee',
          payer: 'Payer',
        },
      },
      transaction_fee: {
        title: 'Transaction Fees',
        percent_fee: {
          ratio: 'Percent Fee',
          scope: 'Scope',
          payer: 'Payer',
        },
        fixed_fee: {
          amount: 'Fixed Fee',
          payer: 'Payer',
        },
      },
      user_fee: {
        title: 'User Fees',
        fees_policy: 'Fees Policy',
        dormancy_fee: 'Dormancy Fee',
        dormancy_trigger: 'Dormancy Trigger (in Months)',
        dormancy_extension_in_months: 'Extension Period (in Months)',
      },
      button_cancel: 'Cancel',
      button_save: 'Submit',
    },
    update_gift_action: {
      owner_business_id: 'Owner',
      timezone: 'Timezone',
      success: 'Gift Certificate Offer Updated',
      title: 'Update Gift Certificate Offer',
      name: 'Name',
      program: 'Program',
      is_single_publisher: 'Structure',
      contributor: 'Contributor',
      breakage_bonus_owner: 'Reward Breakage Owner',
      breakage_payment_owner: 'Contribution Breakage Owner',
      branding: 'Branding',
      incentive: {
        title: 'Incentive',
        bonus: 'Default Reward',
        payment_amount: 'Default Contribution',
        duration_model: 'Duration Model',
        bonus_end_date: 'Reward End Date',
        bonus_end_time: 'Reward End Time',
        duration: 'Reward Duration',
        duration_hint: 'In days',
        user_inventory_limit: 'Reward Limit per User',
        user_payment_limit: 'User Contribution Limit',
        limit_hint: 'By user',
      },
      availability: {
        title: 'Availability',
        publisher_default_inventory_limit: 'Default Reward Limit per Closed Loop',
        start_date: 'Start Date',
        start_time: 'Start Time',
        start_reference_date: 'Start Reference Date',
        end_date: 'End Date',
        end_time: 'End Time',
      },
      accounting_section: {
        title: 'Accounting',
        reference_id: 'Reference Id',
        customer_type: 'Customer Type',
      },
      funding: {
        title: 'Funding',
        payment: 'Contribution',
        bonus: 'Reward',
        publisher: 'Closed Loop Owner',
        contributor: 'Contributor',
        store: 'Store Owner',
      },
      purchase_fee: {
        title: 'Activation Fees',
        percent_fee: {
          ratio: 'Percent Fee',
          scope: 'Scope',
          payer: 'Payer',
        },
        fixed_fee: {
          amount: 'Fixed Fee',
          payer: 'Payer',
        },
      },
      transaction_fee: {
        title: 'Transaction Fees',
        percent_fee: {
          ratio: 'Percent Fee',
          scope: 'Scope',
          payer: 'Payer',
        },
        fixed_fee: {
          amount: 'Fixed Fee',
          payer: 'Payer',
        },
      },
      user_fee: {
        title: 'User Fees',
        fees_policy: 'Fees Policy',
        dormancy_fee: 'Dormancy Fee',
        dormancy_trigger: 'Dormancy Trigger (in Months)',
        extension_in_months: 'Extension Period (in Months)',
      },
      button_cancel: 'Cancel',
      button_save: 'Submit',
    },
    redeem_action: {
      success: 'Activation Code Activated',
      title: 'Activate Activation Code',
      user: 'User',
      card: 'Card',
      publisher: 'Closed Loop',
      cancel_text: 'Cancel',
      submit_text: 'Submit',
    },
    webhook_listeners: {
      title: 'Webhook Listeners',
      count: 'Count',
      action: 'Action',
      add: 'Add',
      fields: {
        business: 'Business',
        endpoint: 'Endpoint',
        endpoint_status: 'Endpoint Status',
        action: 'Actions',
      },
      status: {
        active: 'Active',
        disabled: 'Disabled',
      },
      actions: {
        remove: 'Remove',
      },
      add_modal: {
        title: 'Add Offer Webhook Listener',
        submit: 'Submit',
        cancel: 'Cancel',
        fields: {
          business: 'Business',
          endpoint: 'Webhook Endpoint',
        },
        success: 'Webhook Listener Added',
      },
      remove_modal: {
        title: 'Remove Offer Webhook Listener',
        text: 'Are you sure you want to remove "{webhookName}" webhook listener?',
        submit: 'Submit',
        cancel: 'Cancel',
        success: 'Webhook Listener Removed',
      },
    },
    create_note_action: {
      title: 'Add Note',
      note: 'Note',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      success: 'Note saved',
    },
    delete_note_action: {
      title: 'Delete Note',
      reason: 'Reason',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      success: 'Note deleted',
    },
  },
  offers_publisher: {
    status: {
      undefined: '',
      available: 'Available',
      sold_out: 'Sold-Out',
      deactivated: 'Deactivated',
    },
    readiness: {
      pad: 'PAD Unready',
      display: 'Display Unready',
      transactional: 'Transactional Unready',
    },
  },
  pageNotFound: {
    message: 'The page you are trying to get does not exist!',
  },
  page_title: {
    application: 'Admin | Hello - Applications',
    fraud_analysis: 'Admin | Hello - Fraud Analysis',
    bank_accounts: 'Admin | Hello - Bank Accounts',
    bank_account_details: 'Admin | Hello - Bank Accounts',
    card: 'Admin | Hello - Cards',
    wallet_definition: 'Admin | Hello - Wallet Definitions',
    alert: 'Admin | Hello - Alerts',
    task: 'Admin | Hello - Tasks',
    offers: 'Admin | Hello - Offers',
    offer_promotion_details: 'Admin | Hello - Offer Promotion',
    offers_details: 'Admin | Hello - Offer',
    login: 'Admin | Hello - Login',
    noAccess: 'Admin | Hello - No Access',
    publishers: 'Admin | Hello - Closed Loops',
    publishers_details: 'Admin | Hello - Closed Loop',
    branding: 'Admin | Hello - Branding',
    branding_details: 'Admin | Hello - Branding',
    business: 'Admin | Hello - Business',
    business_details: 'Admin | Hello - Business',
    store: 'Admin | Hello - Store',
    store_details: 'Admin | Hello - Store',
    inventory: 'Admin | Hello - Inventory',
    inventory_details: 'Admin | Hello - Inventory',
    franchise: 'Admin | Hello - Franchise',
    franchise_details: 'Admin | Hello - Franchise',
    destination: 'Admin | Hello - Destination',
    destination_details: 'Admin | Hello - Destination',
    voucher: 'Admin | Hello - Voucher',
    voucher_details: 'Admin | Hello - Voucher',
    purse_details: 'Admin | Hello - Purse',
    user: 'Admin | Hello - User',
    user_details: 'Admin | Hello - User',
    activation_code_detail: 'Admin | Hello - Activation Code',
    transactions: 'Admin | Hello - Transactions',
    debits_credits: 'Admin | Hello - Debits and Credits',
    card_activities: 'Admin | Hello - Card Activities',
    card_applications: 'Admin | Hello - Card Applications',
    activation_codes: 'Admin | Hello - Activation Codes',
    activation_code_batch_detail: 'Admin | Hello - Activation Code Batches',
    vouchers: 'Admin | Hello - Vouchers',
    vouchers_operations: 'Admin | Hello - Vouchers Operations',
    space: 'Admin | Hello - Space',
    space_details: 'Admin | Hello - Spaces',
    clerk_details: 'Admin | Hello - Guest Service User',
    business_suite_employer_report: 'Admin | Hello - Employer Report',
    business_suite_promoter_report: 'Admin | Hello - Promoter Report',
    business_suite_merchant_report: 'Admin | Hello - Merchant Report',
    business_suite_members: 'Admin | Hello - Members',
    business_suite_transactions: 'Admin | Hello - Transactions',
    business_suite_balances: 'Admin | Hello - Balances',
    business_suite_pads: 'Admin | Hello - PADs',
    instant_card_projects: 'Admin | Hello - Projects',
    instant_card_project_details: 'Admin | Hello - Projects',
    instant_card_batches: 'Admin | Hello - Batches',
    instant_card_batch_details: 'Admin | Hello - Batches',
    instant_card_cards: 'Admin | Hello - Cards',
    instant_card_card_details: 'Admin | Hello - Cards',
    workstation_details: 'Admin | Hello - Workstations',
    workstation_registration_details: 'Admin | Hello - Workstation Registration Tokens',
    establishment_details: 'Admin | Hello - Establishments',
  },
  partner_name: {
    label: 'Program',
  },
  publishers: {
    stores: 'Stores',
    business: 'Owner',
    branding: 'Branding',
    no_publishers_found: 'No closed loops found',
    form: {
      add: 'Add',
      placeholder: 'Name, ID',
      business: 'Owner',
      partner: 'Program',
      submit: 'Search',
      data_section: {
        title: 'Closed Loops',
        closed_loops_count: 'Count',
      },
      title: 'Closed Loops',
      total_results_message: 'Displaying the maximum number of results. Further refine your search to see more matches.',
    },
    business_table: {
      fields: {
        id: 'ID',
        name: 'Name',
        contact: 'Contact',
        address: 'Address',
        bank_account: 'Bank Account',
        created: 'Created',
      },
      update_link: 'Change',
    },
    branding_table: {
      fields: {
        id: 'ID',
        name: 'Name',
        type: 'Type',
        images: {
          showcase: 'Showcase',
          horizontal_logo: 'Horizontal Logo',
          big_hero: 'Big Hero',
          small_hero: 'Small Hero',
          background: 'Background',
        },
        created: 'Created',
      },
      update_link: 'Change',
    },
    publishers_table: {
      id: 'ID',
      name: 'Name',
      stores: 'Stores',
      owner: 'Owner',
      offers: 'Offers',
      branding: 'Branding',
      created: 'Created',
      view_link: 'View',
      maximum_page_reached: '{page_count} pages found but we limit to the first {max_pages}.',
    },
    details: {
      publishers_information_section: {
        id: 'ID',
        system_status: {
          pad: 'Pad',
          transactional: 'Transactional',
          display: 'Display',
        },
        name: 'Name',
        stores: 'Stores',
        owner: 'Owner',
        branding: 'Branding',
        created: 'Created',
        title: 'Closed Loop',
        action: 'Action',
        update_publishers: 'Edit',
        update_action: {
          success: 'Closed Loop Edited',
        },
        na: 'N/A',
      },
      business_section: {
        title: 'Owner',
        count: 'Count',
        action: 'Action',
        link: 'Link',
        unlink: 'Unlink',
        unlink_action: {
          title: 'Unlink Business',
          button_cancel: 'Cancel',
          button_submit: 'Submit',
          text:
            'Are you sure you want to unlink the business </br><strong>{businessName}</strong> from closed loop <strong>{publisherName}</strong> ?',
          success: 'Business Unlinked',
        },
        link_action: {
          publisher: 'Closed Loop',
          success: 'Business Linked',
          success_unlink: 'Business Unlinked',
          title: 'Link Business',
          store: 'Store',
          button_cancel: 'Cancel',
          button_save: 'Submit',
          button_unlink: 'Unlink',
          business: 'Business',
          none: 'None',
        },
      },
      branding_section: {
        title: 'Branding',
        count: 'Count',
        action: 'Action',
        link: 'Link',
        unlink: 'Unlink',
        unlink_action: {
          title: 'Unlink Branding',
          button_cancel: 'Cancel',
          button_submit: 'Confirm',
          text:
            'Are you sure you want to unlink the branding </br><strong>{brandingName}</strong> from closed loop <strong>{publisherName}</strong> ?',
          success: 'Branding Unlinked',
        },
        link_action: {
          success: 'Branding Linked',
          success_unlink: 'Branding Unlinked',
          title: 'Link Branding',
          publishers: 'Closed Loops',
          button_cancel: 'Cancel',
          button_save: 'Submit',
          button_unlink: 'Unlink',
          branding: 'Branding',
          none: 'None',
        },
      },
      stores_section: {
        add_publisher_store_action: {
          button_cancel: 'Cancel',
          button_submit: 'Submit',
          publisher: 'Closed Loop',
          store: 'Store',
          success: 'Store Added',
          title: 'Add Store',
        },
        add_stores_from_offer_action: {
          button_cancel: 'Cancel',
          button_submit: 'Submit',
          offer: 'Offer',
          publisher: 'Closed Loop',
          publisher_count: 'Total Publishers',
          store_count: 'Total Stores To Be Added',
          success: 'Stores Added',
          title: 'Add Stores From Offer',
          confirm: {
            title: 'Warning',
            button_cancel: 'Cancel',
            button_submit: 'Submit',
            text: 'Are you sure you want to import <strong>{storeCount}</strong> store(s) in the closed loop (<strong>{publisherId}</strong>)',
          },
        },
        add_stores_from_publisher_action: {
          button_cancel: 'Cancel',
          button_submit: 'Submit',
          offer: 'Offer',
          publisher: 'Closed Loop',
          store_count: 'Total Stores To Be Added',
          success: 'Stores Added',
          title: 'Add Stores From Closed Loop',
          confirm: {
            title: 'Warning',
            button_cancel: 'Cancel',
            button_submit: 'Submit',
            text: 'Are you sure you want to import <strong>{storeCount}</strong> store(s) in the closed loop (<strong>{publisherId}</strong>)',
          },
        },
        add_stores_from_destination_action: {
          button_cancel: 'Cancel',
          button_submit: 'Submit',
          offer: 'Offer',
          destination: 'Destination',
          publisher: 'Publisher',
          store_count: 'Total Stores To Be Added',
          success: 'Stores Added',
          title: 'Add Stores From Destination',
          confirm: {
            title: 'Warning',
            button_cancel: 'Cancel',
            button_submit: 'Submit',
            text: 'Are you sure you want to import <strong>{storeCount}</strong> store(s) in the closed loop (<strong>{publisherId}</strong>)',
          },
        },
        delete_all_stores_from_publisher_action: {
          success: 'Stores Deleted',
          title: 'Remove All Stores From Closed Loop',
        },
        title: 'Add Store',
        actions: 'Actions',
        store_count: 'Count',
        placeholder: 'Name, ID',
        status: 'Status',
        type: 'Type',
        category: 'Category',
        without_card_acceptors: 'Without Card Acceptors',
        create: 'Add Store',
        delete_action: {
          title: 'Remove Store',
          confirm: {
            title: 'Warning',
            button_cancel: 'Cancel',
            button_submit: 'Submit',
            text: 'Are you sure you want to remove store </br><strong>{publishersStoreName}</strong> ?',
          },
          success: 'Store removed',
          button_save: 'Submit',
          button_cancel: 'Cancel',
        },
        delete_all_Stores_action: {
          title: 'Remove All Stores',
          confirm: {
            title: 'Warning',
            button_cancel: 'Cancel',
            button_submit: 'Submit',
            text: 'Are you sure you want to remove <strong>{storeCount}</strong> store(s) from closed loop <strong>{publisherId}</strong>',
          },
          success: 'All stores removed',
          button_save: 'Submit',
          button_cancel: 'Cancel',
        },
        form: {
          submit: 'Search',
          total_results_message: 'Displaying the maximum number of results. Further refine your search to see more matches.',
        },
      },
      offers_section: {
        title: 'Offers',
        count: 'Count',
        add_offer: 'Add Offer',
        action: 'Action',
        create_action: {
          success: 'Offer added',
          title: 'Add Offer To Store',
          offer: 'Offer',
          publisher: 'publisher',
          button_cancel: 'Cancel',
          button_submit: 'Submit',
        },
      },
      notes_section: {
        action: 'Action',
        add: 'Add note',
        author: 'Author',
        count: 'Count',
        create_note: 'Add note',
        created: 'Created',
        delete: 'Delete',
        deleted_by: 'Deleted By',
        no_found: 'None',
        title: 'Notes',
      },
    },
    create_action: {
      success: 'Closed Loop Created',
      title: 'Add Closed Loop',
      name: 'Name',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      copy_stores: 'Add Stores From',
    },
    update_action: {
      title: 'Update Closed Loop',
      success: 'Closed Loop updated',
      name: 'Name',
      owner: 'Owner',
      branding: 'Branding',
      created: 'Created',
      button_cancel: 'Cancel',
      button_save: 'Submit',
    },
    create_note_action: {
      title: 'Add Note',
      note: 'Note',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      success: 'Note saved',
    },
    delete_note_action: {
      title: 'Delete Note',
      reason: 'Reason',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      success: 'Note deleted',
    },
  },
  publisher_name: {
    label: 'Closed Loop',
  },
  publishers_name: {
    label: 'Closed Loop',
  },
  selectText: {
    defaultText: 'Please select one',
  },
  scrollableModal: {
    defaultCloseText: 'Close',
  },
  space: {
    branding_table: {
      fields: {
        id: 'ID',
        name: 'Name',
        type: 'Type',
        images: {
          showcase: 'Showcase',
          horizontal_logo: 'Horizontal Logo',
          big_hero: 'Big Hero',
          small_hero: 'Small Hero',
          background: 'Background',
        },
        created: 'Created',
      },
      update_link: 'Change',
    },
    business_table: {
      fields: {
        id: 'ID',
        name: 'Name',
        contact: 'Contact',
        address: 'Address',
        bank_account: 'Bank Account',
        created: 'Created',
      },
    },
    clerk_table: {
      fields: {
        id: 'ID',
        name: 'Name',
        username: 'Username',
        roles: 'Roles',
        last_auth_date: 'Last Login Date',
        date_created: 'Created',
        status: 'Status',
      },
      add_action: {
        cancel: 'Cancel',
        name: 'Name',
        email: 'Email',
        none: 'None',
        passcode: 'Passcode',
        language: 'Language',
        roles_title: 'Roles',
        roles: {
          admin: 'Manager',
          clerk: 'Agent',
          superadmin: 'Administrator',
        },
        submit: 'Submit',
        success: 'Guest Service User Linked',
        title: 'Add Guest Service User',
        username: 'Username',
        establishments: 'Establishments',
      },
    },
    create_action: {
      business: 'Owner',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      color: 'Color',
      default_language: 'Default Language',
      description: {
        en: 'Description EN',
        fr: 'Description FR',
      },
      horizontal_logo: 'Horizontal Logo',
      id: 'Program ID',
      label: {
        en: 'Label EN',
        fr: 'Label FR',
      },
      name: 'Name',
      region: 'Region',
      timezone: 'Timezone',
      slug: 'Slug',
      success: 'Space created',
      title: 'Add Space',
      url: {
        en: 'URL EN',
        fr: 'URL FR',
      },
      request_consent_communication: 'Request Consent Communication',
    },
    details: {
      branding_section: {
        title: 'Branding',
      },
      business_section: {
        title: 'Owner',
        count: 'Count',
      },
      clerk_section: {
        action: 'Action',
        add: 'Add',
        count: 'Count',
        title: 'Guest Service Users',
        add_action: {
          success: 'Guest Service User Added',
        },
      },
      onboard_action: {
        // approbation_required: 'Approbation Required',
        button_cancel: 'Cancel',
        button_save: 'Submit',
        clerk_name: 'Name',
        clerk_language: 'Language',
        clerk_passcode: 'Passcode',
        clerk_username: 'Username',
        clerk_email: 'Email',
        clerk_establishments: 'Establishments',
        clerk_title: 'Guest Service User',
        ip_restricted: 'IP Restricted',
        success: 'Partner onboarded',
        title: 'Onboard',
        workstation_name: 'Name / Location',
        workstation_title: 'Workstation',
        workstation_request_title: 'Workstation Token',
        workstation_establishments: 'Establishments',
      },
      space_information_section: {
        actions: 'Actions',
        card_selector: 'Card Selector',
        created: 'Date Created',
        id: 'ID',
        is_public_space: {
          true: 'Public',
          false: 'Private',
        },
        region: 'Region',
        timezone: 'Timezone',
        onboard: 'Onboard',
        program: 'Program',
        program_name: 'Name',
        set_private: 'Set Private',
        set_public: 'Set Public',
        title: 'Space',
        update_space: 'Edit',
        url: 'URL',
        guest_services_url: 'Guest Services URL',
        visibility: 'Visibility',
      },
      workstation_section: {
        action: 'Action',
        add: 'Add',
        count: 'Count',
        title: 'Workstations',
        add_action: {
          success: 'Workstation Added',
        },
      },
      partner_establishment_section: {
        action: 'Action',
        add: 'Add',
        count: 'Count',
        title: 'Establishments',
        include_deactivated: 'Show Deactivated',
        add_action: {
          success: 'Establishment Added',
        },
      },
    },
    form: {
      add: 'Add',
      business: 'Owner',
      region: 'Region',
      data_section: {
        title: 'Spaces',
        spaces_count: 'Count',
      },
      upload: {
        logo_horizontal: 'Set Horizontal Logo',
      },
      search_placeholder: 'Id, Name, Partner Id',
      title: 'Space',
      submit: 'Search',
    },
    no_space_found: 'No spaces found',
    spaces_table: {
      branding: 'Branding',
      business: 'Owner',
      id: 'Space ID',
      is_public: 'Visibility',
      name: 'Name',
      program: 'Program',
      region: 'Region',
      timezone: 'Timezone',
      url: 'URL',
      card_selector: {
        title: 'Card Selector',
        values: {
          classical_or_instant: 'Classic or Instant',
          classical_only: 'Classic Only',
          instant_only: 'Instant Only',
          none: 'None',
        },
      },
      is_public_space: {
        true: 'Public',
        false: 'Private',
      },
      action: 'Action',
      set_space_private_link: 'Set Private',
      set_space_public_link: 'Set Public',
    },
    set_public_action: {
      success: 'Space Set As Public',
      confirm: {
        title: 'Set Space As Public',
        button_cancel: 'Cancel',
        button_submit: 'Submit',
        text: 'Are you sure you want to make this space public?',
      },
    },
    set_private_action: {
      success: 'Space Set As Private',
      confirm: {
        title: 'Set Space As Private',
        button_cancel: 'Cancel',
        button_submit: 'Submit',
        text: 'Are you sure you want to make this space private?',
      },
    },
    workstation_table: {
      fields: {
        allowed_cidrs: 'Allowed CIDRs',
        id: 'ID',
        ip_restricted: 'IP Restricted',
        name: 'Name / Location',
        status: 'Status',
        last_access_date: 'Last Access Date',
        pending_registration_token: 'Pending Registration Token',
        date_created: 'Created',
        registration_clerk: 'Registered By',
      },
      add_action: {
        allowed_cidrs: 'Allowed CIDRs',
        cancel: 'Cancel',
        id: 'ID',
        ip_restricted: 'IP Restricted',
        name: 'Name / Location',
        status: 'Status',
        submit: 'Submit',
        success: 'Workstation Linked',
        title: 'Add Workstation',
        establishments: 'Establishments',
        establishment_options: {
          null: 'None',
        },
      },
    },
    partner_establishment: {
      deactivate_action: {
        title: 'Deactivate Establishment',
        confirm: {
          title: 'Warning',
          button_cancel: 'Cancel',
          button_submit: 'Submit',
          text: 'Are you sure you want to set status to deactivated?',
        },
        success: 'Establishment Deactivated',
      },
      activate_action: {
        title: 'Activate Establishment',
        confirm: {
          title: 'Warning',
          button_cancel: 'Cancel',
          button_submit: 'Submit',
          text: 'Are you sure you want to set status to active?',
        },
        success: 'Establishment Activated',
      },
    },
    partner_establishment_table: {
      fields: {
        id: 'ID',
        business: 'Business',
        timezone: 'Timezone',
        status: 'Status',
      },
      add_action: {
        business_id: 'Business Id',
        timezone: 'Timezone',
        cancel: 'Cancel',
        submit: 'Submit',
        success: 'Establishment Created',
        title: 'Add Establishment',
      },
    },
  },
  regions: {
    ab: 'Alberta',
    nb: 'New Brunswick',
    on: 'Ontario',
    qc: 'Quebec',
    undefined: '-',
  },
  state: {
    quebec: 'Quebec',
    saskatchewan: 'Saskatchewan',
    ontario: 'Ontario',
    nova_scotia: 'Nova Scotia',
    new_brunswick: 'New Brunswick',
    manitoba: 'Manitoba',
    british_columbia: 'British Columbia',
    prince_edward_island: 'Prince Edward Island',
    alberta: 'Alberta',
    newfoundland_labrador: 'Newfoundland and Labrador',
    yukon: 'Yukon',
    northwest_territories: 'Northwest Territories',
    nunavut: 'Nunavut',
  },
  store: {
    bank_account: 'Bank Account',
    no_store_found: 'No stores found',
    card_acceptors: 'Card Acceptors',
    publishers: 'Closed Loops',
    types: {
      undefined: '',
      place: 'Place',
      web: 'Web',
      custom: 'Custom',
    },
    status: {
      undefined: '',
      open: 'Open',
      pending: 'Pending',
      closed: 'Closed',
    },
    form: {
      business: 'Owner',
      franchise: 'Franchise',
      destination: 'Shopping Destination',
      publisher: 'Closed Loop',
      card_acceptor: 'Card Acceptor',
      status: 'Status',
      type: 'Type',
      add: 'Add',
      placeholder: 'Name, ID',
      submit: 'Search',
      data_section: {
        title: 'Stores',
        stores_count: 'Count',
      },
      title: 'Store',
      total_results_message: 'Displaying the maximum number of results. Further refine your search to see more matches.',
    },
    business_table: {
      fields: {
        id: 'ID',
        name: 'Name',
        contact: 'Contact',
        address: 'Address',
        bank_account: 'Bank Account',
        created: 'Created',
      },
      update_link: 'Change',
    },
    branding_table: {
      fields: {
        id: 'ID',
        name: 'Name',
        type: 'Type',
        images: {
          showcase: 'Showcase',
          horizontal_logo: 'Horizontal Logo',
          big_hero: 'Big Hero',
          small_hero: 'Small Hero',
          background: 'Background',
        },
        created: 'Created',
      },
      update_link: 'Change',
    },
    publishers_table: {
      fields: {
        id: 'ID',
        name: 'Name',
        branding_id: 'Branding ID',
        offers: 'Offers',
        owner: 'Owner',
        created: 'Created',
      },
      delete_link: 'Remove',
    },
    card_acceptors_table: {
      remove_action: {
        title: 'Remove Card Acceptor',
        text: 'Are you sure you want to remove this card acceptor ?',
        button_cancel: 'Cancel',
        button_submit: 'Submit',
        success: 'Card Acceptor Removed',
      },
      fields: {
        creation_date: 'Created',
        last_update_date: 'Last Update',
        starts_with: 'Starts With',
        contains: 'Contains',
        merchant_id_starts_with: 'Merchant ID',
        merchant_name_starts_with: 'Merchant Name',
        merchant_name_contains: 'Merchant Name',
        merchant_id_starts_with_mobile: 'Id Starts With',
        merchant_name_starts_with_mobile: 'Name Starts With',
        merchant_name_contains_mobile: 'Name Contains',
        merchant_id: 'Merchant ID',
        merchant_name: 'Merchant Name',
        terminal_id: 'Terminal ID',
        country: 'Country',
        state: 'State',
        city: 'City',
        verified: {
          label: 'Verified',
          true: 'Verified',
          false: 'Not\xa0Verified',
        },
      },
      update_link: 'Edit',
      remove_link: 'Remove',
      verify_link: 'Verify',
    },
    stores_table: {
      owner: 'Owner',
      branding: 'Branding',
      business: 'Business',
      destination: 'Shopping Destination',
      franchise: 'Franchise',
      card_acceptors: 'Card Acceptors',
      id: 'ID',
      name: 'Name',
      type: 'Type',
      status: 'Status',
      urls: 'URLs',
      location: 'Location',
      bank_account: 'Bank Account',
      created: 'Created',
      view_link: 'View',
      delete_link: 'Remove',
      maximum_page_reached: '{page_count} pages found but we limit to the first {max_pages}.',
    },
    details: {
      business_section: {
        title: 'Owner',
        count: 'Count',
        action: 'Action',
        link: 'Link',
        unlink: 'Unlink',
        unlink_action: {
          title: 'Unlink Business',
          button_cancel: 'Cancel',
          button_submit: 'Submit',
          text: 'Are you sure you want to unlink the business </br><strong>{businessName}</strong> from store <strong>{storeName}</strong> ?',
          success: 'Business Unlinked',
        },
        link_action: {
          success: 'Business Linked',
          success_unlink: 'Business Unlinked',
          title: 'Link Business',
          store: 'Store',
          button_cancel: 'Cancel',
          button_save: 'Submit',
          button_unlink: 'Unlink',
          business: 'Business',
          none: 'None',
        },
        update_action: {
          success: 'Business Linked',
          title: 'Link Business',
          store: 'Store',
          button_cancel: 'Cancel',
          button_save: 'Submit',
          business: 'Business',
          none: 'None',
        },
      },
      branding_section: {
        title: 'Branding',
        count: 'Count',
        action: 'Action',
        link: 'Link',
        unlink: 'Unlink',
        unlink_action: {
          title: 'Unlink Branding',
          button_cancel: 'Cancel',
          button_submit: 'Submit',
          text: 'Are you sure you want to unlink the branding </br><strong>{brandingName}</strong> from store <strong>{storeName}</strong> ?',
          success: 'Branding Unlinked',
        },
        link_action: {
          success: 'Branding Linked',
          success_unlink: 'Branding Unlinked',
          title: 'Link Branding',
          store: 'Store',
          button_cancel: 'Cancel',
          button_save: 'Submit',
          button_unlink: 'Unlink',
          branding: 'Branding',
          none: 'None',
        },
      },
      publishers_section: {
        count: 'Count',
        add_publisher: 'Add Closed Loop',
        action: 'Action',
        create_action: {
          success: 'Closed Loop added',
          title: 'Add Closed Loop To Store',
          publisher: 'Closed Loop',
          store: 'Store',
          button_cancel: 'Cancel',
          button_submit: 'Submit',
        },
        delete_action: {
          title: 'Remove Closed Loop',
          confirm: {
            title: 'Warning',
            button_cancel: 'Cancel',
            button_submit: 'Submit',
            text: 'Are you sure you want to remove the store from the closed loop?',
          },
          success: 'Closed Loop removed',
          button_save: 'Submit',
          button_cancel: 'Cancel',
        },
      },
      card_acceptors_section: {
        acceptors_count: 'Count',
        create_card_acceptor: 'Add Card Acceptor',
        static: 'Static',
        dynamic: 'Dynamic',
        action: 'Action',
        fuzzy_trx_enabled: 'Fuzzy',
        fuzzy: {
          true: 'Yes',
          false: 'No',
          undefined: 'No',
        },
        create_action: {
          success: 'Card Acceptor Created',
          title: 'Add Store Card Acceptor',
          merchant_id: 'Merchant ID',
          merchant_name: 'Merchant Name',
          terminal_id: 'Terminal ID',
          country: 'Country',
          state: 'State',
          city: 'City',
          merchant_id_starts_with: 'Merchant ID',
          merchant_name_starts_with: 'Merchant Name',
          merchant_name_contains: 'Merchant Name Contains',
          check_transaction_existence: 'Verify in Transaction History',
          button_cancel: 'Cancel',
          button_save: 'Submit',
        },
        update_action: {
          success: 'Card Acceptor Updated',
          title: 'Update Store Card Acceptor',
          merchant_id: 'Merchant ID',
          merchant_name: 'Merchant Name',
          terminal_id: 'Terminal ID',
          country: 'Country',
          state: 'State',
          city: 'City',
          merchant_id_starts_with: 'Merchant ID',
          merchant_name_starts_with: 'Merchant Name',
          merchant_name_contains: 'Merchant Name Contains',
          check_transaction_existence: 'Verify in Transaction History',
          button_cancel: 'Cancel',
          button_save: 'Submit',
        },
        check_verified: {
          title: 'Verify Card Acceptor',
          not_authorized: 'Sorry, no authorization found in our transaction history with this card acceptor. Pleaser try again later.',
          success: 'We have successfully been able to verify the card acceptor.',
          loading: 'Please wait while we look for an authorization transaction for this card acceptor.',
          close: 'Close',
        },
      },
      store_information_section: {
        title: 'Store',
        id: 'ID',
        name: 'Name',
        complementary_info_label: {
          fr: 'Complementary Info FR',
          en: 'Complementary Info EN',
        },
        type: 'Type',
        status: 'Status',
        created: 'Created',
        fuzzy_trx_enabled: 'Fuzzy',
        fuzzy: {
          true: 'Yes',
          false: 'No',
        },
        system_status: {
          pad: 'Pad',
          transactional: 'Transactional',
          display: 'Display',
        },
        url: {
          en: 'URL EN',
          fr: 'URL FR',
        },
        location: 'Location',
        franchise: 'Franchise',
        destination: 'Shopping Destination',
        action: 'Action',
        update_store: 'Edit',
        update_action: {
          success: 'Store Edited',
        },
        na: 'N/A',
      },
      place_section: {
        title: 'Location',
        type: 'Type',
        address: 'Address',
        coordinates: 'Lat/Long Coordinates',
        plus_code: 'Plus Code',
        action: 'Action',
        generate_coordinates: 'Generate Lat/Long Coordinates',
        set_coordinates: 'Set Lat/Long Coordinates',
      },
      web_section: {
        title: 'Location',
        type: 'Type',
        url_en: 'URL EN',
        url_fr: 'URL FR',
      },
      custom_section: {
        title: 'Location',
        type: 'Type',
        label_en: 'Label EN',
        label_fr: 'Label FR',
        description_en: 'Description EN',
        description_fr: 'Description FR',
      },
      notes_section: {
        action: 'Action',
        add: 'Add note',
        author: 'Author',
        count: 'Count',
        create_note: 'Add note',
        created: 'Created',
        delete: 'Delete',
        deleted_by: 'Deleted By',
        no_found: 'None',
        title: 'Notes',
      },
    },
    create_action: {
      success: 'Store created',
      title: 'Add Store',
      complementary_info_label: {
        fr: 'Complementary Info FR',
        en: 'Complementary Info EN',
      },
      name: 'Name',
      fuzzy_trx_enabled: 'Fuzzy Transaction Enabled',
      type_title: 'Type',
      type_place: 'Place',
      type_web: 'Web',
      type_custom: 'Custom',
      web: 'Web',
      status: 'Status',
      franchise: 'Franchise',
      destination: 'Shopping Destination',
      url: {
        en: 'URL EN',
        fr: 'URL FR',
      },
      address: {
        search_address: 'Search store address',
        title: 'Address',
        street: 'Street',
        street_hint: 'On paste - Address will be extracted from this format: STREET, CITY, STATE, ZIP',
        plus_code: 'Plus Code',
        city: 'City',
        state: 'State',
        country: 'Country',
        zip: 'Zip',
      },
      button_cancel: 'Cancel',
      button_save: 'Submit',
    },
    update_action: {
      success: 'Store updated',
      title: 'Update Store',
      name: 'Name',
      fuzzy_trx_enabled: 'Fuzzy Transaction Enabled',
      type_title: 'Type',
      type_place: 'Place',
      type_web: 'Web',
      type_custom: 'Custom',
      status: 'Status',
      franchise: 'Franchise',
      destination: 'Shopping Destination',
      url: {
        en: 'URL EN',
        fr: 'URL FR',
      },
      web: 'Web',
      address: {
        search_address: 'Search store address',
        title: 'Address',
        street: 'Street',
        street_hint: 'On paste - Address will be extracted from this format: STREET, CITY, STATE, ZIP',
        plus_code: 'Plus Code',
        city: 'City',
        state: 'State',
        country: 'Country',
        zip: 'Zip',
      },
      phone: 'Contact Phone Number',
      button_cancel: 'Cancel',
      button_save: 'Submit',
    },
    set_coordinates_action: {
      title: 'Set Coordinates',
      success: 'Store coordinates set',
      lat: 'Latitude',
      lng: 'Longitude',
      button_cancel: 'Cancel',
      button_save: 'Submit',
    },
    generate_coordinates_action: {
      title: 'Generate Coordinates',
      text: 'Are you sure you want to generate coordinates for </br><strong>{storeName}</strong> ?',
      button_cancel: 'Cancel',
      button_submit: 'Submit',
      success: 'Store coordinates generated',
    },
    create_note_action: {
      title: 'Add Note',
      note: 'Note',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      success: 'Note saved',
    },
    delete_note_action: {
      title: 'Delete Note',
      reason: 'Reason',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      success: 'Note deleted',
    },
  },
  system: {
    na: 'N/A',
    bool: {
      true: 'Enabled',
      false: 'Disabled',
    },
    none: 'None',
    false: 'False',
    locale_changed: 'Locale Changed',
    cancel: 'Cancel',
    submit: 'Submit',
    actions: 'Actions',
    dates: {
      year: 'year | years',
      month: 'month | months',
      day: 'day | days',
    },
  },
  toolbar: {
    title: 'Admin | Hello',
  },
  tools: {
    generate_qr_code_modal: {
      title: 'Generate QR Code',
      url: 'Url',
      generate: 'Generate',
      close: 'Close',
    },
  },
  user: {
    blocked: 'This user is blocked',
    aml_note_section: {
      title: 'AML Notes',
      note_created: 'Created',
      note_author: 'Author Email',
      note_note: 'Note',
      note_count: 'Notes',
      add_note: 'Add note',
      action: 'Action',
      no_found: 'None',
      note_type_card: 'Card',
      note_type_application: 'Application',
      delete: 'Delete',
      deleted_by: 'Deleted By',
    },
    aml_profile: {
      aml_status_logs_table: {
        as: 'As',
        change: 'Change',
        details_modal_button_title: 'View Details',
        details_modal_title_general: 'PEPs and Sanctions Hit Summary',
        details_modal_title_peps: 'PEPs Hit Details',
        details_modal_title_sanctions: 'Sanctions Hit Details',
        history: 'History',
        history_mobile: 'Date',
        hits_modal_button_title: 'View Summary',
        list: 'List',
        mobile_section_heading: 'History',
        user: 'By',
        view_details: 'Action',
        view_summary: 'View Summary',
      },
      aml_status_logs: {
        title: 'AML Status Logs',
        date: 'Date',
        type: 'Type',
        sanctions_from_match: 'Sanctions From Match',
        sanctions_to_match: 'Sanctions To Match',
        peps_from_match: 'PEPs From Match',
        peps_to_match: 'PEPs To Match',
        action: 'Action',
      },
      aml_flag: 'Flag',
      edit: 'Action',
      edit_button: 'Edit',
      fatca: {
        title: 'FATCA',
        ssn: 'SSN',
        jurisdiction: 'Jurisdiction',
        tin: 'TIN',
        no_tin_reason: 'No TIN Reason',
      },
      peps_last_verified: 'Last Verification',
      peps_match: 'PEPs',
      peps_title: 'PEPs',
      sanctions_last_verified: 'Last Verification',
      sanctions_match: 'Sanctions',
      sanctions_title: 'Sanctions',
      third_party: {
        title: '3rd Party Determination',
        is_third_party: 'Third Party',
        type: 'Type',
        incorporation_number: 'Incorporation Number',
        jurisdiction: 'Jurisdiction',
        first_name: 'First Name',
        last_name: 'Last Name',
        relationship: 'Relationship',
        occupation: 'Occupation',
        date_of_birth: 'Date of Birth',
        address: 'Address',
      },
    },
    accesses: 'Business Suite Access',
    vouchers: 'Vouchers',
    card_applications: 'Card Applications',
    purses: 'Purses',
    mycash: 'My Cash',
    cards: 'Classic Card',
    instant_cards: 'Instant Cards',
    consumer_gift_card_orders: 'Consumer Gift Card Orders',
    bonus_orders: 'Bonus Orders',
    corporate_orders: 'Corporate Orders',
    memberships: 'Memberships',
    subscriptions: 'Subscriptions',
    transaction_logs: 'Payment Logs',
    no_user_found: 'No users found',
    deactivated_message: 'This user was deactivated on {date} by "{agent}" for this reason: {reason}',
    blocked_message: 'This user was blocked on {date} by "{agent}" for this reason: {reason}',
    aml_flag_message: 'WARNING: User has been flagged as {flag}',
    aml_flag: {
      suspicious: 'Suspicious',
      ok: 'ok',
    },
    form: {
      add: 'Add',
      placeholder: 'ID, Email, Application ID',
      submit: 'Search',
      data_section: {
        title: 'Users',
        users_count: 'Count',
      },
      title: 'User',
      total_results_message: 'Displaying the maximum number of results. Further refine your search to see more matches.',
    },
    user_table: {
      id: 'ID',
      email: 'Email',
      first_name: 'First Name',
      last_name: 'Last Name',
      email_confirmed: 'Email Confirmed',
      email_confirmed_value: {
        true: 'Yes',
        false: 'No',
      },
      password_set: 'Password Set',
      cardholder_id: 'Cardholder ID',
      card_application_id: 'Last Card Application',
      card: 'Last Public Token',
      view_link: 'View',
      maximum_page_reached: '{page_count} pages found but we limit to the first {max_pages}.',
    },
    business_access_table: {
      fields: {
        business: 'Business',
        creation_date: 'Date Access Granted',
      },
    },
    offer_access_table: {
      fields: {
        offer: 'Offer',
        creation_date: 'Date Access Granted',
      },
    },
    user_access_table: {
      type: {
        business: 'Business Access',
        offer: 'Offer Report Access',
      },
    },
    transaction_logs_table: {
      creation_date: 'Transaction Date',
      offer: 'Offer',
      purchase_channel: 'Purchase Channel',
      amount: 'Amount',
      outcome: 'Outcome',
      card: 'Card',
      postal_code: 'Postal Code',
      ip_address: 'IP Address',
      ip_geolocation: 'IP Geolocation',
      protection: 'Protection',
      fingerprint: 'Fingerprint',
      view_fingerprint: 'View',
      status: {
        blocked: 'Blocked',
        rejected: 'Rejected',
        voided: 'Voided',
        approved: 'Approved',
      },
    },
    vouchers_table: {
      view_link: 'View',
      fields: {
        id: 'ID',
        offer_id: 'Offer',
        cardholder: 'Card Public Token',
        payment: 'Contribution',
        partner_id: 'Program',
        bonus: 'Reward',
        received: 'Received',
        remaining: 'Remaining',
        created: 'Created',
        publisher: 'Closed Loop',
        status: 'Status',
      },
    },
    card_applications_table: {
      fields: {
        id: 'ID',
        sponsor: 'Sponsor',
        name: 'Name',
        date_submitted: 'Date Applied',
        status: 'Status',
        card_public_token: 'Issued Card',
      },
    },
    purses_table: {
      fields: {
        id: 'ID',
        virtual_money_id: 'Virtual Money ID',
        wallet_definition_id: 'Wallet Definition ID',
        cardholder_id: 'Cardholder ID',
        available_amount: 'Available',
        actual_amount: 'Hold',
        status: 'Status',
      },
    },
    cards_table: {
      fields: {
        cardholder_id: 'Cardholder ID',
        public_token: 'Public Token',
        card_sponsor: 'Sponsor',
        date_issued: 'Date Issued',
        status: 'Status',
        type: 'Type',
      },
      types: {
        bulk: 'Bulk',
        single: 'Single',
      },
    },
    memberships_table: {
      fields: {
        change_status: 'Change Status',
        edit: 'Edit',
        program: 'Program',
        membership_id: 'Membership ID',
        external_id: 'External ID',
        features: 'Features',
        allow_communication: 'Allow Communication',
        date_joined: 'Date Joined',
        status: 'Status',
        update: 'Edit',
        unsubscribe: 'Unsubscribe',
      },
    },
    subscriptions_table: {
      fields: {
        stripe_subscription_id: 'Stripe Subscription',
        view_subscription: 'View Subscription on Stripe',
        subscription_plan_id: 'Subscription Plan Id',
        partner_id: 'Program',
        status: 'Status',
        creation_date: 'Subscribed On',
      },
      status: {
        active: 'Active',
        cancelled: 'Cancelled',
      },
    },
    consumer_gift_card_orders_table: {
      fields: {
        order_id: 'Order ID',
        offer_promotion_id: 'Offer Promotion ID',
        partner_id: 'Program ID',
        offer_id: 'Offer ID',
        creation_date: 'Date Purchased',
        activation_code_id: 'Activation Code ID',
        amount: 'Amount',
        order_status: 'Order Status',
        voucher_id: 'Voucher ID',
        voucher_status: 'Voucher Status',
        date_activated: 'Date Activated',
        activated_by: 'Activated By',
        loaded_on_card: 'Loaded On',
      },
    },
    bonus_orders_table: {
      fields: {
        order_id: 'Order ID',
        partner_id: 'Program ID',
        offer_id: 'Offer ID',
        creation_date: 'Date Purchased',
        amount: 'Amount',
        order_status: 'Order Status',
        voucher_id: 'Voucher ID',
        voucher_status: 'Voucher Status',
        date_activated: 'Date Activated',
        loaded_on_card: 'Loaded On',
      },
    },
    corporate_orders_table: {
      fields: {
        order_id: 'Order ID',
        partner_id: 'Program ID',
        offer_id: 'Offer ID',
        offer_promotion_id: 'Offer Promotion ID',
        amount: 'Amount',
        quantity: 'Quantity',
        order_status: 'Order Status',
        date_purchased: 'Date Purchased',
        activation_code_batch_id: 'Activation Code Batch',
      },
    },
    details: {
      aml_profile_section: {
        title: 'Aml Profile',
        fatca: {
          title: 'FATCA',
        },
      },
      bonus_orders_section: {
        count: 'Count',
        title: 'Bonus Orders',
        no_found: 'None',
      },
      consumer_gift_card_orders_section: {
        count: 'Count',
        title: 'Consumer Gift Card Orders',
        no_found: 'None',
      },
      corporate_orders_section: {
        count: 'Count',
        title: 'Corporate Orders',
        no_found: 'None',
      },
      user_information_section: {
        title: 'User',
        id: 'ID',
        name: 'Name',
        created: 'Created',
        card_application_id: 'Card Application ID',
        cardholder_id: 'Cardholder ID',
        label: {
          first_name: 'First Name',
          last_name: 'Last Name',
          language: 'Language',
          gender: 'Gender',
          phone: 'Phone',
          mobile: 'Mobile',
          year_of_birth: 'Year of birth',
          street: 'Street',
          city: 'City',
          state: 'State',
          country: 'Country',
          zip: 'Zip',
          sponsor_id: 'Sponsor Id',
          settings_alerts: 'Alerts',
          e_transfer_id: 'E-Transfer Id',
        },
        address: 'Address',
        email: 'Email',
        actions: 'Actions',
        button_edit_account: 'Edit User',
        button_block_account: 'Block User',
        button_unblock_account: 'Unblock User',
        button_email_verification: 'Verify Email',
        button_change_email: 'Change Email',
        button_generate_reset_pswd_link: 'Get Reset Password Link',
        button_deactivate_account: 'Deactivate User',
        na: 'N/A',
        aml_flag: 'AML Flag',
        status: {
          label: 'Status',
          type: {
            active: 'Active',
            deactivated: 'Deactivated',
            undefined: '?',
          },
        },
      },
      vouchers_section: {
        voucher_count: 'Count',
        add_voucher: 'Add',
        action: 'Action',
        no_found: 'None',
        create_action: {
          success: 'Voucher Created',
        },
        view_vouchers_operations: 'View Operations',
      },
      account_accesses_section: {
        count: 'Count',
        no_found: 'None',
      },
      card_applications_section: {
        count: 'Count',
        no_found: 'None',
      },
      mycash_section: {
        title: 'My Cash',
        sync_with_cardholder: 'Synchronized with Open Wallet',
        interac_etransfer_security: 'Interac e-Transfer Security Answer',
        show_history: 'Show My Cash History',
        show_debits_credits: 'Show Open Wallet Debits and Credits',
        user_cannot_use_it: 'Not Synced (No Classic Card)',
      },
      purses_section: {
        count: 'Count',
        no_found: 'None',
        action: 'Action',
        add_purse: 'Add',
      },
      transaction_logs_section: {
        count: 'Count',
        no_found: 'None',
      },
      cards_section: {
        count: 'Count',
        no_found: 'None',
      },
      instant_cards_section: {
        count: 'Count',
        no_found: 'None',
      },
      memberships_section: {
        action: 'Action',
        add_membership: 'Add',
        count: 'Count',
        no_found: 'None',
        add_membership_action: {
          title: 'Add Membership',
          button_cancel: 'Cancel',
          button_save: 'Submit',
          partner: 'Program',
          allow_communication: 'Allow Communication',
          success: 'Membership Added',
        },
        update_membership_action: {
          title: 'Update Membership',
          button_cancel: 'Cancel',
          button_save: 'Submit',
          fields: {
            allow_communication: 'Allow Communication',
            external_id: 'External Id',
          },
          success: 'Membership Updated',
        },
        update_membership_distributed_action: {
          success: 'Membership Updated',
        },
      },
      subscriptions_section: {
        count: 'Count',
        no_found: 'None',
      },
      notes_section: {
        action: 'Action',
        add: 'Add note',
        author: 'Author',
        count: 'Count',
        create_note: 'Add note',
        created: 'Created',
        delete: 'Delete',
        deleted_by: 'Deleted By',
        no_found: 'None',
        title: 'Notes',
      },
    },
    verify_email_action: {
      success: 'Email verified',
      title: 'Verify Email',
      email: 'Please confirm email',
      verify_warning: 'Before proceeding, please ensure that the user has used this email address with customer service.',
      button_cancel: 'Cancel',
      button_save: 'Submit',
    },
    change_email_action: {
      success: 'Email updated',
      title: 'Change Email',
      user: 'User',
      new_email: 'New Email Address',
      confirm_new_email: 'Confirm New Email Address',
      verification_type: 'Verification',
      verification_types: {
        auto: 'Auto-Verify',
        manual: 'Send Verification Email',
      },
      auto_verify_warning: 'Before proceeding with auto-verification, please ensure that the user has used this email address with customer service.',
      button_cancel: 'Cancel',
      button_save: 'Submit',
    },
    generate_reset_pswd_link_action: {
      title: 'Reset Password Link',
      button_cancel: 'Close',
      warning: 'This link will expires in 24 hours.',
    },
    deactivate_account_action: {
      title: 'Deactivate User',
      button_cancel: 'Close',
      button_submit: 'Submit',
      success: 'User deactivated',
      form: {
        reason: {
          label: 'Reason',
        },
      },
      confirm: {
        title: 'Warning',
        button_cancel: 'Cancel',
        button_submit: 'Submit',
        text:
          'Are you sure you want to disable this user <br><strong>{id} ({mail})</strong> ? <br><br> <strong class="error--text">Caution: this action is irreversible !</strong>',
        text_with_open_purse:
          'Are you sure you want to disable this user <br><strong>{id} ({mail})</strong> ? <br><br> <strong class="error--text">Caution: My Cash "${openPurseAvailableBalance}" balance will also be emptied and this action is irreversible !</strong>',
      },
    },
    edit_action: {
      success: 'User updated',
      title: 'Edit User',
      kyc_lock:
        'Some fields of this user such as First Name, Last Name and Date of Birth cannot be edited because they have been sourced through a KYC process.',
      fields: {
        first_name: { label: 'First Name' },
        last_name: { label: 'Last Name' },
        language: { label: 'Language' },
        gender: { label: 'Gender' },
        phone: { label: 'Phone', hint: '1-999-999-9999', error: 'Phone numbers must be in the format 1-999-999-9999' },
        mobile: { label: 'Mobile', hint: '1-999-999-9999', error: 'Phone numbers must be in the format 1-999-999-9999' },
        year_of_birth: { label: 'Year of birth' },
        street: { label: 'Street' },
        city: { label: 'City' },
        state: { label: 'State' },
        country: { label: 'Country' },
        zip: { label: 'Zip' },
        settings_alerts: { label: 'Alerts' },
      },
      address: 'Address',
      personal_information: 'Personal Information',
      contact_information: 'Contact Information',
      settings_information: 'Settings Information',
      button_cancel: 'Cancel',
      button_save: 'Submit',
    },
    create_action: {
      success: 'User created',
      title: 'Add User',
      name: 'Name',
      address: {
        title: 'Address',
        street: 'Street',
        city: 'City',
        state: 'State',
        country: 'Country',
        zip: 'Zip',
      },
      contact_info: 'Contact Info',
      contact_name: 'Contact Name',
      email: 'Contact Email',
      phone: 'Contact Phone Number',
      button_cancel: 'Cancel',
      button_save: 'Submit',
    },
    update_action: {
      success: 'User updated',
      title: 'Update User',
      name: 'Name',
      address: {
        title: 'Address',
        street: 'Street',
        city: 'City',
        state: 'State',
        country: 'Country',
        zip: 'Zip',
      },
      contact_info: 'Contact Info',
      contact_name: 'Contact Name',
      email: 'Contact Email',
      phone: 'Contact Phone Number',
      button_cancel: 'Cancel',
      button_save: 'Submit',
    },
    create_note_action: {
      title: 'Add Note',
      note: 'Note',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      success: 'Note saved',
    },
    delete_note_action: {
      title: 'Delete Note',
      reason: 'Reason',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      success: 'Note deleted',
    },
    block_account_action: {
      title: 'Block User',
      reason: 'Reason',
      cascade: 'Block Vouchers and Cards',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      success: 'User Blocked',
    },
    unblock_account_action: {
      title: 'Unblock User',
      text: 'Are you sure you want to unblock this user <br><strong>{id} ({mail})</strong> ?',
      reason: 'Reason',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      success: 'User Unblocked',
    },
    set_aml_flag_action: {
      title: 'Edit AML Flag',
      regenerate: 'Regenerate card?',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      aml_flag_error_label: 'AML Flag',
      aml_flag_label: 'AML Flag',
      success: 'AML Flag Set',
      aml_note: 'AML Note',
    },
  },
  ready: {
    status: {
      undefined: '',
      ready: 'Ready',
      unready: 'Unready',
    },
  },
  task: {
    assign_action: {
      button_cancel: 'Cancel',
    },
    modal: {
      card_application: {
        title: 'Application',
        id: 'Application ID',
        date: 'Application Date',
        card_applicant: {
          first_name: 'First Name',
          last_name: 'Last Name',
          date_of_birth: 'Date of Birth',
        },
      },
      card: {
        title: 'Card',
        public_token: 'Public Token',
        date_issued: 'Date Issued',
        status: 'Status',
        cardholder: {
          first_name: 'First Name',
          last_name: 'Last Name',
          date_of_birth: 'Date of Birth',
        },
      },
      aml_note: {
        title: 'AML Note',
      },
      aml_status_possible_pep: {
        title: 'Hit',
      },
      aml_status_possible_sanction: {
        title: 'Hit',
      },
      resolve_aml_note_to_review: {
        // <!-- DEPRECATED PLP:  REMOVED IN HC-1753 --> Not commended because uesd out of scope in other task related stuff
        title: 'AML Note - Resolve',
        resolve_title: 'Resolve',
        button_cancel: 'Cancel',
        button_resolve: 'Submit',
        form: {
          action: 'Resolution',
          with_note: 'With Note',
          category: 'Category',
          type: 'Type',
          aml_note: 'Note',
        },
        action: {
          reviewed: 'Mark as reviewed',
          none: 'None',
        },
        success: 'Task resolved',
        aml_note_saved: 'AML note saved',
      },
      resolve_selfserve_change_application_review: {
        title: 'Change Application - Resolve',
        resolve_title: 'Resolve',
        button_cancel: 'Cancel',
        button_resolve: 'Submit',
        form: {
          action: 'Resolution',
        },
        action: {
          approve: 'Approve',
          reject: 'Reject',
        },
        success: 'Task resolved',
      },
      resolve_aml_assessment_check_hit: {
        pep_title: 'PEPs Hit - Resolve',
        resolve_title: 'Resolve',
        sanction_title: 'Sanctions - Resolve',
        button_cancel: 'Cancel',
        button_resolve: 'Submit',
        form: {
          action: 'Resolution',
          reason: 'Reason',
          aml_note: 'AML Note',
        },
        action: {
          none: 'None',
          positive: 'Set as Positive',
          negative: 'Set as Negative',
        },
        reason: {
          false_positive: 'False Positive (Reviewed, Investigated and Updated)',
          other: 'Other',
        },
      },
    },
    form: {
      title: 'Tasks',
      type: 'Type',
      status: 'Status',
      statuses: {
        pending: 'Pending',
        in_progress: 'In Progress',
        resolved: 'Resolved',
      },
      resolved_by: 'Resolved by',
      all_agents: 'All Agents',
      types: {
        aml_note_to_review: 'AML Note',
        aml_fraud_monitoring_to_review: 'AML Fraud Monitoring',
        aml_customer_support_to_review: 'AML Customer Support',
        aml_status_possible_pep: 'PEPs Hit',
        aml_status_possible_sanction: 'Sanctions Hit',
        selfserve_change_application_review: 'Change Application',
        manual: 'Manual',
      },
      assignee: 'Assignee',
      date: 'Date Created',
      date_from: 'From',
      date_to: 'To',
      keyword: 'Keyword',
      keyword_placeholder: 'Task ID, User ID, Application ID, Public Token or Full Name',
      submit: 'Filter',
      no_task_found: 'No tasks found',
    },
    list: {
      field: {
        type: {
          aml_note_to_review: 'AML Note',
          aml_fraud_monitoring_to_review: 'AML Fraud Monitoring',
          aml_customer_support_to_review: 'AML Customer Support',
          aml_status_possible_pep: 'PEPs Hit',
          aml_status_possible_sanction: 'Sanctions Hit',
          selfserve_change_application_review: 'Change Application',
        },
        statuses: {
          pending: 'Pending',
          in_progress: 'In Progress',
          resolved: 'Resolved',
        },
      },
      button_assign: 'Assign',
      button_resolve: 'Resolve',
      button_start: 'Start',
      resolution: {
        title: 'Resolution',
        date: 'Date',
        agent: 'Agent',
        action: 'Action',
      },
      header: {
        person: 'Applicant / Cardholder',
        id: 'Task ID',
        type: 'Type',
        created: 'Created',
        status: 'Status',
        assignee: 'Assignee',
        user: 'User',
      },
      aml_note: {
        title: 'AML Note',
      },
      aml_note_to_review_resolution: {
        aml_note: 'AML Note',
        category: 'Category',
        type: 'Type',
        actions: {
          none: 'None',
          reviewed: 'Reviewed',
        },
      },
      aml_profile: {
        title: 'AML Profile',
        aml_flag: 'AML Flag',
        peps_status: 'AML PEPs',
        sanctions_status: 'AML Sanctions',
      },
      aml_assessment_check: {
        title: 'Hit',
        date: 'Date',
        list_type: 'List Type',
        list_type_pep: 'PEPs',
        list_type_sanctions: 'Sanctions',
        lists: 'Lists',
        as: 'As',
        view_details: 'View Details',
        view_summary: 'View Summary',
        pep_modal_title: 'PEPs Hit Details',
        sanction_modal_title: 'Sanctions Hit Details',
      },
      aml_assessment_check_resolution: {
        actions: {
          none: 'None',
          negative: 'Set as Negative',
          positive: 'Set as Positive',
        },
        reason: 'Reason',
        reasons: {
          false_positive: 'False Positive (Reviewed, Investigated and Updated)',
          other: 'Other',
        },
        category: 'Category',
        type: 'Type',
        aml_note: 'AML Note',
      },
      card_application: {
        title: 'Application',
        id: 'Application ID',
        date: 'Application Date',
        card_public_token: 'Issued Card',
        card_applicant: {
          address: 'Address',
          birth_date: 'Date of Birth',
          first_name: 'First Name',
          last_name: 'Last Name',
        },
        card: {
          date_issued: 'Date Issued',
        },
      },
      change_application_request: {
        title: 'Change Request',
        documents_title: 'Uploaded Documents',
        actions: {
          approved: 'Approved',
          rejected: 'Rejected',
        },
        changes: {
          documents_added: 'Documents Added',
          first_name: 'First Name',
          last_name: 'Last Name',
          date_of_birth: 'Date Of Birth',
          address: {
            street: 'Street',
            city: 'City',
            state: 'State/Province',
            country: 'Country',
            zip: 'Zip/Postal Code',
          },
          email: 'Email',
          phone: 'Phone Number',
          mobile: 'Mobile Number',
          occupation: 'Occupation',
          status: 'Occupation Status',
          sector: 'Sector',
          profession: 'Profession',
          language: 'Language',
          settings: {
            alerts: 'Alerts',
          },
          compliance: {
            occupation: {
              status: { text: 'Compliance Occupation Status' },
              sector: { text: 'Compliance Occupation Sector' },
              profession: { text: 'Compliance Occupation Profession' },
            },
          },
        },
      },
      card: {
        title: 'Card',
        public_token: 'Public Token',
        date_issued: 'Date Issued',
        status: 'Status',
        cardholder: {
          birth_date: 'Date of Birth',
          first_name: 'First Name',
          last_name: 'Last Name',
          card_application_id: 'Application ID',
        },
      },
      user: {
        title: 'User',
        id: 'User ID',
        first_name: 'First Name',
        last_name: 'Last Name',
        creation_date: 'Creation Date',
      },
    },
    pagination: {
      results_found: 'no results found | {n} result found | {n} results found',
    },
  },
  validation: {
    fields: {
      email: 'Email Address',
      password: 'Password',
    },
    invalid: {
      postal_code: 'Invalid Character in postal code',
      postal_code_format: 'Invalid Format expect A0A 0A0',
    },
    select: {
      card_package: 'The Card Package field is required.',
    },
    required: 'Is required',
  },
  purse: {
    source_of_funds: {
      undefined: '?',
      credit_card: 'Credit Card',
      debit_card: 'Debit Card',
      etransfer: 'Interac e-Transfer',
      reward: 'Reward',
      loyalty_program: 'Loyalty Program',
      other: 'Other',
      agent: 'Agent',
      offer: 'Offer',
    },
    details: {
      purse_information_section: {
        title: 'My Cash',
        id: 'Id',
        status: 'Status',
        cardholder: 'Synchronized with Open Wallet',
        user: 'User',
        interac_etransfer_security: 'Interac e-Transfer',
        available_amount: 'Available',
        actual_amount: 'Hold',
        wallet_definition_id: 'Wallet Definition ID',
        virtual_money_id: 'Virtual Money ID',
        block_purse: 'Block',
        load_purse_from_interac: 'Load / Interac e-Transfer',
        activate_purse: 'Activate',
        load_purse_from_business: 'Load / Business',
        purse_adjustment: 'Adjust Balance',
        created: 'Created',
        debits_credits: 'Open Wallet Debits and Credits',
        user_cannot_use_it: 'Not Synced (No Classic Card)',
        empty_open_purse: 'Empty My Cash',
      },
    },
    create_action: {
      title: 'Add User Purse',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      account_id: 'User Id',
      virtual_money_id: 'Virtual Money ID',
      success: 'Purse Created',
    },
    load_purse_from_interac_action: {
      title: 'Load / Interac e-Transfer',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      success: 'Purse Loaded',
      fields: {
        amount: 'Amount',
        currency: 'Currency',
        credit: {
          note: 'Note',
        },
        etransfer: {
          reference_id: 'Interac e-Transfer Record ID',
          reference_date: 'Interac e-Transfer Date',
          note: 'Note (optional)',
        },
      },
      confirm: {
        title: 'Warning',
        button_cancel: 'Cancel',
        button_submit: 'Submit',
        text: 'Are you sure you want to give the reward of <strong>${amount}</strong> in the purse (<strong>{purseId}</strong>)',
      },
    },
    load_open_purse_from_interac_action: {
      title: 'Load / Interac e-Transfer',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      success: 'My Cash Loaded',
      fields: {
        amount: 'Amount',
        currency: 'Currency',
        credit: {
          note: 'Note',
        },
        etransfer: {
          reference_id: 'Interac e-Transfer Record ID',
          reference_date: 'Interac e-Transfer Date',
          note: 'Note (optional)',
        },
      },
      confirm: {
        title: 'Warning',
        button_cancel: 'Cancel',
        button_submit: 'Submit',
        text: 'Are you sure you want to load <strong>${amount}</strong> in My Cash',
      },
    },
    load_purse_from_business: {
      title: 'Load / Bank Business',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      success: 'Purse Loaded',
      fields: {
        amount: 'Amount',
        currency: 'Currency',
        business_id: 'Business',
        credit: {
          note: 'Note',
        },
      },
      confirm: {
        title: 'Warning',
        button_cancel: 'Cancel',
        button_submit: 'Submit',
        text: 'Are you sure you want to load <strong>${amount}</strong> in this purse (<strong>{purseId}</strong>)',
      },
    },
    load_open_purse_from_business: {
      title: 'Load / Bank Business',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      success: 'My Cash Loaded',
      fields: {
        amount: 'Amount',
        currency: 'Currency',
        business_id: 'Business',
        note: 'Note',
      },
      confirm: {
        title: 'Warning',
        button_cancel: 'Cancel',
        button_submit: 'Submit',
        text: 'Are you sure you want to load <strong>${amount}</strong> in My Cash',
      },
    },
    operations: {
      title: 'History',
      types: {
        purse_load: 'Load',
        purse_unload: 'Unload',
        purse_cancel: 'Cancel',
        purse_purchase: 'Purchase',
        purse_cancel_purchase: 'Cancel Purchase',
        purse_start_purchase: 'Start Purchase',
        purse_complete_purchase: 'Complete Purchase',
        purse_debit_adjustment: 'Debit Adjustment',
        purse_credit_adjustment: 'Credit Adjustment',
        card_load: 'Card - Load',
        card_unload: 'Card - Unload',
        card_debit_adjustment: 'Card - Debit Adjustment',
        card_credit_adjustment: 'Card - Credit Adjustment',
        transaction_financial_debit_adjustment: 'Transaction - Financial Debit Adjustment',
        transaction_financial_credit_adjustment: 'Transaction - Financial Credit Adjustment',
        transaction_authorization: 'Transaction - Authorization',
        transaction_authorization_reversal: 'Transaction - Authorization Reversal',
        transaction_payment: 'Transaction - Payment',
        transaction_unauthorized_payment: 'Transaction - Payment (Without Auth)',
        transaction_payment_reversal: 'Transaction - Payment Reversal',
        transaction_payment_debit_adjustment: 'Transaction - Payment (Uncovered)',
        transaction_authorization_debit_adjustment: 'Transaction - Authorization Debit Adjustment',
        transaction_authorization_credit_adjustment: 'Transaction - Authorization Credit Adjustment',
        transaction_credits_refund: 'Transaction - Credits Refund',
        transaction_chargeback: 'Transaction - Chargeback',
        transaction_chargeback_reversal: 'Transaction - Chargeback Reversal',
        transaction_cancel: 'Transaction - Cancel',
      },
    },
    operations_table: {
      id: 'ID',
      type: 'Type',
      amount: 'Amount',
      created: 'Created',
      reason: 'Reason / Merchant',
      card_activity_id: 'Card Activity ID',
      source_of_funds: 'Source of Funds',
      agent: 'Agent / Service',
    },
    adjust_purse_balance_action: {
      title: 'Adjust Balance',
      success: 'Purse Balance Adjusted',
      form: {
        operation_group_id: {
          label: 'Operation Group Id',
        },
        operation_type: {
          label: 'Operation Type',
        },
        reason: {
          label: 'Reason',
        },
        skip_webhook: {
          label: 'Skip Webhook',
        },
        skip_card_sync: {
          label: 'Skip Card Sync',
        },
        amount: {
          label: 'Amount',
        },
        currency: {
          label: 'Currency',
        },
        credit: {
          label: 'Credit',
        },
        debit: {
          label: 'Debit',
        },
      },
      button_cancel: 'Cancel',
      button_submit: 'Submit',
      confirm: {
        title: 'Warning',
        button_cancel: 'Cancel',
        button_submit: 'Submit',
        text:
          'Are you sure you want to adjust the balance of the purse (<strong>{purseId}</strong>) from <strong>${currentBalance}</strong> to <strong>${targetBalance}</strong>?',
      },
    },
    empty_open_purse_action: {
      title: 'Empty "My Cash"',
      button_cancel: 'Cancel',
      button_submit: 'Submit',
      success: '"My Cash" successfully emptied.',
      form: {
        reason: {
          label: 'Reason',
        },
      },

      confirm: {
        title: 'Empty "My Cash" Confirmation',
        text:
          'Are you sure you want to empty "My Cash" for this user <br><strong>{id} ({email})</strong> ? <br><br> <strong class="error--text">Caution: This action is irreversible !</strong>',
        button_cancel: 'Cancel',
        button_submit: 'Confirm',
      },
    },
  },
  voucher: {
    duration_trigger: {
      undefined: '',
      activation: 'Activation',
      purchase: 'Purchase',
    },
    status: {
      active: 'Active',
      canceled: 'Canceled',
      closed: 'Closed',
      pending_activation: 'Pending Activation',
      transferred: 'Transferred',
      undefined: '',
    },
    types: {
      bonus: 'Bonus',
      gift_certificate: 'Gift Certificate',
      none: 'None',
    },
    voucher_types: {
      consumer: 'Consumer',
      reward: 'Reward',
      corporate: 'Corporate',
      undefined: '',
    },
    no_voucher_found: 'No vouchers found',
    before_activation_date: 'Voucher has been restricted for activation up to {date}. By activating the voucher, you will override this restriction.',
    form: {
      add: 'Add',
      placeholder: 'ID, User ID, Activation Code ID',
      activation_code: 'Activation Code',
      partner: 'Program',
      offer: 'Offer',
      publisher: 'Publisher',
      submit: 'Search',
      data_section: {
        title: 'Voucher',
      },
      title: 'Voucher',
      total_results_message: 'Displaying the maximum number of results. Further refine your search to see more matches.',
      voucher_count: 'Count',
    },
    create_action: {
      success: 'Voucher created',
      title: 'Add Bonus Voucher',
      name: 'Name',
      user: 'User',
      card: 'Card',
      publisher: 'Closed Loop',
      cardholder: 'Cardholder',
      payment: 'Contribution',
      voucher_payment: 'Voucher Payment',
      bonus: 'Reward',
      button_cancel: 'Cancel',
      button_save: 'Submit',
    },
    extend_expiration_action: {
      title: 'VOUCHER - Extend Reward Expiration',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      bonus_extension_days: 'Days to Extend',
      bonus_end_date: 'Expiration Date',
    },
    apply_dormancy_extension_action: {
      title: 'VOUCHER - Apply Dormancy Extension',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      reason: 'Reason',
    },
    create_gift_action: {
      success: 'Voucher created',
      title: 'Add Gift Certificate Voucher',
      user: 'User',
      publisher: 'Closed Loop',
      button_cancel: 'Cancel',
      button_save: 'Submit',
    },
    transfer_action: {
      success: 'Voucher Transfer',
      title_to_user: 'VOUCHER - Transfer to Another User',
      title_to_card: 'VOUCHER - Transfer to Another Card',
      reason: 'Reason',
      card: 'Card',
      voucher_id: 'Voucher',
      account_id: 'From User',
      user: 'To User',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      unloaded_card: 'Unloaded (not on card)',
    },
    load_action: {
      success: 'Voucher loaded',
      title: 'VOUCHER - Load to Card',
      reason: 'Reason',
      card: 'Card',
      voucher_id: 'Voucher',
      user: 'To User',
      button_cancel: 'Cancel',
      button_save: 'Submit',
    },
    debit_action: {
      success: 'Voucher debited',
      title: 'VOUCHER - Debit Voucher',
      payment: 'Amount',
      credit_open_wallet: 'Credit Open Wallet',
      reason: 'Reason',
      remaining: 'Remaining',
      button_cancel: 'Cancel',
      button_save: 'Submit',
    },
    refund_action: {
      success: 'Voucher refunded',
      title: 'VOUCHER - Refund to My Cash',
      payment: 'Amount',
      store_id: 'Store',
      reason: 'Reason',
      remaining: 'Remaining',
      button_cancel: 'Cancel',
      button_save: 'Submit',
    },
    refund_voucher_order_action: {
      success: 'Voucher Stripe Refunded',
      title: 'VOUCHER - Refund Purchase (Paysafe)',
      reason: 'Reason',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      notice: 'Please note that the refund will be full. To make a partial refund, you must go into the payment system.',
    },
    block_voucher_action: {
      success: 'Voucher blocked',
      title: 'VOUCHER - Block Voucher',
      reason: 'Reason',
      button_cancel: 'Cancel',
      button_save: 'Submit',
    },
    unblock_voucher_action: {
      success: 'Voucher unblocked',
      title: 'VOUCHER - Unblock Voucher',
      reason: 'Reason',
      button_cancel: 'Cancel',
      button_save: 'Submit',
    },
    cancel_action: {
      success: 'Voucher canceled',
      title: 'VOUCHER - Cancel Voucher',
      reason: 'Reason',
      button_cancel: 'Cancel',
      button_save: 'Submit',
    },
    downloadInvoice: {
      title: 'Download Invoice PDF',
      language_modal: {
        title: 'Download Invoice PDF',
        languageSelectedField: 'Language',
        options: {
          en: 'English',
          fr: 'French',
        },
      },
      button_cancel: 'Cancel',
      button_save: 'Download',
    },
    force_close_action: {
      success: 'Voucher closed',
      title: 'VOUCHER - Force Closure Voucher',
      reason: 'Reason',
      skip_transfer_to_open_wallet: 'Skip Transfer to My Cash',
      button_cancel: 'Cancel',
      button_save: 'Submit',
    },
    adjust_voucher_balance_action: {
      title: 'Adjust voucher balance',

      button_cancel: 'Cancel',
      button_submit: 'Submit',
      success: 'Voucher balance adjusted',
      form: {
        bonus_amount: 'Bonus Amount',
        payment_amount: 'Payment Amount',
        affect_available: 'Affect Available',
        affect_actual: 'Affect Actual',
        reason: 'Reason',
        operation_id: 'Voucher operation id',
      },
      confirm: {
        title: 'Balance adjustment confirmation',
        text: 'Are you sure you want to apply the adjustments for the voucher {voucher_id}?',
        button_cancel: 'Cancel',
        button_confirm: 'Confirm',
      },
    },
    unload_action: {
      success: 'Voucher unloaded',
      title: 'VOUCHER - Unload Voucher',
      message: 'Are you sure you want to unload the voucher {voucher_id} from the card it has been loaded on ?',
      button_cancel: 'Cancel',
      button_save: 'Submit',
    },
    vouchers_table: {
      id: 'ID',
      name: 'Name',
      user: 'User',
      created: 'Created',
      offer: 'Offer',
      partner: 'Program',
      received: 'Received',
      remaining: 'Remaining',
      status: 'Status',
      maximum_page_reached: '{page_count} pages found but we limit to the first {max_pages}.',
    },
    operations: {
      title: 'Voucher History',
      types: {
        voucher_apply_dormancy_extension: 'Apply Dormancy Extension',
        voucher_extend_bonus_expiration: 'Extend Reward Expiration',
        voucher_dormancy_start_date: 'Extend Dormancy Fee Trigger Date',
        voucher_dormancy_fee: 'Dormancy Fee',
        voucher_create: 'Create',
        voucher_activate: 'Activate',
        voucher_cancel: 'Cancel',
        voucher_refund: 'Refund',
        voucher_adjustment_debit: 'Adjustment Debit',
        voucher_adjustment_credit: 'Adjustment Credit',
        voucher_bonus_expiration: 'Reward Expiration',
        voucher_close: 'Close',
        voucher_block: 'Block',
        voucher_unblock: 'Unblock',
        voucher_load: 'Load',
        voucher_unload: 'Unload',
        voucher_transfer_to: 'Transferred',
        voucher_create_from: 'Created from Transfer',
        voucher_adjustment: 'Adjustment',
        voucher_refund_order: 'Order Refunded',
        transaction_authorization: 'Transaction - Authorization',
        transaction_authorization_reversal: 'Transaction - Authorization Reversal',
        transaction_payment: 'Transaction - Payment',
        transaction_payment_reversal: 'Transaction - Payment Reversal',
        transaction_authorization_debit_adjustment: 'Transaction - Authorization Debit Adjustment',
        transaction_authorization_credit_adjustment: 'Transaction - Authorization Credit Adjustment',
        transaction_credits_refund: 'Transaction - Credits Refund',
        transaction_chargeback: 'Transaction - Chargeback',
        transaction_chargeback_reversal: 'Transaction - Chargeback Reversal',
        transaction_cancel: 'Transaction - Cancel',
      },
    },
    operations_table: {
      id: 'ID',
      type: 'Type',
      amount: 'Amount',
      created: 'Created',
      reason: 'Reason / Merchant',
      card_activity_id: 'Card Activity ID',
      agent: 'Agent / Service',
      transfer_to: 'Transfer To',
      transfer_from: 'Transfer From',
    },
    write_off_action: {
      title: 'VOUCHER - Write-Off',
      reason: 'Reason',
      button_cancel: 'Cancel',
      button_save: 'Save',
    },
    remove_write_off_action: {
      title: 'VOUCHER - Remove Write-off',
      message: 'Are you sure you want to remove the write-off from the voucher {voucher_id}?',
      button_cancel: 'Cancel',
      button_submit: 'Submit',
    },
    details: {
      voucher_information_section: {
        title: 'Voucher',
        id: 'ID',
        name: 'Name',
        type: 'Type',
        user: 'User',
        balance: 'Balance',
        offer_promotion_id: 'Offer Promotion ID',
        write_off_voucher: 'Write-off',
        remove_write_off_voucher: 'Remove Write-off',
        system_status: {
          status: 'Status',
        },
        accounting: {
          title: 'Accounting',
          reference_id: 'Reference Id',
          customer_type: {
            label: 'Customer Type',
          },
          breakage_bonus_owner: 'Reward Breakage Owner',
          breakage_payment_owner: 'Contribution Breakage Owner',
        },
        write_off: {
          title: 'Write-off',
          reason: 'Reason',
          date: 'Date',
          by_app_key: 'Write-Off By',
        },
        purchase_channel: 'Purchase Channel',
        downloadInvoice: 'Download Invoice PDF',
        duration_trigger: 'Duration Trigger',
        redeem_activation_code: 'Activate',
        transferred_to_voucher_id: 'Transferred to Voucher',
        bonus_expiration_date: 'Reward Expiration Date',
        activation_start_date: 'Activation Start Date',
        closure_date: 'Closure Date',
        publisher: 'Closed Loop',
        publisher_na: 'Set at Activation Time',
        activation_code: 'Activation Code',
        instant_card_id: 'Instant Card',
        offer: 'Offer',
        cardholder: 'Card Public Token',
        payment: 'Initial Contribution',
        bonus: 'Initial Reward',
        received: 'Received',
        amount: 'Initial Balance',
        activation_date: 'Activation Date',
        remaining: 'Remaining Balance',
        bonus_remaining: 'Remaining Reward',
        payment_remaining: 'Remaining Contribution',
        order_id: 'Order ID',
        created: 'Created',
        action: 'Action',
        debit_voucher: 'Debit',
        extend_voucher_expiration: 'Extend Reward Expiration',
        apply_voucher_dormancy_extension: 'Apply Dormancy Extension',
        refund_voucher_transaction: 'Refund to My Cash',
        refund_voucher_paysafe: 'Refund Purchase (Paysafe) ',
        transfer_voucher_to_another_user: 'Transfer to Another User',
        transfer_voucher_to_another_card: 'Transfer to Another Card',
        load_voucher_to_card: 'Load to Card',
        cancel_voucher: 'Cancel',
        force_closure_voucher: 'Force Closure',
        adjust_balance: 'Adjust Balance',
        unload_from_card: 'Unload from Card',
        restricted_to_members: 'Privilege',
        consumer_gift_program: 'Consumer Gift Program',
        block_voucher: 'Block Voucher',
        unblock_voucher: 'Unblock Voucher',
        dormancy: {
          title: 'Dormancy',
          dormancy_fee: 'Fee',
          dormancy_trigger: 'Trigger (in Months)',
          dormancy_trigger_date: 'Trigger Date',
          dormancy_last_fee_date: 'Last Fee Date',
          extension_in_months: 'Extension Period (in Months)',
        },
        owner_account: 'Owner User',
        extend_action: {
          success: 'Voucher Extended',
        },
        apply_dormancy_extension: {
          success: 'Voucher Dormancy Extension Applied',
        },
        refund_action: {
          success: 'Voucher Refunded',
        },
        refund_action_order: {
          success: 'Voucher Order Refunded',
        },
        transfer_action: {
          success: 'Voucher Transferred',
        },
        debit_action: {
          success: 'Voucher Debited',
        },
        cancel_action: {
          success: 'Voucher Canceled',
        },
        force_close_action: {
          success: 'Voucher closed',
        },
        unload_action: {
          success: 'Voucher Unloaded',
        },
        load_action: {
          success: 'Voucher loaded',
        },
        redeem_action: {
          success: 'Voucher Activated',
        },
        block_action: {
          success: 'Voucher Blocked',
        },
        unblock_action: {
          success: 'Voucher Unblocked',
        },
        remove_write_off_voucher_action: {
          success: 'Voucher Write-off Removed',
        },
        write_off_voucher_action: {
          success: 'Voucher Write-off Added',
        },
      },
      create_action: {
        success: 'Voucher created',
        title: 'Add Voucher',
        name: 'Name',
        user: 'User',
        cardholder: 'Cardholder',
        button_cancel: 'Cancel',
        button_save: 'Submit',
      },
    },
    blocked_message: 'This voucher was blocked on {date} by "{agent}" for this reason: {reason}',
  },
  wallet_definition: {
    rename_wallet_definition_action: {
      title: 'Rename wallet definition',
      name: 'Name',
      button_cancel: 'Cancel',
      button_submit: 'Submit',
      success: 'Wallet Definition renamed',
    },
    no_wallet_definition_found: 'No wallet definitions found',
    form: {
      title: 'Search for a wallet definition',
      submit: 'search',
      clear: 'clear',
      placeholder: 'Wallet Definition ID, Merchant ID or Merchant Name',
      total_results_message: 'Displaying the maximum number of results. Further refine your search to see more matches.',
      data_section: {
        title: 'Wallet Definitions',
        wallet_definitions_count: 'Count',
      },
    },
    info: {
      title: 'Wallet Definition',
      id: 'ID',
      name: 'Name',
      type: 'Type',
      external_id: 'External ID',
      wallets_count: 'Wallets',
      wallets_amount: 'Amount',
      partner: 'Program',
      button_rename_wallet_definition: 'Rename',
      button_actions: 'Actions',
    },
    labels: {
      title: 'Labels',
      fr: 'French',
      en: 'English',
    },
    logs: {
      title: 'Change Logs (Last 3 Changes)',
      fields: {
        rules: 'Rules',
        merchant_id: 'Merchant ID',
        merchant_name: 'Merchant Name',
        external_id: 'External ID',
        tags: 'Tags',
        terminal_id: 'Terminal ID',
        city: 'City',
        state: 'State',
        country: 'Country',
        merchant_name_start_with: 'Merchant Name Start With',
        merchant_id_start_with: 'Merchant ID Start With',
        merchant_name_contains: 'Merchant Name Contains',
        name: 'Name',
        label: 'Labels',
        label_fr: 'French',
        label_en: 'English',
      },
      na: 'N/A',
    },
    wallet_definition_table: {
      id: 'ID',
      name: 'Name',
      program: 'Program',
      type: 'Type',
      maximum_page_reached: '{page_count} pages found but we limit to the first {max_pages}.',
    },
    details: {
      rules_section: {
        count: 'Count',
        title: 'Rules',
      },
      logs_section: {
        title: 'CHANGE LOGS (LAST 3 CHANGES)',
      },
    },
  },
  workstation: {
    activate_action: {
      confirm: {
        button_cancel: 'Cancel',
        button_submit: 'Submit',
        text: 'Are you sure you want to set status to active?',
        title: 'Warning',
      },
      success: 'Workstation activated',
    },
    deactivate_action: {
      confirm: {
        button_cancel: 'Cancel',
        button_submit: 'Submit',
        text: 'Are you sure you want to set status to deactivated?',
        title: 'Warning',
      },
      success: 'Workstation deactivated',
    },
    details: {
      workstation_information_section: {
        actions: 'Actions',
        activate: 'Activate',
        allowed_cidrs: 'Allowed CIDRs',
        date_created: 'Created',
        deactivate: 'Deactivate',
        edit: 'Edit',
        generate_request: 'Generate Request',
        id: 'Id',
        ip_restricted: 'IP Restricted',
        name: 'Name / Location',
        status: 'Status',
        suspend: 'Suspend',
        title: 'Workstation',
        last_access_date: 'Last Access Date',
        establishments: 'Establishments',
        registration_clerk: 'Registered By',
      },
      workstation_registration_request_section: {
        add: 'Add',
        count: 'Count',
        title: 'Workstation Registration Tokens',
        add_action: {
          success: 'Workstation Registration Token Added',
        },
      },
    },
    workstation_registration_request_table: {
      add_action: {
        cancel: 'Cancel',
        submit: 'Submit',
        success: 'Workstation Registration Token Added',
        title: 'Add Workstation Registration Token',
        workstation_id: 'Workstation ID',
      },
      fields: {
        id: 'ID',
        token: 'Token',
        message: 'Message',
        rejected_reason: 'Reason',
        status: 'Status',
        workstation_id: 'Workstation',
        token_expiration_date: 'Expiration Date',
        date_created: 'Created',
      },
    },
    generate_request_action: {
      button_cancel: 'Cancel',
      button_submit: 'Submit',
      success: 'Request generated',
      title: 'Generate request',
    },
    ip_restricted: {
      true: 'True',
      false: 'False',
      undefined: 'Undefined',
    },
    status: {
      created: 'Pending Registration',
      pending_approval: 'Pending Approval',
      active: 'Active',
      suspended: 'Suspended',
      deactivated: 'Deactivated',
    },
    suspend_action: {
      confirm: {
        button_cancel: 'Cancel',
        button_submit: 'Submit',
        text: 'Are you sure you want to set status to suspended?',
        title: 'Warning',
      },
      success: 'Workstation suspended',
    },
    update_action: {
      allowed_cidr: 'Allowed CIDR',
      allowed_cidrs: 'Allowed CIDRs',
      allowed_cidrs_label: 'Allowed CIDRs',
      button_cancel: 'Cancel',
      button_save: 'Submit',
      ip_restricted: 'IP Restricted',
      name: 'Name / Location',
      success: 'Workstation updated',
      title: 'Update Workstation',
      establishments: 'Establishments',
      establishment_options: {
        null: 'None',
      },
    },
  },
  workstation_registration: {
    approve_action: {
      confirm: {
        button_cancel: 'Cancel',
        button_submit: 'Submit',
        text: 'Are you sure you want to approve workstation registration token?',
        title: 'Warning',
      },
      success: 'Workstation Registration Token approved',
    },
    details: {
      workstation_registration_information_section: {
        actions: 'Actions',
        approve: 'Approve',
        id: 'Id',
        token: 'Token',
        message: 'Message',
        request_app_key: 'Request by Agent / Service',
        request_date: 'Request Date',
        request_clerk_id: 'Request by Clerk',
        request_location: 'Request Location',
        approval_date: 'Approval Date',
        approval_by_app_key: 'Approval by Agent / Service',
        approval_by_clerk_id: 'Approval by Clerk',
        approval_by_workstation_id: 'Approval from Workstation',
        reason: 'Reason',
        reject: 'Reject',
        status: 'Status',
        title: 'Workstation Registration Token',
        workstation_id: 'Workstation ID',
      },
    },
    reject_action: {
      button_cancel: 'Cancel',
      button_submit: 'Submit',
      reason: 'Reason',
      success: 'Workstation Registration Token rejected',
      text: 'Are you sure you want to reject workstation registration token?',
      title: 'Warning Reject Registration Token',
    },
    status: {
      approved: 'Approved',
      created: 'Pending',
      pending: 'Pending Approbation',
      rejected: 'Rejected',
    },
  },
  establishment: {
    activate_action: {
      confirm: {
        button_cancel: 'Cancel',
        button_submit: 'Submit',
        text: 'Are you sure you want to set status to active?',
        title: 'Warning',
      },
      success: 'Establishment activated',
    },
    deactivate_action: {
      confirm: {
        button_cancel: 'Cancel',
        button_submit: 'Submit',
        text: 'Are you sure you want to set status to deactivated?',
        title: 'Warning',
      },
      success: 'Establishment deactivated',
    },
    details: {
      establishment_information_section: {
        actions: 'Actions',
        activate: 'Activate',
        deactivate: 'Deactivate',
        edit: 'Edit',
        id: 'Id',
        timezone: 'Timezone',
        status: 'Status',
        title: 'Establishment',
        partner: 'Program',
        business: 'Business',
      },
    },
    status: {
      active: 'Active',
      deactivated: 'Deactivated',
    },
    update_action: {
      button_cancel: 'Cancel',
      button_save: 'Submit',
      timezone: 'Timezone',
      success: 'Establishment updated',
      title: 'Update Establishment',
    },
  },
};
