<template>
  <v-flex class="view-instant_card-form">
    <h1 hidden>{{ $t('instant_card_cards.form.title') }}</h1>
    <v-card>
      <form @submit.prevent="submit" class="form-basic form-search">
        <v-layout align-center wrap class="form-search--main-wrap-v2">
          <v-flex class="form-search--inputs-zone-v2">
            <form-warning-search
              :autofocus="true"
              :searched="searched"
              :hint-message="$t('instant_card_cards.form.total_results_message')"
              class="instant-card--search-field"
              :label="$t('instant_card_cards.form.placeholder')"
              v-model="keyword"
              data-vv-name="search"
              v-validate="'max:50'"
              :error-messages="errors.collect('search')"
              data-test="form-instant-card-cards--keyword"
            ></form-warning-search>
            <h-autocomplete
              v-model="program_id"
              :label="$t('instant_card_cards.form.program')"
              no-filter
              clearable
              :error-messages="errors.collect('program_id')"
              name="program_id"
              data-vv-name="program_id"
              data-vv-as="program_id"
              search-action="partner/autocompleteSearch"
              data-test="form-instant-card-cards--program"
            >
            </h-autocomplete>
            <h-autocomplete
              v-model="project_id"
              :label="$t('instant_card_cards.form.project')"
              no-filter
              clearable
              :error-messages="errors.collect('project_id')"
              name="project_id"
              data-vv-name="project_id"
              data-vv-as="project_id"
              search-action="instantCardProject/autocompleteSearch"
              data-test="form-instant-card-cards--project"
            >
            </h-autocomplete>
            <v-select
              name="status"
              v-model="status"
              :error-messages="errors.collect('status')"
              data-vv-name="status"
              :data-vv-as="$t('instant_card_cards.form.status')"
              :items="statusOptions"
              item-text="name"
              item-value="value"
              clearable
              :label="$t('instant_card_cards.form.status')"
              data-test="form-instant-card-cards--status"
            ></v-select>
            <v-select
              name="activation_enabled"
              v-model="activation_enabled"
              :error-messages="errors.collect('activation_enabled')"
              data-vv-name="activation_enabled"
              :data-vv-as="$t('instant_card_cards.form.activation_enabled')"
              :items="activationEnabledOptions"
              item-text="name"
              item-value="value"
              clearable
              :label="$t('instant_card_cards.form.activation_enabled')"
              data-test="form-instant-card-cards--activation_enabled"
            ></v-select>
            <div class="instant-card-cards--btn-search">
              <button-primary :text="$t('instant_card_cards.form.submit')" :loading="working" type="submit" />
            </div>
          </v-flex>
        </v-layout>
      </form>
    </v-card>

    <v-flex class="data-section">
      <alert-warning v-if="dataReady && cards && cards.length === 0" :text="$t('instant_card.no_instant_card_card_found')"></alert-warning>
      <card v-if="dataReady && cards && cards.length > 0" icon="fal fa-credit-card-blank" :title="`${$t('instant_card_cards.form.title')}`">
        <template slot="headerZoneRight">
          <label-text class="text-sm-center" :label="$t('instant_card_cards.form.instant_cards_count')" :value="listItemCount || '0'"></label-text>
        </template>
        <template slot="content">
          <instant-cards-table :items="cards" />
        </template>
      </card>
    </v-flex>
  </v-flex>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable, security } from '@/mixins';
import _ from 'lodash';
import Vue from 'vue';
import InstantCardsTable from '@/components/instant_card/list/InstantCardsTable';

export default {
  name: 'search-instant-card-cards-form',
  mixins: [actionErrorTrackable, security],
  components: { InstantCardsTable },
  $_veeValidate: {
    validator: 'new',
  },
  beforeDestroy() {
    this.executeAction({ action: 'instantCardProject/clearListProjects' });
  },
  data() {
    return {
      page: 1,
      dataReady: false,
      keyword: '',
      searched: '',
      program_id: '',
      project_id: '',
      status: '',
      activation_enabled: undefined,
      showCreateCardsModal: false,
      statusOptions: [
        { name: this.$t('instant_card_cards.status.pending_activation'), value: 'pending_activation' },
        { name: this.$t('instant_card_cards.status.active'), value: 'active' },
        { name: this.$t('instant_card_cards.status.paused'), value: 'paused' },
        { name: this.$t('instant_card_cards.status.destroyed'), value: 'destroyed' },
      ],
      activationEnabledOptions: [
        { name: this.$t('instant_card_cards.activation_enabled.true'), value: 'true' },
        { name: this.$t('instant_card_cards.activation_enabled.false'), value: 'false' },
      ],
    };
  },
  async created() {
    this.page = this.listPageNumber;
    this.keyword = this.listKeyword;
    this.program_id = this.listPartnerId;
    this.project_id = this.listProjectId;
    this.status = this.listStatus;
    this.activation_enabled = this.listActivationEnabled;

    if (this.routeHasQueryParams()) {
      this.page = 1;
    }
    if (this.$route.query.keyword) {
      this.keyword = this.$route.query.keyword;
    }
    if (this.$route.query.program_id) {
      this.program_id = this.$route.query.program_id;
    }
    if (this.$route.query.project_id) {
      this.project_id = this.$route.query.project_id;
    }
    if (this.$route.query.status) {
      this.status = this.$route.query.status;
    }
    if (this.$route.query.activation_enabled) {
      this.activation_enabled = this.$route.query.activation_enabled;
    }

    this.routeHasQueryParams() ? this.submit(null, false, this.page) : this.submit(null, true, this.page);
  },
  computed: {
    ...mapGetters('instantCardCard', [
      'cards',
      'listKeyword',
      'listPageNumber',
      'listPartnerId',
      'listProjectId',
      'listStatus',
      'listActivationEnabled',
      'listItemCount',
    ]),
  },
  methods: {
    async clear() {
      this.searched = '';
      this.keyword = '';
      this.program_id = '';
      this.project_id = '';
      this.status = '';
      this.activation_enabled = '';
      this.$validator.reset();
    },
    showCreateCardsFeature() {
      return this.canManageOffer(); // TODO: change permission
    },
    routeHasQueryParams() {
      return (
        this.$route.query.keyword ||
        this.$route.query.program_id ||
        this.$route.query.project_id ||
        this.$route.query.status ||
        this.$route.query.activation_enabled
      );
    },
    openCreateCardsModal() {
      this.showCreateCardsModal = true;
    },
    closeCreateCardsModal() {
      this.showCreateCardsModal = false;
    },
    buildPayloadQueryString() {
      const payload = {};
      if (this.keyword) {
        payload.keyword = this.keyword;
      }
      if (this.program_id) {
        payload.partner_id = this.program_id;
      }
      if (this.project_id) {
        payload.project_id = this.project_id;
      }
      if (this.status) {
        payload.status = this.status;
      }
      if (this.activation_enabled) {
        payload.activation_enabled = this.activation_enabled;
      }

      return payload;
    },
    async submit(event, forceSearch = false, page = 1) {
      let validationComplete;
      if (!forceSearch) {
        validationComplete = await this.$validator.validateAll();
      }

      if (validationComplete || forceSearch) {
        this.$router.replace({ query: this.buildPayloadQueryString() });
        await this.clearAllError();

        try {
          this.dataReady = false;
          const search = this.buildPayloadQueryString();
          search.page = page;
          await this.executeAction({ action: 'instantCardCard/listCards' }, search);
        } finally {
          this.dataReady = true;
        }
      }
    },
    // async createCardsSuccess() {
    //   await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'instant_card_cards.create_action.success' });
    //   this.closeCreateCardsModal();
    // },
  },
};
</script>
