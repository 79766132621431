<template>
  <div>
    <table class="responsive-table">
      <thead>
        <tr>
          <td>{{ $t('publishers.publishers_table.id') }}</td>
          <td>{{ $t('publishers.publishers_table.name') }}</td>
          <td>{{ $t('publishers.publishers_table.owner') }}</td>
          <td>{{ $t('publishers.publishers_table.branding') }}</td>
          <td>{{ $t('publishers.publishers_table.stores') }}</td>
          <td>{{ $t('publishers.publishers_table.created') }}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>
            <h5>{{ $t('publishers.publishers_table.id') }}:</h5>
            <span>
              <router-link :to="{ name: 'closed_loop_details', params: { publishersId: item.id } }">
                {{ item.id }}
              </router-link>
            </span>
          </td>
          <td>
            <h5>{{ $t('publishers.publishers_table.name') }}:</h5>
            <span>{{ item.name }}</span>
          </td>
          <td>
            <h5>{{ $t('publishers.publishers_table.owner') }}:</h5>
            <span>
              <router-link v-if="item.business_id" :to="{ name: 'business_details', params: { businessId: item.business_id } }">
                {{ `${item.business.name} (${item.business_id})` }}
              </router-link>
              <label-none v-else>{{ $t('system.na') }}</label-none>
            </span>
          </td>
          <td>
            <h5>{{ $t('publishers.publishers_table.branding') }}:</h5>
            <router-link v-if="item.branding_id" :to="{ name: 'branding_details', params: { brandingId: item.branding_id } }">
              {{ `${item.branding.name} (${item.branding_id})` }}
            </router-link>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td align="center">
            <h5>{{ $t('publishers.publishers_table.stores') }}:</h5>
            <span>{{ item.stores_count }}</span>
          </td>
          <td>
            <h5>{{ $t('publishers.publishers_table.created') }}:</h5>
            <span>{{ item.creation_date | formatShortDateInverted | orNotAvailable }}</span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-xs-center">
      <v-pagination v-if="listPageCount > 1" v-model="pagination" :length="truncatedListPageCount"></v-pagination>

      <p v-if="maximumPageReached" class="pt-1 hint-default">
        {{ $t('publishers.publishers_table.maximum_page_reached', { page_count: listPageCount, max_pages: maxPages }) }}
      </p>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'the-publishers-table',
  mixins: [actionErrorTrackable, security],
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      pagination: 1,
      maxPages: 20,
    };
  },
  mounted() {
    this.pagination = this.listPageNumber;
  },
  computed: {
    ...mapGetters('publishers', ['listPageCount', 'listPageNumber', 'listKeyword', 'listPartnerId', 'listBusinessId']),
    truncatedListPageCount() {
      return Math.min(this.listPageCount, this.maxPages);
    },
    maximumPageReached() {
      return this.listPageCount > this.maxPages;
    },
  },
  watch: {
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'publishers/listPublishers',
        },
        {
          page: newValue,
          keyword: this.listKeyword,
          partner_id: this.listPartnerId,
          business_id: this.listBusinessId,
        },
      );
    },
  },
};
</script>
