import Vue from 'vue';
import _get from 'lodash/get';

import AccountStatus from '@/enums/account-status';
import amlStatusLogConverter from '../utils/aml-status-log-converter';
import { safeDispatcher } from '../../utils/context/context-helper';
import _ from 'lodash';

const defaultPage = 1;
const paginationLimit = 20;

const accountVouchersPaginationLimit = 20;
const accountConsumerGiftCardOrdersPaginationLimit = 20;
const accountBonusOrdersPaginationLimit = 20;
const accountCorporateOrdersPaginationLimit = 20;
const accountCardApplicationsPaginationLimit = 20;
const accountNotesPaginationLimit = 20;
const accountTransactionLogsLimit = 20;

export default {
  namespaced: true,
  state: {
    account: {
      contact: { email: {} },
      address: {
        street: '',
        city: '',
        state: '',
        country: '',
        zip: '',
      },
      settings: {
        communications: {
          push_notifications: {
            channels: {},
          },
        },
      },
    },
    accounts: [],
    accountAmlStatusLogsAndAssessmentChecks: [],

    accountSubscriptions: [],
    accountAmlStatus: {},
    accountAccesses: [],
    offerAccesses: [],
    businessAccesses: [],
    accountVouchers: [],
    accountVouchersPageNumber: defaultPage,
    accountVouchersPageCount: 0,
    accountVouchersItemCount: 0,
    accountCardApplications: [],
    accountCardApplicationsPageNumber: defaultPage,
    accountCardApplicationsPageCount: 0,
    accountCardApplicationsItemCount: 0,

    loadableCards: [],

    instantCards: [],
    instantCardsItemCount: 0,
    instantCardsPageCount: 0,
    instantCardsCurrentPage: 1,

    accountBonusOrdersPage: defaultPage,
    accountBonusOrders: [],
    accountBonusOrdersItemCount: 0,
    accountBonusOrdersPageCount: 0,
    accountBonusOrdersPageNumber: 1,

    accountConsumerGiftCardOrdersPage: defaultPage,
    accountConsumerGiftCardOrders: [],
    accountConsumerGiftCardOrdersItemCount: 0,
    accountConsumerGiftCardOrdersPageCount: 0,
    accountConsumerGiftCardOrdersPageNumber: 1,

    accountCorporateOrdersPage: defaultPage,
    accountCorporateOrders: [],
    accountCorporateOrdersItemCount: 0,
    accountCorporateOrdersPageCount: 0,
    accountCorporateOrdersPageNumber: 1,
    account_notes: [],
    account_notes_list: {
      pageNumber: 1,
      pageCount: 0,
      itemCount: 0,
    },
    account_transaction_logs: [],
    account_transaction_logs_list: {
      pageNumber: 1,
      pageCount: 0,
      itemCount: 0,
    },
    resetPasswordLink: null,
    search: {
      keyword: '',
      pageNumber: 1,
      pageCount: 0,
    },
  },
  getters: {
    accounts: (state) => state.accounts,
    account: (state) => state.account,
    accountAccesses: (state) => state.accountAccesses,
    accountAmlStatus: (state) => state.accountAmlStatus,
    offerAccesses: (state) => state.offerAccesses,
    businessAccesses: (state) => state.businessAccesses,
    accountId: (state) => state.account.id,
    accountCountry: (state) => state.account.address.country,
    accountPostalCode: (state) => state.account.address.zip,
    accountFirstName: (state) => state.account.first_name,
    accountYearOfBirth: (state) => state.account.year_of_birth,
    accountSubscriptions: (state) => state.accountSubscriptions,
    accountAmlStatusLogsAndAssessmentChecks: (state) => state.accountAmlStatusLogsAndAssessmentChecks,
    accountVouchers: (state) => state.accountVouchers,
    accountVouchersPageCount: (state) => state.accountVouchersPageCount,
    accountVouchersItemCount: (state) => state.accountVouchersItemCount,
    accountVouchersPageNumber: (state) => state.accountVouchersPageNumber,
    accountCardApplications: (state) => state.accountCardApplications,
    accountCardApplicationsPageCount: (state) => state.accountCardApplicationsPageCount,
    accountCardApplicationsItemCount: (state) => state.accountCardApplicationsItemCount,
    accountCardApplicationsPageNumber: (state) => state.accountCardApplicationsPageNumber,
    accountEmailStatus: (state) => _get(state, 'account.contact.email.status'),
    accountIsDeactivated: (state) => state.account.status === AccountStatus.DEACTIVATED,

    loadableCards: (state) => state.loadableCards,

    instantCards: (state) => state.instantCards,
    instantCardsPageCount: (state) => state.instantCardsPageCount,
    instantCardsItemCount: (state) => state.instantCardsItemCount,
    instantCardsCurrentPage: (state) => state.instantCardsCurrentPage,

    accountBonusOrders: (state) => state.accountBonusOrders,
    accountBonusOrdersPageCount: (state) => state.accountBonusOrdersPageCount,
    accountBonusOrdersItemCount: (state) => state.accountBonusOrdersItemCount,
    accountBonusOrdersPageNumber: (state) => state.accountBonusOrdersPageNumber,

    accountConsumerGiftCardOrders: (state) => state.accountConsumerGiftCardOrders,
    accountConsumerGiftCardOrdersPageCount: (state) => state.accountConsumerGiftCardOrdersPageCount,
    accountConsumerGiftCardOrdersItemCount: (state) => state.accountConsumerGiftCardOrdersItemCount,
    accountConsumerGiftCardOrdersPageNumber: (state) => state.accountConsumerGiftCardOrdersPageNumber,

    accountCorporateOrders: (state) => state.accountCorporateOrders,
    accountCorporateOrdersPageCount: (state) => state.accountCorporateOrdersPageCount,
    accountCorporateOrdersItemCount: (state) => state.accountCorporateOrdersItemCount,
    accountCorporateOrdersPageNumber: (state) => state.accountCorporateOrdersPageNumber,

    searchKeyword: (state) => state.search.keyword,
    searchPageCount: (state) => state.search.pageCount,
    searchPageNumber: (state) => state.search.pageNumber,
    resetPasswordLink: (state) => state.resetPasswordLink,

    accountNotes: (state) => state.account_notes,
    listAccountNotesPageCount: (state) => state.account_notes_list.pageCount,
    listAccountNotesItemCount: (state) => state.account_notes_list.itemCount,
    listAccountNotesPageNumber: (state) => state.account_notes_list.pageNumber,

    accountTransactionLogs: (state) => state.account_transaction_logs,
    listTransactionLogsPageCount: (state) => state.account_transaction_logs_list.pageCount,
    listTransactionLogsItemCount: (state) => state.account_transaction_logs_list.itemCount,
    listTransactionLogsPageNumber: (state) => state.account_transaction_logs_list.pageNumber,
  },
  mutations: {
    account(state, account) {
      state.account = account;
    },
    accounts(state, accounts) {
      state.accounts = accounts;
    },
    accountAccesses(state, accountAccesses) {
      state.accountAccesses = accountAccesses;
    },
    accountAmlStatus(state, accountAmlStatus) {
      state.accountAmlStatus = accountAmlStatus;
    },
    offerAccesses(state, offerAccesses) {
      state.offerAccesses = offerAccesses;
    },
    businessAccesses(state, businessAccesses) {
      state.businessAccesses = businessAccesses;
    },
    accountSubscriptions(state, subscriptions) {
      state.accountSubscriptions = subscriptions;
    },
    accountAmlStatusLogsAndAssessmentChecks(state, accountAmlStatusLogsAndAssessmentChecks) {
      state.accountAmlStatusLogsAndAssessmentChecks = accountAmlStatusLogsAndAssessmentChecks;
    },
    accountVouchers(state, accountVouchers) {
      state.accountVouchers = accountVouchers;
    },
    accountVouchersPageCount(state, pageCount) {
      state.accountVouchersPageCount = pageCount;
    },
    accountVouchersItemCount(state, itemCount) {
      state.accountVouchersItemCount = itemCount;
    },
    accountVouchersPage(state, page) {
      state.accountVouchersPageNumber = page;
    },
    loadableCards(state, cards) {
      state.loadableCards = cards;
    },
    instantCards(state, cards) {
      state.instantCards = cards;
    },
    instantCardsPageCount(state, pageCount) {
      state.instantCardsPageCount = pageCount;
    },
    instantCardsItemCount(state, itemCount) {
      state.instantCardsItemCount = itemCount;
    },
    instantCardsCurrentPage(state, page) {
      state.instantCardsCurrentPage = page;
    },
    accountBonusOrders(state, cards) {
      state.accountBonusOrders = cards;
    },
    accountBonusOrdersPageCount(state, pageCount) {
      state.accountBonusOrdersPageCount = pageCount;
    },
    accountBonusOrdersItemCount(state, itemCount) {
      state.accountBonusOrdersItemCount = itemCount;
    },
    accountBonusOrdersPageNumber(state, page) {
      state.accountBonusOrdersPageNumber = page;
    },
    accountConsumerGiftCardOrdersPage(state, page) {
      state.accountConsumerGiftCardOrdersPageNumber = page;
    },
    accountConsumerGiftCardOrders(state, cards) {
      state.accountConsumerGiftCardOrders = cards;
    },
    accountConsumerGiftCardOrdersPageCount(state, pageCount) {
      state.accountConsumerGiftCardOrdersPageCount = pageCount;
    },
    accountConsumerGiftCardOrdersItemCount(state, itemCount) {
      state.accountConsumerGiftCardOrdersItemCount = itemCount;
    },
    accountConsumerGiftCardOrdersPageNumber(state, page) {
      state.accountConsumerGiftCardOrdersPageNumber = page;
    },
    accountCorporateOrders(state, cards) {
      state.accountCorporateOrders = cards;
    },
    accountCorporateOrdersPageCount(state, pageCount) {
      state.accountCorporateOrdersPageCount = pageCount;
    },
    accountCorporateOrdersItemCount(state, itemCount) {
      state.accountCorporateOrdersItemCount = itemCount;
    },
    accountCorporateOrdersPageNumber(state, page) {
      state.accountCorporateOrdersPageNumber = page;
    },
    accountCardApplications(state, accountCardApplications) {
      state.accountCardApplications = accountCardApplications;
    },
    accountCardApplicationsPageCount(state, pageCount) {
      state.accountCardApplicationsPageCount = pageCount;
    },
    accountCardApplicationsItemCount(state, itemCount) {
      state.accountCardApplicationsItemCount = itemCount;
    },
    accountCardApplicationsPage(state, page) {
      state.accountCardApplicationsPageNumber = page;
    },
    emailStatus(state, emailStatus) {
      state.account.contact.email.status = emailStatus;
    },
    email(state, email) {
      state.account.contact.email.value = email.value;
      state.account.contact.email.status = email.status;
    },
    searchKeyword(state, keyword) {
      state.search.keyword = keyword;
    },
    searchPageCount(state, pageCount) {
      state.search.pageCount = pageCount;
    },
    searchPage(state, page) {
      state.search.pageNumber = page;
    },
    resetPasswordLink(state, url) {
      state.resetPasswordLink = url;
    },
    accountNotes(state, notes) {
      state.account_notes = notes;
    },
    listAccountNotesPageCount(state, pageCount) {
      state.account_notes_list.pageCount = pageCount;
    },
    listAccountNotesItemCount(state, itemCount) {
      state.account_notes_list.itemCount = itemCount;
    },
    listAccountNotesPage(state, page) {
      state.account_notes_list.pageNumber = page;
    },
    accountTransactionLogs(state, logs) {
      state.account_transaction_logs = logs;
    },
    listTransactionLogsPageCount(state, pageCount) {
      state.account_transaction_logs_list.pageCount = pageCount;
    },
    listTransactionLogsItemCount(state, itemCount) {
      state.account_transaction_logs_list.itemCount = itemCount;
    },
    listTransactionLogsPageNumber(state, page) {
      state.account_transaction_logs_list.pageNumber = page;
    },
  },
  actions: {
    async autocompleteSearch({}, { page, limit, keyword }) {
      const accountService = Vue.prototype.$services.account;

      return accountService.searchAccounts(page, limit, keyword);
    },
    async getAccount({ commit }, id) {
      const accountService = Vue.prototype.$services.account;
      const account = await accountService.getAccount(id);
      commit('account', account);

      return account;
    },

    async clearAccount({ commit }) {
      const emptyAccount = {
        contact: { email: {} },
        address: {
          street: '',
          city: '',
          state: '',
          country: '',
          zip: '',
        },
        settings: {
          communications: {
            push_notifications: {
              channels: {},
            },
          },
        },
      };
      commit('account', emptyAccount);
    },

    async getAccountByCardholderId({ commit }, cardholderId) {
      const accountService = Vue.prototype.$services.account;
      const account = await accountService.getAccountByCardholderId(cardholderId);
      commit('account', account);

      return account;
    },

    async listAccounts({ commit }) {
      const accountService = Vue.prototype.$services.account;
      const accounts = await accountService.listAccounts();

      commit('accounts', accounts);
    },

    async createAccountNote({ dispatch }, { account_id, note }) {
      const accountService = Vue.prototype.$services.account;
      await accountService.createAccountNote(account_id, { note });
      await dispatch('listAccountNotes', { account_id });
    },
    async deleteAccountNote({ dispatch }, { account_id, note_id, reason }) {
      const accountService = Vue.prototype.$services.account;
      await accountService.deleteAccountNote(account_id, note_id, { reason });
      await dispatch('listAccountNotes', { account_id });
    },
    async listAccountNotes({ state, commit }, { account_id, page, limit } = {}) {
      const accountService = Vue.prototype.$services.account;
      const thePage = page || state.account_notes_list.pageNumber;
      const theLimit = limit || accountNotesPaginationLimit;
      const theAccountKey = account_id || '';

      const listResults = await accountService.listAccountNotes(thePage, theLimit, theAccountKey);

      commit('listAccountNotesPage', thePage);
      commit('listAccountNotesPageCount', listResults.page_count);
      commit('listAccountNotesItemCount', listResults.item_count);
      commit('accountNotes', listResults.items);
    },

    async listTransactionLogs({ state, commit }, { account_id, limit, page } = {}) {
      const accountService = Vue.prototype.$services.account;

      const thePage = page || state.account_transaction_logs_list.pageNumber;
      const theLimit = limit || accountTransactionLogsLimit;

      const listResults = await accountService.listTransactionLogs(thePage, theLimit, account_id);

      commit('listTransactionLogsPageNumber', thePage);
      commit('listTransactionLogsPageCount', listResults.page_count);
      commit('listTransactionLogsItemCount', listResults.item_count);
      commit('accountTransactionLogs', listResults.items);
    },

    async listBonusOrdersByAccountId({ commit }, id, page) {
      const accountService = Vue.prototype.$services.account;
      const thePage = page || defaultPage;

      const result = await accountService.listBonusOrdersByAccountId(id, thePage, accountBonusOrdersPaginationLimit);

      commit('accountBonusOrders', result.items);
      commit('accountBonusOrdersPageNumber', thePage);
      commit('accountBonusOrdersPageCount', result.page_count);
      commit('accountBonusOrdersItemCount', result.item_count);
    },

    async changeAccountBonusOrdersPage({ commit }, { id, page }) {
      commit('accountBonusOrdersPageNumber', page);

      const accountService = Vue.prototype.$services.account;
      const limit = accountBonusOrdersPaginationLimit;

      const result = await accountService.listBonusOrdersByAccountId(id, page, limit);

      commit('accountBonusOrders', result.items);
      commit('accountBonusOrdersPageCount', result.page_count);
      commit('accountBonusOrdersItemCount', result.item_count);
    },

    async listConsumerGiftCardOrdersByAccountId({ commit }, id, page) {
      const accountService = Vue.prototype.$services.account;
      const thePage = page || defaultPage;

      const result = await accountService.listConsumerGiftCardOrdersByAccountId(id, thePage, accountConsumerGiftCardOrdersPaginationLimit);

      commit('accountConsumerGiftCardOrders', result.items);
      commit('accountConsumerGiftCardOrdersPageNumber', thePage);
      commit('accountConsumerGiftCardOrdersPageCount', result.page_count);
      commit('accountConsumerGiftCardOrdersItemCount', result.item_count);
    },

    async changeAccountConsumerGiftCardOrdersPage({ commit, state }, { id, page }) {
      commit('accountConsumerGiftCardOrdersPage', page);

      const accountService = Vue.prototype.$services.account;
      const limit = accountConsumerGiftCardOrdersPaginationLimit;

      const result = await accountService.listConsumerGiftCardOrdersByAccountId(id, page, limit);

      commit('accountConsumerGiftCardOrdersPageCount', result.page_count);
      commit('accountConsumerGiftCardOrders', result.items);
    },

    async listCorporateOrdersByAccountId({ commit }, id, page) {
      const accountService = Vue.prototype.$services.account;
      const thePage = page || defaultPage;

      const result = await accountService.listCorporateOrdersByAccountId(id, thePage, accountCorporateOrdersPaginationLimit);

      commit('accountCorporateOrders', result.items);
      commit('accountCorporateOrdersPageNumber', thePage);
      commit('accountCorporateOrdersPageCount', result.page_count);
      commit('accountCorporateOrdersItemCount', result.item_count);
    },

    async changeAccountCorporateOrdersPage({ commit, state }, { id, page }) {
      commit('accountCorporateOrdersPage', page);

      const accountService = Vue.prototype.$services.account;
      const limit = accountCorporateOrdersPaginationLimit;

      const result = await accountService.listCorporateOrdersByAccountId(id, page, limit);

      commit('accountCorporateOrdersPageCount', result.page_count);
      commit('accountCorporateOrders', result.items);
    },

    async listInstantCardsByAccountId({ commit }, id, page, limit) {
      const accountService = Vue.prototype.$services.account;
      const result = await accountService.listInstantCardsByAccountId(id, page, limit);

      const currentPage = page || defaultPage;
      commit('instantCards', result.items);
      commit('instantCardsCurrentPage', currentPage);
      commit('instantCardsPageCount', result.page_count);
      commit('instantCardsItemCount', result.item_count);
    },

    async listLoadableCardsByAccountId({ commit }, id, page, limit) {
      const accountService = Vue.prototype.$services.account;
      const result = await accountService.listLoadableCardsByAccountId(id, page, limit);

      commit('loadableCards', result.items);
    },

    async clearInstantCards({ commit }) {
      commit('instantCards', []);
      commit('instantCardsCurrentPage', 1);
      commit('instantCardsPageCount', 0);
      commit('instantCardsItemCount', 0);
    },

    async listVouchersByAccountId({ commit }, { id, page }) {
      const voucherService = Vue.prototype.$services.voucher;
      const thePage = page || defaultPage;
      const result = await voucherService.listVouchersByAccountId(id, 1, accountVouchersPaginationLimit);
      const accountVouchers = result.items;

      commit('accountVouchers', accountVouchers);
      commit('accountVouchersPage', thePage);
      commit('accountVouchersPageCount', result.page_count);
      commit('accountVouchersItemCount', result.item_count);
    },

    async listCardApplicationsByAccountId({ commit }, { id, page }) {
      const applicationService = Vue.prototype.$services.application;
      const thePage = page || defaultPage;
      const result = await applicationService.listCardApplicationsByAccountId(id, 1, accountCardApplicationsPaginationLimit);
      const accountCardApplications = result.items;

      commit('accountCardApplications', accountCardApplications);
      commit('accountCardApplicationsPage', thePage);
      commit('accountCardApplicationsPageCount', result.page_count);
      commit('accountCardApplicationsItemCount', result.item_count);
    },

    async listAccountAccesses({ commit }, email) {
      const businessService = Vue.prototype.$services.business;
      const offersService = Vue.prototype.$services.offers;
      const businessAccesses = await businessService.listBusinessAccessesByEmail(email);
      const offerAccesses = await offersService.listOfferAccessesByEmail(email);

      const accountAccesses = businessAccesses.concat(offerAccesses);
      commit('businessAccesses', businessAccesses);
      commit('offerAccesses', offerAccesses);
      commit('accountAccesses', accountAccesses);
    },

    async changeAccountVoucherPage({ commit, state }, { id, page }) {
      commit('accountVouchersPage', page);

      const voucherService = Vue.prototype.$services.voucher;
      const limit = accountVouchersPaginationLimit;

      const accountVouchersResult = await voucherService.listVouchersByAccountId(id, page, limit);

      commit('accountVouchersPageCount', accountVouchersResult.page_count);
      commit('accountVouchers', accountVouchersResult.items);
    },

    async changeAccountCardApplicationPage({ commit, state }, { id, page }) {
      commit('accountCardApplicationsPage', page);

      const voucherService = Vue.prototype.$services.voucher;
      const limit = accountCardApplicationsPaginationLimit;

      const accountCardApplicationsResult = await voucherService.listCardApplicationsByAccountId(id, page, limit);

      commit('accountCardApplicationsPageCount', accountCardApplicationsResult.page_count);
      commit('accountCardApplications', accountCardApplicationsResult.items);
    },

    async listAccountSubscriptions({ commit }, { id }) {
      const subscriptionService = Vue.prototype.$services.subscription;
      const result = await subscriptionService.listSubscriptionsByAccountId(id);

      commit('accountSubscriptions', result.items);
    },
    async generateResetPasswordLink({ commit }, { email, preferred_language }) {
      commit('resetPasswordLink', null);

      const accountService = Vue.prototype.$services.account;
      const { url } = await accountService.getResetPasswordLink({ email, preferred_language });

      commit('resetPasswordLink', url);
    },
    async manualVerifyEmail({ commit }, { id, email }) {
      const accountService = Vue.prototype.$services.account;
      const res = await accountService.manualVerifyEmail({ id, email });
      commit('emailStatus', res.status);
    },
    async changeEmail({ commit }, { id, email, doAutoVerify, language }) {
      const accountService = Vue.prototype.$services.account;
      const res = await accountService.changeEmail({ id, email, doAutoVerify, language });
      commit('email', res.email);
    },
    async searchAccounts({ state, commit }, { keyword, page, limit }) {
      const accountService = Vue.prototype.$services.account;
      const thePage = page || state.search.pageNumber || defaultPage;
      const theLimit = limit || paginationLimit;
      const theKeyword = keyword || '';

      const searchResults = await accountService.searchAccounts(thePage, theLimit, theKeyword);

      commit('searchKeyword', theKeyword);
      commit('searchPage', thePage);
      commit('searchPageCount', searchResults.page_count);
      commit('accounts', searchResults.items);
    },
    async clearSearchAccounts({ commit }) {
      commit('accounts', []);
    },

    async createAccount({ commit }, params) {
      const accountService = Vue.prototype.$services.account;

      return accountService.createAccount(params);
    },

    async blockAccount({ dispatch, getters }, params) {
      const accountService = Vue.prototype.$services.account;
      const id = getters.accountId;
      const result = await accountService.blockAccount(id, params);

      await dispatch('getAccount', id);
      return result;
    },

    async unblockAccount({ dispatch, getters }, params) {
      const accountService = Vue.prototype.$services.account;
      const id = getters.accountId;
      const result = await accountService.unblockAccount(id, params);

      await dispatch('getAccount', id);
      return result;
    },

    async updateAccount({ dispatch, getters }, params) {
      const accountService = Vue.prototype.$services.account;

      const id = getters.accountId;
      const result = await accountService.updateAccount(id, params);

      await dispatch('getAccount', id);
      return result;
    },
    async deactivateAccount({ dispatch, getters }, params) {
      const accountService = Vue.prototype.$services.account;

      const id = getters.accountId;
      const result = await accountService.deactivateAccount(id, params);

      await dispatch('getAccount', id);
      return result;
    },

    async setAccountAmlFlag({ dispatch }, { account_id, aml_flag, note }) {
      const accountService = Vue.prototype.$services.account;
      const amlNoteService = Vue.prototype.$services.amlNote;
      const defaultDispatch = safeDispatcher(dispatch);

      if (!_.isEmpty(note)) {
        let payload = {
          note,
          account_id,
          created_by_email: this.state.security.currentUser.email,
        };
        await amlNoteService.createAmlNote(payload);
        await defaultDispatch('aml/getAmlNotes', { account_id });
      }

      await accountService.setAccountAmlFlag(account_id, aml_flag);

      await defaultDispatch('ui/showSuccessSnackBar', { text: 'user.set_aml_flag_action.success' });

      await dispatch('getAccount', account_id);
    },

    async getAccountAmlStatus({ commit }, account_id) {
      const amlStatusService = Vue.prototype.$services.amlStatus;

      const accountAmlStatus = await amlStatusService.getAccountAmlStatus(account_id);
      commit('accountAmlStatus', accountAmlStatus);
    },

    async setAccountAmlStatus({ dispatch }, { account_id, aml_status_object }) {
      const amlStatusService = Vue.prototype.$services.amlStatus;
      const defaultDispatch = safeDispatcher(dispatch);

      await amlStatusService.setAccountAmlStatus(account_id, aml_status_object);

      await defaultDispatch('ui/showSuccessSnackBar', { text: aml_status_object.status_bar_text });

      await defaultDispatch('account/getAccountAmlStatus', account_id);
      await defaultDispatch('account/getAmlStatusLogsAndAssessmentChecks', account_id);
    },

    async getAccountAmlStatusLogsAndAssessmentChecks({ commit }, account_id) {
      const amlStatusLogsService = Vue.prototype.$services.amlStatusLog;

      const amlStatusLogsAndAssessmentChecksOriginal = await amlStatusLogsService.getAccountAmlStatusLogsWithAssessmentChecks(account_id);

      if (amlStatusLogsAndAssessmentChecksOriginal.length > 0) {
        const accountAmlStatusLogsAndAssessmentChecks = amlStatusLogConverter(amlStatusLogsAndAssessmentChecksOriginal);
        commit('accountAmlStatusLogsAndAssessmentChecks', accountAmlStatusLogsAndAssessmentChecks);
      } else {
        commit('accountAmlStatusLogsAndAssessmentChecks', {});
      }
    },
  },
};
