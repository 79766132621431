<template>
  <div>
    <table class="responsive-table" id="offer-vouchers-table">
      <thead>
        <tr>
          <td>{{ $t('voucher.vouchers_table.id') }}</td>
          <td>{{ $t('voucher.vouchers_table.partner') }}</td>
          <td>{{ $t('voucher.vouchers_table.offer') }}</td>
          <td>{{ $t('voucher.vouchers_table.user') }}</td>
          <td align="right">{{ $t('voucher.vouchers_table.received') }}</td>
          <td align="right">{{ $t('voucher.vouchers_table.remaining') }}</td>
          <td>{{ $t('voucher.vouchers_table.created') }}</td>
          <td>{{ $t('voucher.vouchers_table.status') }}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>
            <h5>{{ $t('voucher.vouchers_table.id') }}:</h5>
            <router-link v-if="item.id" :to="{ name: 'voucher_details', params: { voucherId: item.id } }">
              {{ item.id }}
            </router-link>
          </td>
          <td>
            <h5>{{ $t('voucher.vouchers_table.partner') }}:</h5>
            <span>{{ item.partner_id }}</span>
          </td>
          <td>
            <h5>{{ $t('voucher.vouchers_table.offer') }}:</h5>
            <router-link v-if="item.offer_id" :to="{ name: 'offers_details', params: { offerId: item.offer_id } }">
              {{ item.offer_branding.name }} ({{ item.offer_id }})
            </router-link>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('voucher.vouchers_table.user') }}:</h5>
            <account-email v-if="item.account_id" :id="item.account_id" :show-label="false" :generate-link-to-card="true"></account-email>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td align="right">
            <h5>{{ $t('voucher.vouchers_table.received') }}:</h5>
            {{ (item.payment_amount + item.bonus_amount) | currency | dollarSignI18n }}
          </td>
          <td align="right">
            <h5>{{ $t('voucher.vouchers_table.remaining') }}:</h5>
            {{ item.remaining_amount | currency | dollarSignI18n }}
          </td>
          <td>
            <h5>{{ $t('voucher.vouchers_table.created') }}:</h5>
            <span>{{ item.creation_date | formatShortDateInverted | orNotAvailable }}</span>
          </td>
          <td>
            <h5>{{ $t('voucher.vouchers_table.status') }}:</h5>
            <voucher-status-view slot="labelTextLink" :status="item.status" />
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-xs-center">
      <v-pagination v-if="listPageCount > 1" v-model="pagination" :length="truncatedListPageCount"></v-pagination>
      <p v-if="maximumPageReached" class="pt-1 hint-default">
        {{ $t('voucher.vouchers_table.maximum_page_reached', { page_count: listPageCount, max_pages: maxPages }) }}
      </p>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import AccountEmail from './../../account/AccountEmail';
import VoucherStatusView from '@/components/voucher/VoucherStatusView';
import { mapGetters } from 'vuex';

export default {
  name: 'vouchers-table',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { AccountEmail, VoucherStatusView },
  props: {
    items: {
      type: Array,
    },
  },
  data() {
    return {
      pagination: 1,
      maxPages: 20,
    };
  },
  mounted() {
    this.pagination = this.listPageNumber;
  },
  computed: {
    ...mapGetters('voucher', ['listPageCount', 'listPageNumber', 'listPartnerId', 'listOfferId']),

    truncatedListPageCount() {
      return Math.min(this.listPageCount, this.maxPages);
    },
    maximumPageReached() {
      return this.listPageCount > this.maxPages;
    },
  },
  watch: {
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'voucher/listVouchers',
        },
        {
          page: newValue,
          keyword: this.listKeyword,
          partner_id: this.listPartnerId,
          offer_id: this.listOfferId,
        },
      );
    },
  },
};
</script>
