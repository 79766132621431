<template>
  <div>
    <table class="responsive-table">
      <thead>
        <tr>
          <td>{{ $t('wallet_definition.wallet_definition_table.id') }}</td>
          <td>{{ $t('wallet_definition.wallet_definition_table.name') }}</td>
          <td>{{ $t('wallet_definition.wallet_definition_table.program') }}</td>
          <td>{{ $t('wallet_definition.wallet_definition_table.type') }}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>
            <h5>{{ $t('wallet_definition.wallet_definition_table.id') }}:</h5>
            <span>
              <router-link :to="{ name: 'wallet_definition_details', params: { walletDefinitionId: item.id } }">
                {{ item.id }}
              </router-link>
            </span>
          </td>
          <td>
            <h5>{{ $t('wallet_definition.wallet_definition_table.name') }}:</h5>
            <span v-if="item.name">{{ item.name }}</span>
            <label-none v-else>{{ $t('system.none') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('wallet_definition.wallet_definition_table.program') }}:</h5>
            <span>{{ item.partner_id }}</span>
          </td>
          <td>
            <h5>{{ $t('wallet_definition.wallet_definition_table.type') }}:</h5>
            <span v-if="item.type">{{ item.type }}</span>
            <label-none v-else>{{ $t('system.none') }}</label-none>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-xs-center">
      <v-pagination v-if="listPageCount > 1" v-model="pagination" :length="truncatedListPageCount"></v-pagination>

      <p v-if="maximumPageReached" class="pt-1 hint-default">
        {{ $t('wallet_definition.wallet_definition_table.maximum_page_reached', { page_count: listPageCount, max_pages: maxPages }) }}
      </p>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'wallet-definitions-table',
  mixins: [actionErrorTrackable, security],
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      pagination: 1,
      maxPages: 20,
    };
  },
  mounted() {
    this.pagination = this.listPageNumber;
  },
  computed: {
    ...mapGetters('walletDefinition', ['listPageCount', 'listKeyword', 'listPageNumber']),
    truncatedListPageCount() {
      return Math.min(this.listPageCount, this.maxPages);
    },
    maximumPageReached() {
      return this.listPageCount > this.maxPages;
    },
  },
  watch: {
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'walletDefinition/listWalletDefinitions',
        },
        {
          page: newValue,
          keyword: this.listKeyword,
        },
      );
    },
  },
};
</script>
