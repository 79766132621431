<template>
  <div>
    <table class="responsive-table">
      <thead>
        <tr>
          <td>{{ $t('branding.brandings_table.id') }}</td>
          <td>{{ $t('branding.brandings_table.name') }}</td>
          <td>{{ $t('branding.brandings_table.type') }}</td>
          <td>{{ $t('branding.brandings_table.category') }}</td>
          <td>{{ $t('branding.brandings_table.images.showcase') }}</td>
          <td>{{ $t('branding.brandings_table.images.horizontal_logo') }}</td>
          <td>{{ $t('branding.brandings_table.images.banner') }}</td>
          <td>{{ $t('branding.brandings_table.images.background') }}</td>
          <td>{{ $t('branding.brandings_table.created') }}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>
            <h5>{{ $t('branding.brandings_table.id') }}:</h5>
            <span>
              <router-link :to="{ name: 'branding_details', params: { brandingId: item.id } }">
                {{ item.id }}
              </router-link>
            </span>
          </td>
          <td>
            <h5>{{ $t('branding.brandings_table.name') }}:</h5>
            <span>{{ item.name }}</span>
          </td>
          <td>
            <h5>{{ $t('branding.brandings_table.type') }}:</h5>
            <span>{{ $t(`branding.types.${item.type}`) }}</span>
          </td>
          <td>
            <h5>{{ $t('branding.brandings_table.category') }}:</h5>
            <span>{{ item.branding_category.name.en }}</span>
          </td>
          <td align="center">
            <h5>{{ $t('branding.brandings_table.images.showcase') }}:</h5>
            <span v-if="item.images">{{ item.images.filter((image) => image.role === 'showcase_fr' || image.role === 'showcase_en').length }}</span>
          </td>
          <td align="center">
            <h5>{{ $t('branding.brandings_table.images.horizontal_logo') }}:</h5>
            <span v-if="item.images">{{ item.images.filter((image) => image.role.startsWith('logo_horizontal')).length }}</span>
          </td>
          <td align="center">
            <h5>{{ $t('branding.brandings_table.images.banner') }}:</h5>
            <span v-if="item.images">{{ item.images.filter((image) => image.role.startsWith('small_hero')).length }}</span>
          </td>
          <td align="center">
            <h5>{{ $t('branding.brandings_table.images.background') }}:</h5>
            <span v-if="item.images">{{ item.images.filter((image) => image.role.startsWith('background')).length }}</span>
          </td>
          <td>
            <h5>{{ $t('branding.brandings_table.created') }}:</h5>
            <span>{{ item.creation_date | formatShortDateInverted | orNotAvailable }}</span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-xs-center">
      <v-pagination v-if="listPageCount > 1" v-model="pagination" :length="truncatedListPageCount"></v-pagination>

      <p v-if="maximumPageReached" class="pt-1 hint-default">
        {{ $t('branding.brandings_table.maximum_page_reached', { page_count: listPageCount, max_pages: maxPages }) }}
      </p>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'brandings-table',
  mixins: [actionErrorTrackable, security],
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      pagination: 1,
      maxPages: 20,
    };
  },
  mounted() {
    this.pagination = this.listPageNumber;
  },
  computed: {
    ...mapGetters('branding', ['listPageCount', 'listPageNumber', 'listKeyword', 'listType', 'listCategory']),
    truncatedListPageCount() {
      return Math.min(this.listPageCount, this.maxPages);
    },
    maximumPageReached() {
      return this.listPageCount > this.maxPages;
    },
  },
  methods: {
    typesTranslated(type) {
      return this.$t(`branding.types.${type}`);
    },
  },
  watch: {
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'branding/listBrandings',
        },
        {
          page: newValue,
          keyword: this.listKeyword,
          type: this.listType,
          category: this.listCategory,
        },
      );
    },
  },
};
</script>
